import { ACTION_SIDE_BAR_COMPONENT_KEY } from '../utilities/utilities';
import ReassignTasks from '../views/ReassignTasks';
import RescheduleTasks from '../views/RescheduleTasks';

function useSideBarComponent(compKey) {
	const sidebarComponents = [
		{ key: ACTION_SIDE_BAR_COMPONENT_KEY.REASSIGN, component: <ReassignTasks /> },
		{ key: ACTION_SIDE_BAR_COMPONENT_KEY.RESCHEDULE, component: <RescheduleTasks /> },
	];
	return sidebarComponents?.find((comp) => comp?.key == compKey)?.component || null;
}

export default useSideBarComponent;
