// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
// Demo Data
import { useDispatch, useSelector } from 'react-redux';
import {
	clientPaymentModes,
	clientPaymentTypes,
	countryStatesDummy,
	numberWithCommas,
	standardPermissions,
	clientPaymentTerms,
	draftInvoices,
	calculateEndBillingDate,
} from '../../utilities/utilities';
import { Modal, Tooltip } from '@mui/material';
import CustomIconText from '../TextViews/CustomIconText';
import ClientBilling from './Modals/ClientBilling';
import BillingHistory from './Modals/ClientBillingHistory';
import moment from 'moment';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import { ReactComponent as Billing } from '../../assets/icons/billing.svg';
import { ReactComponent as Workflow } from '../../assets/icons/Package.svg';
import { ReactComponent as BillingLocked } from '../../assets/icons/LockSimple.svg';
import * as clientBillingActions from '../../redux/actions/client-billing-actions';
import * as clientActions from '../../redux/actions/client-actions';
import { useParams } from 'react-router';
import Restricted from '../Permissions/Restricted';
import TableRowButton from '../Others/TableRowButton';
import TableRowItem from '../Others/TableRowItem';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import { TableItemWidth3, TableItemWidth4 } from '../../components/TableSpacing/TableSpacingStyles';
import Card from '../Others/Card';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getUserSettings } from '../../hooks/getUserSettings';
import DummyModal from '../Modal/DummyModal';
import { Country } from 'country-state-city';

function ClientBillingDetail() {
	const dispatch = useDispatch();
	const { id } = useParams();
	const clientBilling = useSelector((state) => state.clientBillingReducer.clientBilling);
	const userTeamRole = useSelector((state) => state.clientReducer.userTeamRole);
	const client = useSelector((state) => state.clientReducer.client);
	const tenantProfile =
		client?.team_client_permissions.length &&
		client?.team_client_permissions?.map((team) => {
			return team?.team?.tenant_profile;
		});
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	if (clientBilling?.length > 0) {
		var activeClientbilling = clientBilling.find((item) => item?.is_active === true);

		if (activeClientbilling) {
			//
		} else {
			activeClientbilling = [...clientBilling].sort(
				(a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
			)[0];
		}
	}

	const [editModal, setEditModal] = useState(false);
	const [isCreateModal, setIsCreateModal] = useState(false);
	const [billingModal, setBillingModal] = useState(false);
	const [endBillingDate, setEndBillingDate] = useState(null);

	const toggleEditModal = () => {
		setEditModal(!editModal);
	};

	const toggleBillingModal = () => {
		setBillingModal(!billingModal);
	};

	const profileDetails = tenantProfile?.map((profile) => profile?.currency);

	const currencySymbol = profileDetails ? getSymbolFromCurrency(profileDetails[0]) : '';

	useEffect(() => {
		dispatch(clientBillingActions.getClientBilling(id));
		dispatch(clientActions.getClient(id));
	}, [dispatch, id]);

	useEffect(() => {
		if (activeClientbilling?.next_billing_date && activeClientbilling?.payment_mode) {
			const calculatedEndDate = calculateEndBillingDate(
				activeClientbilling.next_billing_date,
				activeClientbilling.payment_mode,
			);
			setEndBillingDate(moment.utc(calculatedEndDate).format('MM-DD-YYYY'));
		}
	}, [activeClientbilling]);

	return (
		<>
			<section className='client-profile'>
				<div className='d-flex flex-column w-100 align-items-center' style={{ gap: '1em' }}>
					<div
						className='text-left w-100 d-flex align-items-center justify-content-between information-header'
						style={{ height: '76px' }}
					>
						<div>
							<span className='d-flex' style={{ gap: '12px' }}>
								<Billing />
								<h3 className='HeadlineThreeBold'>Billing Details</h3>
							</span>
							<span className='BodyTwoLight' style={{ paddingLeft: '36px' }}>
								{
									"Enter your client's billing details to automatically apply them in future invoices"
								}
							</span>
						</div>
						{clientBilling?.length > 0 ? (
							<div className='d-flex align-items-center'>
								<button
									className='BodyTwoLight primary-text mr-2 mb-0 cursor-pointer bg-transparent border-0'
									onClick={toggleBillingModal}
								>
									Billing History
								</button>
								{/* <Restricted
								AllowedUsers={standardPermissions}
								userTeamRole={[userTeamRole]}
							> */}
								<Tooltip title='Edit Client Info'>
									<EditIcon
										style={{
											color: '#fb7a03',
											cursor: 'pointer',
										}}
										onClick={() => {
											toggleEditModal();
											setIsCreateModal(false);
										}}
									/>
								</Tooltip>
								{/* </Restricted> */}
							</div>
						) : (
							<div className='d-flex align-items-center'>
								<button
									className='BodyTwoLight primary-text mr-2 mb-0 cursor-pointer bg-transparent border-0'
									onClick={() => {
										toggleEditModal();
										setIsCreateModal(true);
									}}
								>
									Create New Billing
								</button>
							</div>
						)}
					</div>
					{console.log(activeClientbilling, 'BillingData')}
					<div className='Profile-Content'>
						{/* {activeClientbilling ? ( */}
						<div
							className='d-flex row w-100 justify-content-between information-content'
							style={{
								borderTop: '1px solid  #EAEAEA ',
								padding: '1em',
								gap: '2%',
								background: 'white',
								width: '100%',
							}}
						>
							<div className='d-flex' style={{ width: '100%' }}>
								<div
									className='d-flex flex-column text-left'
									style={{
										minHeight: '200px',
										gap: '0.8rem',
										width: '100%',
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<span className='BodyOneBold'>Billing Contact</span>
									</div>
									<div className='d-flex flex-column' style={{ gap: '0.8rem' }}>
										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='First Name'
												name='first_name'
												value={
													activeClientbilling?.billing_contact
														?.first_name || ''
												}
											/>
											{console.log(
												activeClientbilling?.billing_contact?.first_name,
												'first_name',
											)}
										</div>

										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Last Name'
												name='last_name'
												className={'text-left'}
												value={
													activeClientbilling?.billing_contact
														?.last_name || ''
												}
											/>
										</div>

										<div className='text-left ProfilePage'>
											<CustomIconText
												label='Phone'
												name='phone_no'
												value={
													activeClientbilling?.billing_contact
														?.phone_no || ''
												}
											/>
										</div>

										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Email'
												name='email_to'
												value={
													activeClientbilling?.billing_contact
														?.email_to || ''
												}
											/>
										</div>
									</div>
								</div>

								{/* Address */}
								<div
									className='d-flex flex-column text-left'
									style={{
										minHeight: '200px',
										gap: '0.8rem',
										width: '100%',
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<span className='BodyOneBold'>Address</span>
									</div>
									<div className='d-flex flex-column' style={{ gap: '0.8rem' }}>
										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Country'
												name='country'
												value={
													Country.getCountryByCode(
														activeClientbilling?.address[0]?.country,
													)?.name ||
													'' ||
													''
												}
											/>
										</div>
										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Street'
												name='street'
												value={
													activeClientbilling?.address[0]?.street || ''
												}
											/>
										</div>

										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Floor/Suite'
												name='floor'
												className={'text-left'}
												value={activeClientbilling?.address[0]?.floor || ''}
												editModal={() => {
													toggleEditModal();
												}}
												billingModal={() => {
													toggleBillingModal();
												}}
											/>
										</div>

										<div className='text-left ProfilePage'>
											<CustomIconText
												label='City/Town'
												name='city'
												type='number'
												edit={false}
												value={activeClientbilling?.address[0]?.city || ''}
											/>
										</div>

										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='State/Province/Region'
												name='state'
												value={activeClientbilling?.address[0]?.state || ''}
											/>
										</div>
										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Zip Code/Postal Code'
												name='zip'
												value={activeClientbilling?.address[0]?.zip || ''}
											/>
										</div>
									</div>
								</div>
								{/* Billing Details */}
								<div
									className='d-flex flex-column text-left'
									style={{
										minHeight: '200px',
										gap: '0.8rem',
										width: '100%',
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<span className='BodyOneBold'>Billing Details</span>
									</div>
									<div className='d-flex flex-column' style={{ gap: '0.8rem' }}>
										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Payment Type'
												name='payment_type'
												value={
													clientPaymentTypes?.find(
														(item) =>
															item.value ==
															activeClientbilling?.payment_type,
													)?.label
												}
											/>
										</div>

										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Payment Frequency'
												name='payment_mode'
												className={'text-left'}
												value={
													clientPaymentModes?.find(
														(item) =>
															item.value ==
															activeClientbilling?.payment_mode,
													)?.label
												}
												editModal={() => {
													toggleEditModal();
												}}
												billingModal={() => {
													toggleBillingModal();
												}}
											/>
										</div>

										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Payment Terms'
												name='payment_terms'
												value={
													clientPaymentTerms?.find(
														(item) =>
															item.value ==
															activeClientbilling?.payment_terms,
													)?.label
												}
											/>
										</div>

										<div className='text-left ProfilePage'>
											<CustomIconText
												label={`Rate`}
												name='rate'
												value={
													activeClientbilling
														? `$${numberWithCommas(
																activeClientbilling?.rate,
																// eslint-disable-next-line no-mixed-spaces-and-tabs
														  )}`
														: ''
												}
												type='number'
												edit={false}
											/>
										</div>
										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Rate Start Date'
												name='bill_start_date'
												value={
													activeClientbilling?.bill_start_date
														? moment
																.utc(
																	activeClientbilling?.bill_start_date,
																)
																.format('MM-DD-YYYY')
														: ''
												}
											/>
										</div>
									</div>
								</div>
								{/* Invoice Details */}
								<div
									className='d-flex flex-column text-left'
									style={{
										minHeight: '200px',
										gap: '0.8rem',
										width: '100%',
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<span className='BodyOneBold'>Invoice Details</span>
									</div>
									<div className='d-flex flex-column' style={{ gap: '0.8rem' }}>
										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Autogenerate Drafts'
												name='invoice_automation'
												className={'text-left'}
												value={
													draftInvoices?.find(
														(item) =>
															item.value ==
															activeClientbilling?.invoice_automation,
													)?.label
												}
											/>
										</div>
										<div className='text-left ProfilePage'>
											<CustomIconText
												label='Start Billing Date'
												name='next_billing_date'
												value={
													activeClientbilling?.next_billing_date
														? moment
																.utc(
																	activeClientbilling?.next_billing_date,
																)
																.format('MM-DD-YYYY')
														: ''
												}
												type='number'
												edit={false}
											/>
										</div>
										<div className='text-left ProfilePage'>
											<CustomIconText
												label='End Billing Date'
												name='end_billing_date'
												value={activeClientbilling?.next_billing_date ? moment.utc(endBillingDate).format('MM-DD-YYYY') : ''}
												type='number'
												edit={false}
											/>
										</div>
										<div className='text-left ProfilePage'>
											<CustomIconText
												label='Next Invoice Date'
												name='automation_date'
												value={
													activeClientbilling?.automation_date
														? moment
																.utc(
																	activeClientbilling?.automation_date,
																)
																.format('MM-DD-YYYY')
														: ''
												}
												type='number'
												edit={false}
											/>
										</div>
										<div className='text-left ProfilePage'>
											<CustomIconText
												label='Invoice Prefix'
												name='start_invoice_no'
												value={activeClientbilling?.start_invoice_no || ''}
												type='number'
												edit={false}
											/>
										</div>
										{/* <div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Email From'
												name='email_from'
												value={activeClientbilling?.email_from || ''}
											/>
										</div> */}
									</div>
								</div>
								{/* Additional Notes */}
								<div
									className='d-flex flex-column text-left'
									style={{
										minHeight: '200px',
										gap: '0.8rem',
										width: '100%',
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<span className='BodyOneBold'>Internal Notes</span>
									</div>
									<div className='d-flex flex-column' style={{ gap: '0.8rem' }}>
										<div className='text-left ProfilePage'>
											<CustomIconText
												edit={false}
												label='Note'
												name='note'
												value={activeClientbilling?.notes || ''}
											/>
										</div>
									</div>
								</div>
								{/* <div style={{ width: '50px', height: '50px' }}>
										{sortedClientbilling ? (
											<Tooltip title='Edit Client Info'>
												<EditIcon
													style={{
														color: '#fb7a03',
														cursor: 'pointer',
													}}
													onClick={toggleEditModal}
												/>
											</Tooltip>
										) : (
											<>
												<Tooltip title='Billing is Locked'>
													<BillingLocked
														style={{
															color: '#fb7a03',
															cursor: 'pointer',
														}}
													/>
												</Tooltip>
											</>
										)}
									</div> */}
							</div>
						</div>
						{/* ) : (
							<div
								className='d-flex row w-100 justify-content-between information-content'
								style={{
									borderTop: '1px solid  #EAEAEA ',
									padding: '1em',
									gap: '2%',
									background: 'white',
									width: '100%',
								}}
							>
								<h3 className='BodyTwoLight mb-0 table-header-text  ml-1 m-auto opacity-75'>
									No billing against the client. Click{' '}
									<span
										style={{ color: '#FB7A03' }}
										className='pointer'
										onClick={() => {
											toggleEditModal();
											setIsCreateModal(true);
										}}
									>
										here
									</span>{' '}
									to create a new billing
								</h3>
							</div>
						)} */}
					</div>

					{/* <div
						className='d-flex row w-100 justify-content-between information-content'
						style={{ borderTop: '1px solid  #EAEAEA ', padding: '1em' }}
					>
						<div
							className='d-flex flex-column'
							style={{ width: '20%', gap: '0.8rem' }}
						>
							<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
								<CustomIconText
									edit={false}
									label='Payment Mode'
									name='payment_mode'
									className={'text-left'}
									value={
										clientPaymentModes?.find(
											(item) => item.value == activeClientbilling?.payment_mode,
										)?.label
									}
									editModal={() => {
										toggleEditModal();
									}}
									billingModal={() => {
										toggleBillingModal();
									}}
								/>
							</div>
						</div>
						<div
							className='d-flex flex-column'
							style={{ width: '20%', gap: '0.8rem' }}
						>
							<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
								<CustomIconText
									edit={false}
									label='Payment Type'
									name='payment_type'
									value={
										clientPaymentTypes?.find(
											(item) => item.value == activeClientbilling?.payment_type,
										)?.label
									}
								/>
							</div>
						</div>

						<div
							className='d-flex flex-column'
							style={{ width: '20%', gap: '0.8rem' }}
						>
							<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
								<CustomIconText
									edit={false}
									label='Billing Start Date'
									name='bill_start_date'
									value={moment(activeClientbilling?.bill_start_date).format(
										'MM-DD-YYYY',
									)}
								/>
							</div>
						</div>

						<div
							className='d-flex flex-column'
							style={{ width: '20%', gap: '0.8rem' }}
						>
							<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
								<CustomIconText
									label={`Rate/${
										activeClientbilling?.payment_type == 'RETAINER'
											? 'month'
											: 'hr'
									}`}
									name='rate'
									value={
										activeClientbilling
											? `$${numberWithCommas(activeClientbilling?.rate)}`
											: ''
									}
									type='number'
									edit={false}
								/>
							</div>
						</div>
					</div> */}
				</div>
				{/* <div className='text-left w-100 d-flex align-items-center justify-content-between information-header'>
					<span className='d-flex' style={{ gap: '12px' }}>
						<Workflow />
						<h3 className='HeadlineThreeBold'>Workflow Details</h3>
					</span>
				</div>
				<div className='Profile-Content'>
					<div
						className='d-flex row w-100 justify-content-between information-content'
						style={{
							borderTop: '1px solid  #EAEAEA ',
							padding: '1em',
							gap: '2%',
							background: 'white',
							width: '100%',
						}}
					>
						<TableHeader
							ClassName='TableHeader flex fixedHeader invocie-workFlow-table'
							border={false}
						>
							<TableHeaderItem
								ClassName='UManagement invoice-table-headers'
								label='Workflow'
								width={TableItemWidth4}
							/>

							<TableHeaderItem
								ClassName='UManagement invoice-table-headers'
								label='Budgeted Hours'
								width={TableItemWidth4}
							/>

							<TableHeaderItem
								ClassName='UManagement invoice-table-headers'
								label='Recurrence'
								width={TableItemWidth3}
							/>

							<TableHeaderItem
								ClassName={'UManagement invoice-table-headers'}
								label='Days to Complete'
								width={TableItemWidth3}
							/>
							<TableHeaderItem
								ClassName='UManagement invoice-table-headers'
								label='Assignees'
								width={TableItemWidth3}
							/>
						</TableHeader>

						<div data-cy='all-clients-list' className='ListingLoading listing-scroll'>
							<div className='TableRow d-flex align-items-center'>
								<TableRowButton
									ClassName='UManagement invoice-table-headers worflor-name'
									width={TableItemWidth4}
									label='Payroll'
								/>

								<TableRowItem
									width={TableItemWidth4}
									ClassName='UManagement text-left invoice-table-headers'
									label='40'
								/>

								<TableRowItem
									width={TableItemWidth3}
									ClassName='UManagement text-left invoice-table-headers'
									label='4th Thursday of every month'
								/>

								<TableRowItem
									width={TableItemWidth3}
									ClassName='UManagement text-left invoice-table-headers'
									label='30'
								/>
								<TableRowButton
									ClassName='UManagement invoice-table-headers'
									text='text-left'
									width={TableItemWidth3}
									label='Theresa Webb Jacob Jones Penny Barber'
								/>
							</div>
						</div>
					</div>
				</div> */}
			</section>

			<Modal
				onClose={toggleEditModal}
				open={editModal}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<DummyModal
					title={isCreateModal ? 'Create Billing Details' : 'Edit Billing Details'}
					onClose={toggleEditModal}
				>
					<ClientBilling
						onSubmit={() => {
							toggleEditModal();
						}}
						isCreate={isCreateModal}
						onCancel={toggleEditModal}
						title={isCreateModal ? 'Add' : 'Update'}
						currencySymbol={currencySymbol}
					/>
				</DummyModal>
			</Modal>
			<Modal
				onClose={toggleBillingModal}
				open={billingModal}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<BillingHistory
					onCancel={toggleBillingModal}
					data={clientBilling}
					currencySymbol={currencySymbol}
				/>
			</Modal>
		</>
	);
}

export default ClientBillingDetail;
