/* eslint-disable no-case-declarations */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, memo } from 'react';

// Components
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as Info } from '../../assets/icons/Info.svg';

// Utils
import { getValidComponent } from '../../utilities/BuilderUtils.js';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import {
	TableItemWidth1,
	TableItemWidth2,
	TableItemWidth3,
} from '../TableSpacing/TableSpacingStyles';
import moment from 'moment';

// Redux
import * as builderActions from '../../redux/actions/workflow-builder-actions.js';
import { useDispatch, useSelector } from 'react-redux';
import Board from './Workflow-Builder/Board';
import Card from './Workflow-Builder/Card';
import { ReactComponent as Down } from '../../assets/icons/next.svg';
import { ReactComponent as Up } from '../../assets/icons/client copy.svg';
import { useHistory, useParams } from 'react-router-dom';
import TableRowItem from '../Others/TableRowItem';
import EditableText from '../Inputs/EditableText';
import TableRowButton from '../Others/TableRowButton';
import useModal from '../Modal/useModal';
import { CircularProgress, IconButton, Modal } from '@mui/material';
import DummyModal from '../Modal/DummyModal';
import WorkflowTaskModal from './Modals/WorkflowTaskModal';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { EditableDropdown } from '../Inputs/EditableDropDown';
import DynamicModal from './Modals/DynamicModal';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RecurrenceModal from './Modals/RecurrenceModal';
import {
	inputDateFormate,
	removeHttp,
	REPEAT_TYPE,
	ordinal_suffix_of,
	weekOfMonth,
	Member,
	sortTasks,
} from '../../utilities/utilities';
import { getWeeksInMonth } from 'date-fns/esm';

import { states2 } from '../../components/DemoData';
import ConfirmModal from '../Modal/ConfirmModal';
import { Tooltip } from '@material-ui/core';
import { backgroundGrey } from '../Style';
import EditableTextArea from '../Inputs/EditableTextArea';
import RequireToolTip from '../Others/RequireToolTip';
import WorkflowMoreButton from '../Buttons/WorkflowMoreButton';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import { motion } from 'framer-motion';

function ExpandDuplicatedWorkflowBox(props) {
	const edit = true;
	const [FormProp, setFormProp] = useState({});
	const [LoadingItems, setLoadingItems] = useState([]);
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();
	const user = useSelector((state) => state.authReducer.user);
	const allWorkflowTaskRecurrence = useSelector(
		(state) => state.WorkflowBuilder.taskRecurrence,
	);
	const urls = useSelector((state) => state.clientAccountsReducer.allUrls);
	const clientAccounts = useSelector(
		(state) => state.clientAccountsReducer.clientAccounts,
	)?.map((item) => {
		return {
			...item,
			softwares: item?.softwares?.map((soft) => {
				return {
					...soft,
					institution: urls?.find((url) => url.id == soft.institution_url_id)?.institution || soft?.name,
					login: urls?.find((url) => url.id == soft.institution_url_id)?.url || soft?.custom_url
				};
			})
		};
	});
	const loading = useSelector((state) => state.WorkflowBuilder.loading);

	const [toDeleteTask, setToDeleteTask] = useState({});
	const [selectedSection, setSelectSection] = useState();
	const client = useSelector((state) => state.clientReducer.client);
	const recurrences = [
		{ type: REPEAT_TYPE.DAILY, name: 'Day' },
		{ type: REPEAT_TYPE.WEEKLY, name: 'Week' },
		{ type: REPEAT_TYPE.MONTHLY, name: 'Month' },
		{ type: REPEAT_TYPE.YEARLY, name: 'Year' },
	];
	const [selected, setSelected] = useState(false);

	const setSelectedComponent = (item, e) => {
		const elementsOne = document.getElementsByClassName('deleteComponent');
		for (let i = 0; i < elementsOne.length; i++) {
			elementsOne[i].style.setProperty('display', 'none', 'important');
		}

		document
			.getElementById(item.id + ' delete-component')
			?.style.setProperty('display', 'block', 'important');

		const elementsTwo = document.getElementsByClassName('selected');
		for (let i = 0; i < elementsTwo.length; i++) {
			elementsTwo[i].style.border = 'none';
		}

		document
			.getElementById(item.id)
			?.style.setProperty('border', '1px dotted #fb7a03', 'important');

		dispatch(builderActions.setComponent(item));
	};

	const deleteItem = (item) => {
		setTimeout(() => {
			dispatch(builderActions.deleteComponent(item.id));
		}, 500);
	};

	const templateEdit = (workflow) => {
		dispatch(builderActions.getClientsForWorkflow(workflow.workflow_id));
		dispatch(builderActions.setWorkflow(workflow));
		history.push(`/workflow/edit/${id}`);
	};

	const inputChangeHandler = (item, workflowName) => (e) => {
		let valueStructure = '';

		switch (item?.componentType) {
			case 'dropdown':
				valueStructure = {
					value: e.target.value,
					label: e.target.value,
				};
				break;
			case 'input':
				valueStructure = e.target.value;
				break;
			case 'datepicker':
				valueStructure = e.target.value;
				break;
			case 'textarea':
				valueStructure =e;
				break;
			case 'radio':
				valueStructure = e.target.value;
				break;
			case 'select-group':
				valueStructure = e;
				break;
			case 'pick-list':
				valueStructure = e;
				break;
			case 'software-pick-list':
				valueStructure = e;
				break;
			case 'contact-pick-list':
				valueStructure = e;
				break;
			case 'state-pick-list':
				valueStructure = e;
				break;
			case 'location-pick-list':
				valueStructure = e;
				break;
			case 'links-pick-list':
				valueStructure = e;
				break;
			case 'merchant-pick-list':
				valueStructure = e;
				break;
			case 'bank-pick-list':
				valueStructure = e;
				break;
			case 'account-pick-list':
				valueStructure = e;
				break;
			default:
				break;
		}
		dispatch(
			builderActions.setDuplicateWorkflowComponentValue({
				...item,
				value: valueStructure,
				workflowName: workflowName,
			}),
		);
	};

	const addNewTaskRow = (workflow) => {
		ModalType('WORKFLOW_TASK');
		dispatch(builderActions.clearWorkflowTaskValues());
		setFormProp({
			workflow_id: workflow?.workflow_id,
			client_id: Number(id),
		});
		toggleModal();
	};

	const deleteTablerow = (workflowName, sectionIndex, rowIndex) => {
		dispatch(
			builderActions.deleteDeactivateTableRow(
				workflowName,
				sectionIndex,
				rowIndex,
			),
		);
	};

	const changeRowValues = (
		workflowName,
		sectionIndex,
		rowIndex,
		withinRowIndex,
		e,
	) => {
		dispatch(
			builderActions.changeDeactivateWorkflowTableValue(
				e.target.value,
				workflowName,
				sectionIndex,
				rowIndex,
				withinRowIndex,
			),
		);
	};

	const reActivation = (clientID, workflowID, workflow) => {
		const { clientWorkflow } = workflow;
		delete clientWorkflow.is_tenative_task;
		if (
			clientWorkflow?.start_date === null ||
			clientWorkflow?.repeat_interval === null ||
			clientWorkflow?.repeat_type === null
		) {
			alert('Set Recurrence');
			return 0;
		}

		const workflowTasks = props?.workflowTasks.map((item) => {
			let dependent_task_id = item?.dependent_task_id;

			if (
				dependent_task_id === 0 ||
				dependent_task_id === 'null' ||
				dependent_task_id === '' ||
				dependent_task_id === '0'
			) {
				dependent_task_id = null;
			} else if (!isNaN(dependent_task_id) && dependent_task_id !== null) {
				dependent_task_id = Number(dependent_task_id);
			}

			return {
				approval_from: item?.approval_from || null,
				budgeted_hours: parseFloat(item?.budgeted_hours) || 0.1,
				title: item?.title,
				description: item?.description,
				assignee_user_id: item?.assignee_user_id,
				dependent_task_id: dependent_task_id,
				id: item?.id,
				required_days: item?.required_days,
				task_type_id: item?.task_type_id,
			};
		});

		props.clientWorkflow.client_workflow_tasks = workflowTasks
			? workflowTasks
			: [];

		const finalData = {
			...props.clientWorkflow,
			workflow_submission: {
				...props.clientWorkflow.workflow_submission,
				data: [
					{
						componentName:
							props.clientWorkflow.workflow_submission.data[0].componentName,
						displayName:
							props.clientWorkflow.workflow_submission.data[0].displayName,
						props: props.clientWorkflow.workflow_submission.data[0].props,
						propsTypes:
							props.clientWorkflow.workflow_submission.data[0].propsTypes,
					},
				],
			},
		};
		dispatch(builderActions.reActivateWorkflow(finalData, id, setSelected));
	};

	const columnChangeHandler = (e, columnIndex) => {
		const { value } = e.target;

		dispatch(builderActions.handleColumnTitleChangeHanlder(value, columnIndex));
	};

	const updateWorkflowTasks = (form) => {
		const workflowTasks = props?.workflowTasks.map((item) => {
			let dependent_task_id = item?.dependent_task_id;

			if (
				dependent_task_id === 0 ||
				dependent_task_id === 'null' ||
				dependent_task_id === '' ||
				dependent_task_id === '0'
			) {
				dependent_task_id = null;
			} else if (!isNaN(dependent_task_id) && dependent_task_id !== null) {
				dependent_task_id = Number(dependent_task_id);
			}

			return {
				approval_from: item?.approval_from || null,
				budgeted_hours: parseFloat(item?.budgeted_hours) || 0.1,
				title: item?.title,
				description: item?.description,
				assignee_user_id: item?.assignee_user_id,
				dependent_task_id: dependent_task_id,
				id: item?.id,
				required_days: item?.required_days,
				task_type_id: item?.task_type_id,
			};
		});

		props.clientWorkflow.client_workflow_tasks = form
			? [...workflowTasks, form]
			: workflowTasks
				? workflowTasks
				: [];

		// dispatch(builderActions.updateWorkflowTableValues(props.clientWorkflow));
		// dispatch(builderActions.reActivateWorkflow())
	};
	const openDynamicForm = (section, workflow, sectionIndex) => {
		ModalType('DYNAMIC_MODAL');
		const obj = {};
		const formFields = section?.tableHeaders;
		formFields?.map((item) => {
			const fieldName = item.name.split(' ').join('_');
			obj[fieldName] = '';
			return item;
		});
		setFormProp({
			formLabel: formFields,
			formName: obj,
			workflowName: workflow?.componentName,
			sectionIndex: sectionIndex,
			sectionName: section.sectionName,
		});
		toggleModal();
	};

	const deleteTask = (taskId, clientId) => {
		dispatch(
			builderActions.deletedDeactivatedWorkflowTask(taskId, Number(clientId)),
		);
		setTimeout(() => setLoadingItems([]), 3000);
	};

	const removeColumn = (index) => {
		dispatch(builderActions.removeColumn(index));
	};

	const taskTableBudgetedHoursHandler = (taskId) => (event) => {
		const indexName = event.target.name;
		const value = event.target.value;
		const pattern=/^\d*\.?\d*$/;
		if(pattern.test(value)){
			dispatch(builderActions.updateDeactivateWorkflowTableValue(taskId, value, indexName));
		}
	};

	const taskTableValueHandler = (taskId) => (event) => {
		const indexName = event.target.name;
		let value = event.target.value;

		if (indexName == 'assignee_user_id' || indexName == 'approval_from') {
			if (!isNaN(value) && value !== 0 && value !== null) {
				value = Number(value);
			} else {
				value = null;
			}
		}

		if (indexName == 'assignee_user_id' && value === null) {
			dispatch(
				builderActions.updateDeactivateWorkflowTableValue(
					taskId,
					null,
					'approval_from',
				),
			);
		}

		if (indexName === 'dependent_task_id') {
			if (value === '' || value === 'null' || value === 0) {
				value = null;
			}
		}

		if (indexName == 'budgeted_hours' || indexName == 'required_days') {
			value = Number(value);
		}
		dispatch(
			builderActions.updateDeactivateWorkflowTableValue(
				taskId,
				value,
				indexName,
			),
		);
	};

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const workflowTasks = sortTasks(props?.workflowTasks);

	const columnNameComponentSelected = (e) => {
		const { id } = e.target;

		setSelectedComponent({ componentType: 'Section-Name', id: id });
	};

	const recurrenceFormSubmit = (CurrentRecurrence, onSubmitComplete) => {
		const recurrenceObj = {
			id: CurrentRecurrence.id,
			start_date: CurrentRecurrence.start_date,
			repeat_interval: CurrentRecurrence.repeat_interval,
			repeat_type: CurrentRecurrence.repeat_type,
			is_monthly_on_day: CurrentRecurrence.is_monthly_on_day,
			recur_preference: CurrentRecurrence.recur_preference,
		};
		if (CurrentRecurrence.repeat_type !== REPEAT_TYPE.MONTHLY) {
			delete recurrenceObj.is_monthly_on_day;
			recurrenceObj.recur_preference = null;
		} else {
			if (recurrenceObj.recur_preference === null) {
				const currentDayMonth = weekOfMonth(moment(recurrenceObj.start_date));
				const nextWeekDayMonth = weekOfMonth(
					moment(recurrenceObj.start_date).add(7, 'months'),
				);
				if (currentDayMonth === 4 && currentDayMonth === nextWeekDayMonth)
					recurrenceObj.recur_preference = 1;
				if (
					currentDayMonth === 5 ||
					(currentDayMonth === 4 && currentDayMonth < nextWeekDayMonth)
				)
					recurrenceObj.recur_preference = 2;
			}
		}
		dispatch(
			builderActions.setDeactivatedWorkflowData({
				...recurrenceObj,
			}),
		);
		onSubmitComplete();
	};

	return (
		<div style={{ paddingTop: '5px' }}>
			<div className='workflow-list-item'>
				<div className='d-flex align-items-center'>
					<span className='body-1'>{props.displayName}</span>
				</div>
				<div className='d-flex align-items-center'>
					<div
						style={{ paddingLeft: '17px', cursor: 'pointer' }}
						onClick={() => setSelected(!selected)}
					>
						{selected ? <Up /> : <Down />}
					</div>
				</div>
			</div>

			{selected && (
				<div className='Profile-Content' style={{ padding: '12px 22px' }}>
					{props.props?.sections?.map((section, j) => {
						return (
							<>
								{section?.componentType == 'COLUMN' && (
									<div
										key={j}
										className='d-flex flex-column w-100 align-items-center ProfileSection'
									>
										<h2
											className={
												'HeadlineTwoEmphasizedCentra text-left w-100 pb-2'
											}
										>
											{section.componentName}
										</h2>

										<div
											className={
												section?.sectionName === 'LINKS' ||
													section?.sectionName === 'NOTES'
													? 'd-flex row w-100  justify-content-between'
													: 'd-flex row w-100 pt-2'
											}
											style={{
												borderTop: '1px solid  #EAEAEA ',
												gap: '1.2em',
											}}
										>
											{section.columns?.map((column, i) => {
												return (
													<div
														key={i}
														className='d-flex flex-column'
														style={{
															width: `calc(90% / ${section?.columns?.length})`,
															minHeight: props.isDraggable && '200px',
															minWidth: '150px',
															maxWidth: '200px',
															gap: '0.8rem',
														}}
													>
														{props?.edit && section?.sectionName !== 'NOTES' ? (
															<div
																className='d-flex flex-row justify-content-between align-items-center'
																style={{ backgroundColor: backgroundGrey }}
															>
																<h4
																	className={
																		'BodyTwoEmphasized w-100 text-left selected'
																	}
																	id={i}
																	onClick={columnNameComponentSelected}
																>
																	{column.columnName}
																</h4>

																<span>
																	<Tooltip title='Remove Column'>
																		<RemoveCircleOutlineIcon
																			fontSize='10px'
																			sx={{
																				color: '#fb7a03',
																			}}
																			onClick={() => {
																				removeColumn(i);
																			}}
																			className='pointer'
																		/>
																	</Tooltip>
																</span>
															</div>
														) : (
															<h4
																className={'BodyTwoEmphasized w-100 text-left'}
															>
																{column.columnName}
															</h4>
														)}

														<Board
															className={'Board d-flex flex-column pb-3'}
															index={{ col: i, sec: j }}
															id={i}
															Style={{
																gap: '0.7em',
																height: '100%',
															}}
														>
															{column.columnComponents?.map((item, i) => {
																let options = [];

																switch (item?.componentType) {
																	case 'software-pick-list':
																		options = clientAccounts
																			?.find((item) => item.type === 'SOFTWARE')
																			?.softwares?.map((software) => {
																				return {
																					value: software?.id,
																					label: software?.institution,
																				};
																			});
																		break;

																	case 'contact-pick-list':
																		options = client?.client_contact_list?.map(
																			(client) => {
																				return {
																					value: client.id,
																					label: `${client.first_name} ${client.last_name}`,
																				};
																			},
																		);
																		break;

																	case 'links-pick-list':
																		options = client?.links?.map((link) => {
																			return {
																				value: link.id,
																				label: `${link.name} `,
																			};
																		});
																		break;

																	case 'location-pick-list':
																		options =
																			client?.client_office_locations?.map(
																				(loc) => {
																					const state = states2.find(
																						(item) =>
																							item.value === Number(loc.state),
																					);
																					return {
																						value: loc.id,
																						label: state?.label || loc?.city,
																					};
																				},
																			);
																		break;

																	case 'state-pick-list':
																		options = [...states2];
																		break;

																	case 'account-pick-list':
																		clientAccounts
																			?.filter((acc) => acc.type !== 'SOFTWARE')
																			?.map((acc) => {
																				acc?.softwares?.map((software) => {
																					options.push({
																						value: software?.id,
																						label: software?.institution,
																					});
																					return software;
																				});
																				return acc;
																			});

																		break;
																	default:
																		options = item?.options;
																}

																let value = '';
																switch (item.name) {
																	case 'notes':
																		value = client?.notes;
																		break;
																	case 'close_week':
																		value = client?.close_week;
																		break;
																	case 'LINKNAME':
																		value = client?.link?.name;
																		break;
																	case 'LINKSTORAGE':
																		value =
																			client?.link?.account_document_storage;
																		break;
																	case 'LINKLOGIN':
																		value = client?.link?.login;
																		break;
																	case 'LINKNOTES':
																		value = client?.link?.notes;
																		break;
																	default:
																		value = item.value;
																		break;
																}

																return (
																	<Card
																		style={{
																			marginTop: props.isDraggable && '1rem',
																			marginBottom: props.isDraggable && '1rem',
																		}}
																		key={i}
																		withinColumnIndex={i}
																		id={item.id}
																		className='card selected position-relative'
																		draggable={
																			props.isDraggable && props.isDraggable
																		}
																		clickHanlder={(e) => {
																			props.isDraggable &&
																				setSelectedComponent(item, e);
																		}}
																	>
																		{props.isDraggable && (
																			<span
																				className='deleteComponent pointer d-flex justify-content-end'
																				id={item.id + ' delete-component'}
																				style={{
																					position: 'absolute',
																					bottom: '100%',
																					right: '0',
																					width: 'fit-content',
																					display: 'none',
																					zIndex: 5,
																				}}
																				onClick={() => {
																					deleteItem(item);
																				}}
																			>
																				<DeleteIcon
																					fontSize='15px'
																					sx={{ color: '#fb7a03' }}
																				/>
																			</span>
																		)}
																		{item &&
																			React.createElement(
																				getValidComponent(item?.componentType),
																				{
																					onChange: props.isDraggable
																						? () => { }
																						: inputChangeHandler(
																							item,
																							props?.componentName,
																							// eslint-disable-next-line no-mixed-spaces-and-tabs
																						),
																					Edit: props.isDraggable
																						? props.edit
																						: item.name == 'close_week' ||
																							item.name === 'notes' ||
																							section?.sectionName === 'LINKS'
																							? false
																							: edit,
																					edit: props.isDraggable
																						? props.edit
																						: item.name == 'close_week' ||
																							item.name === 'notes'
																							? false
																							: edit,
																					...item,
																					value: value,
																					link: value,
																					options: options || [],
																					style:
																						item?.componentType === 'textarea'
																							? {
																									height: 'fit-content',
																									maxHeight: '80px',
																									width: '500px',
																									paddingTop: '1px',
																									paddingBottom: '1px',
																									textAlign: 'left',
                                                  marginBottom: '2rem'
																							  }
																							: {},
																				},
																			)}
																	</Card>
																);
															})}
														</Board>
													</div>
												);
											})}
										</div>
									</div>
								)}
								{section?.componentType == 'TABLE' && (
									<div className='d-flex flex-column w-100 align-items-center ProfileSection'>
										<h2 className='HeadlineTwoEmphasizedCentra text-left w-100 pb-2'>
											{section?.componentName}
										</h2>
										<div className='w-100 d-flex flex-column'>
											<div className='Teams-Table'>
												<TableHeader
													ClassName='TableHeader flex'
													border={false}
												>
													{section?.tableHeaders?.map((header, i) => {
														return (
															<TableHeaderItem
																ClassName={'text-nowrap'}
																key={i}
																label={header?.name}
																width={TableItemWidth3}
															/>
														);
													})}

													{edit && (
														<TableHeaderItem
															label={'Remove'}
															width={TableItemWidth1}
														/>
													)}
												</TableHeader>
												{section?.tableRows?.length < 1 ? (
													<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
														<h4 className='BodyTwoLight placeholder-text'>Empty</h4>
													</div>
												) : (
													section?.tableRows?.map((item, i) => {
														return (
															<div
																key={i}
																className='TableRow d-flex align-items-center'
															>
																{item?.values.map((rowItem, itemIndex) => {
																	return (
																		<TableRowItem
																			key={itemIndex}
																			ClassName='text-left'
																			label={rowItem}
																			width={TableItemWidth3}
																			input={
																				rowItem?.includes('http') ? true : edit
																			}
																		>
																			{edit ? (
																				<EditableText
																					value={rowItem}
																					Edit={edit}
																					onChange={(e) => {
																						changeRowValues(
																							props?.componentName,
																							j,
																							i,
																							itemIndex,
																							e,
																						);
																					}}
																				/>
																			) : (
																				<a
																					className='BodyTwoLight primary-text extension-call '
																					target='_blank'
																					rel='noreferrer'
																					href={rowItem}
																					style={{
																						display: 'inline-block',
																						maxWidth: '120px',
																						whiteSpace: 'nowrap',
																						overflow: 'hidden',
																						textOverflow: 'ellipsis',
																					}}
																				>
																					{rowItem}
																				</a>
																			)}
																		</TableRowItem>
																	);
																})}

																{edit && (
																	<TableRowButton
																		ClassName='d-flex align-items-center justify-content-center'
																		width={TableItemWidth1}
																	>
																		<>
																			<RemoveCircleOutlineIcon
																				fontSize='10px'
																				sx={{
																					color: '#fb7a03',
																				}}
																				onClick={() => {
																					deleteTablerow(
																						props?.componentName,
																						j,
																						i,
																					);
																				}}
																				className='pointer'
																			/>
																		</>
																	</TableRowButton>
																)}
															</div>
														);
													})
												)}
												{edit && (
													<div className='w-100 d-flex justify-content-center'>
														<div
															className='pointer'
															style={{
																height: '22px',
																width: '22px',
																borderRadius: '3px',
																margin: '1rem',
																padding: 0,
																position: 'relative',
																backgroundColor: '#fb7a03',
															}}
															onClick={() => {
																setSelectSection(section.componentName);
																openDynamicForm(section, props, j);
															}}
														>
															<AddIcon
																fontSize='small'
																sx={{
																	color: 'white',
																	fontSize: 21,
																	position: 'absolute',
																	margin: 0,
																	padding: 0,
																	top: 0,
																	left: 0,
																}}
															/>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
								)}

								{section.componentType == 'FILLED_TABLE' && (
									<div className='d-flex flex-column w-100 align-items-center Filled-Table'>
										<div className='w-100 d-flex flex-row justify-content-between align-items-center'>
											<h2 className='HeadlineTwoEmphasizedCentra text-left w-100 pb-2'>
												{section.componentName}
											</h2>
											<div
												className='d-flex flex-row align-items-end pb-1'
												style={{
													gap: '0.5em',
													width: 'max-content',
												}}
											>
												<h4
													style={{
														width: 'max-content',
														padding: 0,
														margin: 0,
													}}
													className=' BodyOneEmphasized text-left'
												>
													Recurrence:
												</h4>
												<h4
													style={{
														width: 'max-content',
														padding: 0,
														margin: 0,
													}}
													className=' BodyOneEmphasized text-left light-dark'
												>
													{/* For Daily Recurrence */}
													{props.clientWorkflow?.repeat_type ===
														REPEAT_TYPE.DAILY &&
														`On every ${props.clientWorkflow?.repeat_interval != 1
															? ordinal_suffix_of(
																props.clientWorkflow?.repeat_interval,
															)
															: ''
														} day`}

													{/* For Weekly Recurrence */}
													{props.clientWorkflow?.repeat_type ===
														REPEAT_TYPE.WEEKLY &&
														`On ${moment(
															props.clientWorkflow?.start_date,
														).format('dddd')} of every ${props.clientWorkflow?.repeat_interval != 1
															? ordinal_suffix_of(
																props.clientWorkflow?.repeat_interval,
															)
															: ''
														} week `}

													{/* For Monthly Recurrence */}
													{props.clientWorkflow?.repeat_type ===
														REPEAT_TYPE.MONTHLY && (
															<>
																{props?.clientWorkflow?.is_monthly_on_day
																	? `On ${ordinal_suffix_of(
																		moment
																			.utc(props.clientWorkflow?.start_date)
																			.format('D'),
																	)} of every ${props.clientWorkflow?.repeat_interval != 1
																		? ordinal_suffix_of(
																			props.clientWorkflow?.repeat_interval,
																		)
																		: ''
																	} month`
																	: props.clientWorkflow?.recur_preference
																		? `On
                                            ${props.clientWorkflow
																			?.recur_preference === 1
																			? '4th'
																			: 'last'
																		}
                                            ${moment(
																			props.clientWorkflow?.start_date,
																		).format('dddd')} of every ${props.clientWorkflow?.repeat_interval != 1
																			? ordinal_suffix_of(
																				props.clientWorkflow?.repeat_interval,
																			)
																			: ''
																		} month`
																		: `On ${getWeeksInMonth(
																			props.clientWorkflow?.repeat_interval,
																		) ==
																			weekOfMonth(
																				moment(props.clientWorkflow?.start_date),
																			)
																			? 'last'
																			: ordinal_suffix_of(
																				weekOfMonth(
																					moment(
																						props.clientWorkflow?.start_date,
																					),
																				),
																			)
																		} ${moment(
																			props.clientWorkflow?.start_date,
																		).format('dddd')} of every ${props.clientWorkflow?.repeat_interval != 1
																			? ordinal_suffix_of(
																				props.clientWorkflow?.repeat_interval,
																			)
																			: ''
																		} month`}
															</>
														)}

													{/* For Yearly Recurrence */}
													{props.clientWorkflow?.repeat_type ===
														REPEAT_TYPE.YEARLY &&
														`On ${new Date(
															inputDateFormate(
																props.clientWorkflow?.start_date,
															),
														).toLocaleDateString('en-US', {
															month: 'long',
															day: 'numeric',
														})} of every ${props.clientWorkflow?.repeat_interval != 1
															? ordinal_suffix_of(
																props.clientWorkflow?.repeat_interval,
															)
															: ''
														}  year`}
												</h4>
												{(props?.clientWorkflow?.repeat_type === null ||
													props?.clientWorkflow?.start_date === null ||
													props?.clientWorkflow?.repeat_interval === null) && (
														<div
															onClick={() => {
																ModalType('RECURRENCE_MODAL');
																toggleModal();
															}}
															className='primary-text Body-TwoEmphasized pointer'
															style={{ width: 'max-content' }}
														>
															Set Recurrence
														</div>
													)}

												{edit && (
													<IconButton
														onClick={() => {
															ModalType('RECURRENCE_MODAL');
															toggleModal();
														}}
														style={{
															margin: 0,
															padding: 0,
														}}
													>
														<BorderColorIcon
															fontSize='inherit'
															style={{
																fontSize: '16px',
																color: '#fb7a03',
															}}
														/>
													</IconButton>
												)}
											</div>
										</div>
										<div className='w-100 d-flex flex-column'>
											<div className='Teams-Table'>
												<TableHeader
													ClassName='TableHeader flex'
													border={false}
												>
													{section?.tableHeaders?.map((header, i) => {
														return (
															<TableHeaderItem
																key={i}
																label={
																	header?.name == 'Budget'
																		? header?.name + 'ed Hours'
																		: header?.name == 'Description' ? 'Instructions' : header?.name
																}
																width={TableItemWidth3}
															/>
														);
													})}

													<TableHeaderItem width={TableItemWidth3}>
														<div
															className={'d-flex fle-row align-items-center'}
															style={{ gap: '5px' }}
														>
															<h3 className=' BodyOneSherif'>
																Require Approval
															</h3>
															<RequireToolTip
																title='Choose an option if this task needs approval from admin or team manager'
																placement='bottom'
															>
																<Info />
															</RequireToolTip>
														</div>
													</TableHeaderItem>
												</TableHeader>
												{workflowTasks?.length < 1 ? (
													<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
														<h4 className='BodyTwoLight placeholder-text'>Empty</h4>
													</div>
												) : (
													workflowTasks?.map((item, i) => {
														const dependentOfdependent = workflowTasks
															?.filter((t) => {
																return t.dependent_task_id == item.id;
															})
															?.map((t) => t.id);

														workflowTasks?.map((t) => {
															if (
																dependentOfdependent?.some(
																	(item) => item == t.dependent_task_id,
																)
															) {
																dependentOfdependent.push(t.id);
															}
														});
														return (
															<motion.div
																layout
																key={item.id}
																className={`TableRow d-flex align-items-center`}
															>
																<TableRowItem
																	ClassName='text-left'
																	label={item?.title}
																	width={TableItemWidth3}
																	input={edit}
																>
																	<EditableText
																		value={item?.title}
																		Edit={edit}
																		name={'title'}
																		onChange={taskTableValueHandler(item?.id)}
																	/>
																</TableRowItem>

																<TableRowItem
																	ClassName='text-left'
																	label={item?.description}
																	width={TableItemWidth3}
																	input={edit}
																>
																	<EditableTextArea
																		type={'text'}
																		rows={1}
																		style={{
																			overflowY: 'hidden',
																			lineHeight: '1rem',
																		}}
																		value={item?.description}
																		Edit={edit}
																		name={'description'}
																		onChange={taskTableValueHandler(item?.id)}
																	/>
																</TableRowItem>

																<TableRowItem
																	ClassName='text-left'
																	label={item?.budgeted_hours}
																	width={TableItemWidth3}
																	input={edit}
																>
																	<EditableText
																		value={item?.budgeted_hours}
																		Edit={edit}
																		name={'budgeted_hours'}
																		type='text'
																		onChange={taskTableBudgetedHoursHandler(
																			item?.id,
																		)}
																	/>
																</TableRowItem>
																<TableRowItem
																	ClassName='text-left'
																	label={
																		props?.users?.find(
																			(state) =>
																				state.id == item.assignee_user_id,
																		)?.full_name
																	}
																	width={TableItemWidth3}
																	input={edit}
																>
																	<EditableDropdown
																		value={{
																			label: props?.users?.find(
																				(state) =>
																					state.id == item.assignee_user_id,
																			)?.full_name,
																			value: props?.users?.find(
																				(state) =>
																					state.id == item.assignee_user_id,
																			)?.id,
																		}}
																		options={props?.users?.map((item) => {
																			return {
																				label: item.full_name,
																				value: item.id,
																			};
																		})}
																		Edit={edit}
																		name='assignee_user_id'
																		onChange={taskTableValueHandler(item?.id)}
																	/>
																</TableRowItem>

																<TableRowItem
																	ClassName='text-left'
																	label={
																		props?.workflowTasks?.find(
																			(state) =>
																				state.id == item.dependent_task_id,
																		)?.title
																	}
																	width={TableItemWidth3}
																	input={edit}
																>
																	<EditableDropdown
																		placeholder='Select Dependency'
																		value={{
																			value: props?.workflowTasks?.find(
																				(state) =>
																					state.id == item.dependent_task_id,
																			)?.id,
																		}}
																		options={workflowTasks
																			?.filter((task) => {
																				return (
																					task.id != item.id &&
																					!dependentOfdependent?.some(
																						(item) => item == task.id,
																					)
																				);
																			})
																			?.map((item) => {
																				return {
																					label: item.title,
																					value: item.id,
																				};
																			})}
																		Edit={edit}
																		name='dependent_task_id'
																		onChange={taskTableValueHandler(item?.id)}
																	/>
																</TableRowItem>

																<TableRowItem
																	ClassName='text-left'
																	label={item?.required_days}
																	width={TableItemWidth3}
																	input={edit}
																>
																	<EditableText
																		min='0'
																		value={item?.required_days}
																		Edit={edit}
																		name={'required_days'}
																		onChange={taskTableValueHandler(item?.id)}
																	/>
																</TableRowItem>

																<TableRowItem
																	ClassName='text-left'
																	label={
																		props?.users?.find(
																			(state) => state.id == item.approval_from,
																		)?.full_name
																	}
																	width={TableItemWidth3}
																	input={edit}
																>
																	<EditableDropdown
																		tooltipTitle={
																			item.assignee_user_id
																				? ''
																				: 'Please choose an assignee first'
																		}
																		selectDisabled={
																			item.assignee_user_id ? false : true
																		}
																		value={{
																			label: props?.users?.find(
																				(state) =>
																					state.id == item.approval_from,
																			)?.full_name,
																			value: props?.users?.find(
																				(state) =>
																					state.id == item.approval_from,
																			)?.id,
																		}}
																		options={props?.users
																			?.filter(
																				(user) =>
																					user.id !== item.assignee_user_id &&
																					user.role_id !== Member.teamMemberId && user.role_id !== Member.externalCollaborator,
																			)
																			?.map((item) => {
																				return {
																					label: item.full_name,
																					value: item.id,
																				};
																			})}
																		Edit={edit}
																		name='approval_from'
																		onChange={taskTableValueHandler(item?.id)}
																	/>
																</TableRowItem>
															</motion.div>
														);
													})
												)}
											</div>
										</div>
									</div>
								)}

								{section.componentType == 'COLUMN_TWO' && (
									<div
										className='d-flex flex-row w-100  ProfileSection'
										style={{
											gap: '1.5rem',
											minHeight: 'fit-content',
										}}
									>
										{section.columns?.map((col, i) => {
											return (
												<div key={i} className='w-50'>
													<h2 className='HeadlineTwoEmphasizedCentra text-left w-100 pb-2'>
														{col.columnName}
													</h2>
													<div
														className='d-flex flex-column justify-content-between pt-2'
														style={{ borderTop: '1px solid  #EAEAEA ' }}
													>
														<Board
															className={'Board d-flex flex-column pb-3'}
															index={{ col: i, sec: j }}
															id={i}
															Style={{
																paddingTop: props.isDraggable && '1.2rem',
																paddingBottom: props.isDraggable && '1.2rem',
																gap: '0.7em',
																height: '100%',
															}}
														>
															{col?.columnComponents?.map((item, i) => {
																return (
																	<Card
																		key={i}
																		withinColumnIndex={i}
																		id={item.id}
																		className='card selected position-relative'
																		draggable={
																			props.isDraggable && props.isDraggable
																		}
																		clickHanlder={(e) => {
																			props.isDraggable &&
																				setSelectedComponent(item, e);
																		}}
																	>
																		{props.isDraggable && (
																			<span
																				className='deleteComponent pointer d-flex justify-content-end'
																				id={item.id + ' delete-component'}
																				style={{
																					position: 'absolute',
																					bottom: '100%',
																					right: '0',
																					width: 'fit-content',
																					display: 'none',
																					zIndex: 5,
																				}}
																			>
																				<h3
																					onClick={() => {
																						deleteItem(item);
																					}}
																					className='BodyTwoLight primary-text d-flex justify-content-end'
																				>
																					Delete
																				</h3>
																			</span>
																		)}
																		{!edit && col?.columnName == 'Links'
																			? item.value
																				.split(' ')
																				.map((link, index) => {
																					return (
																						<a
																							key={index}
																							className='BodyTwoLight primary-text text-left pointer'
																							href={link}
																							target='_blank'
																							rel='noreferrer'
																						>
																							{removeHttp(link)}
																						</a>
																					);
																				})
																			: React.createElement(
																				getValidComponent(item.componentType),
																				{
																					onChange: props.isDraggable
																						? () => { }
																						: inputChangeHandler(
																							item,
																							props?.componentName,
																							// eslint-disable-next-line no-mixed-spaces-and-tabs
																						),
																					Edit: props.isDraggable
																						? props.edit
																						: edit,
																					edit: props.isDraggable
																						? props.edit
																						: edit,
																					...item,
																				},
																			)}
																	</Card>
																);
															})}
														</Board>
													</div>
												</div>
											);
										})}
									</div>
								)}
							</>
						);
					})}

					{edit && (
						<div
							style={{ padding: '24px 20px 15px 0px' }}
							className='d-flex justify-content-end'
						>
							<div className='VerticalSpacer'>
								<SubmitButtonsField child={true}>
									<button
										type='submit'
										className='d-flex align-items-center'
										id='Element'
										disabled={loading}
										onClick={() => reActivation(id, props._id, props)}
									>
										{loading ? (
											<CircularProgress
												size={24}
												sx={{ color: 'white' }}
												disableShrink
											/>
										) : (
											'Reactivate'
										)}
									</button>
								</SubmitButtonsField>
							</div>
						</div>
					)}
				</div>
			)}

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'WORKFLOW_TASK' && (
							<DummyModal title={'Add Task'} onClose={toggleModal}>
								<WorkflowTaskModal
									workflow={FormProp}
									onCancel={() => {
										toggleModal();
									}}
									allWorkflowTasks={workflowTasks}
									workflowID={props?.workflow_id}
									clientWorkflow={props.clientWorkflow}
									updateWorkflowTasks={updateWorkflowTasks}
								/>
							</DummyModal>
						)}
						{ModalName == 'DYNAMIC_MODAL' && (
							<DummyModal
								title={`Add ${selectedSection}`}
								onClose={toggleModal}
							>
								<DynamicModal
									workflow={FormProp}
									onCancel={() => {
										toggleModal();
									}}
									onSubmit={(workflowName, workflowSectionIndex, taskForm) => {
										dispatch(
											builderActions.addNewTableRowToDeActivateWorkflow(
												workflowName,
												workflowSectionIndex,
												taskForm,
											),
										);
										toggleModal();
									}}
								/>
							</DummyModal>
						)}
						{ModalName == 'RECURRENCE_MODAL' && (
							<DummyModal
								title={'Update workflow recurrence'}
								onClose={toggleModal}
							>
								<RecurrenceModal
									isWorkflowBrowser
									recurrence={{
										id: props.clientWorkflow?.id,
										repeat_type: props.clientWorkflow?.repeat_type,
										repeat_interval: props.clientWorkflow?.repeat_interval,
										start_date: props.clientWorkflow?.start_date,
										is_monthly_on_day:
											props.clientWorkflow?.is_monthly_on_day || false,
										recur_preference:
											props.clientWorkflow?.recur_preference || null,
									}}
									onCancel={() => {
										toggleModal();
									}}
									onSubmit={recurrenceFormSubmit}
								/>
							</DummyModal>
						)}
						{ModalName == 'DELETE_TASK_ALERT' && (
							<DummyModal title={'Alert'} onClose={toggleModal}>
								<ConfirmModal
									description={
										'Deleting the task would delete its all dependenpt tasks as well. Are you sure you want to continue?'
									}
									OnCancel={() => {
										setToDeleteTask({});
										toggleModal();
									}}
									Onsubmit={() => {
										toggleModal();

										const array = [toDeleteTask?.taskId];
										setLoadingItems(array);
										deleteTask(toDeleteTask?.taskId, toDeleteTask?.clientId);
									}}
									descriptionClassName='text-left'
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}

export default memo(ExpandDuplicatedWorkflowBox);
