/* eslint-disable no-mixed-spaces-and-tabs */
import { useHistory, useLocation } from 'react-router';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from '../components/sidebar/SideBar';
import NewSidebarItem from '../components/sidebar/NewSidebarItem';
import NewSidebarWorkflowItem from '../components/sidebar/NewSidebarWorkflowItem';
import ClientInformation from '../components/ClientManagement/ClientInformation';

import {
	clientPaymentModes,
	clientPaymentTypes,
	filterNullAttributes,
	numberWithCommas,
} from '../utilities/utilities';
import { routes } from '../utilities/routes';
import { Country } from 'country-state-city';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';

import { getUserSettings } from '../hooks/getUserSettings';

import { ReactComponent as WorkflowsIcon } from '../assets/icons/BookOpen.svg';
import { ReactComponent as ClientDetails } from '../assets/icons/client-details.svg';
import { ReactComponent as Billing } from '../assets/icons/billing.svg';
import { ReactComponent as TaskIcon } from '../assets/icons/ClipboardText.svg';
import { ReactComponent as ClientAddress } from '../assets/icons/client-address.svg';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Avatar } from '@mui/material';

import CustomIconText from '../components/TextViews/CustomIconText';
import ClientProfilePreviewActionBar from '../components/ClientManagement/ClientProfilePreview/ClientProfilePreviewActionBar';
import ClientWorkflowTask from '../components/ClientManagement/ClientWorkflowTask';

import * as clientActions from '../redux/actions/client-actions';
import TableRowItem from '../components/Others/TableRowItem';
import { TableItemWidth3, TableItemWidth4 } from '../components/TableSpacing/TableSpacingStyles';
import TableHeaderItem from '../components/Others/TableHeaderItem';
import TableHeader from '../components/Others/TableHeader';

const navigationItems = [
	{ name: 'Client Information', sectionId: 'PROFILE' },
	// { name: 'Engagement Overview', sectionId: 'ENGAGEMENT_OVERVIEW' },
];

function ClientProfilePreview() {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const ClientPreviewData = location?.state;

	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const companyProfileData = useSelector((state) => state.profileReducer.profileData);
	const currencySymbol = companyProfileData
		? getSymbolFromCurrency(companyProfileData?.currency)
		: '';

	const clientLocation = ClientPreviewData?.client_location;
	const client_profile = filterNullAttributes(ClientPreviewData?.client_profile);
	const client = { ...client_profile };
	const clientBilling = ClientPreviewData?.client_billing;
	const clientTeam = ClientPreviewData?.clientTeam;
	const clientWorkflows = ClientPreviewData?.relevant_workflows?.filter(
		(item) => item?.metadata?.workflows?.length > 0,
	);
	const workflowsToAdd = useSelector(
		(state) => state.clientReducer.workflowsToAddForClientPreview,
	);

	const [activeTab, setActiveTab] = useState('PROFILE');
	const [selectedClientworkflow, setSelectedClientworkflow] = useState(undefined);

	const toggleWorkflowsToAdd = (event) => {
		let { value } = event.target;
		value = parseInt(value);

		let tempWorkflows = [];
		if (workflowsToAdd?.includes(value)) {
			tempWorkflows = workflowsToAdd.filter((id) => id !== value);
		} else {
			tempWorkflows = [...workflowsToAdd, value];
		}

		dispatch(clientActions.setWorkflowsToAddForClientPreview(tempWorkflows));
	};

	const changeActiveTab = useCallback(
		(tab) => () => {
			setActiveTab(tab);
			setSelectedClientworkflow(undefined);
		},
		[],
	);

	const changeWorkflowActiveTab = useCallback(
		(tab) => () => {
			const workflow = clientWorkflows?.find((item) => {
				return item.metadata.workflows[0].id == tab;
			});
			setActiveTab(tab);
			setSelectedClientworkflow(workflow?.metadata?.workflows[0]);
		},
		[],
	);

	useMemo(() => {
		if (clientWorkflows?.length > 0) {
			dispatch(
				clientActions.setWorkflowsToAddForClientPreview(
					clientWorkflows?.map(({ metadata }) => metadata?.workflows?.[0]?.id),
				),
			);
		}

		return () => {
			dispatch(clientActions.setWorkflowsToAddForClientPreview([]));
		};
	}, [clientWorkflows?.length]);

	return (
		<div className='AppBody'>
			<Sidebar title='Client Management'>
				<div className='new-sidebar-content-wrapper'>
					<div className='User-filters'>
						<div className='new-side-menu BodyTwoLight-bold m-0 d-flex flex-column align-items-start'>
							{navigationItems?.map((item, index) => {
								const prevOfActive =
									navigationItems[index + 1]?.sectionId == activeTab;
								return (
									<>
										{index == 0 && (
											<div
												style={{ padding: '8px' }}
												className={`w-100 main-sidebar-item ${
													navigationItems[0].sectionId == activeTab
														? 'prev'
														: ''
												}`}
											></div>
										)}
										<NewSidebarItem
											key={item.sectionId}
											uniqueId={item.sectionId}
											label={item.name}
											isActive={activeTab == item.sectionId}
											setActiveTab={changeActiveTab}
										/>
										<div
											style={{ padding: '8px' }}
											className={`w-100 main-sidebar-item ${
												prevOfActive ? 'prev' : ''
											}`}
										></div>
									</>
								);
							})}

							<div className='new-side-menu'>
								<span
									className='text-left d-flex flex-row align-items-center new-sidebar-item-header'
									style={{ gap: '4px', background: '#364E55' }}
								>
									<div>
										<WorkflowsIcon />
									</div>
									<div className='BodyOneBold text-white'>Workflows</div>
								</span>
								{clientWorkflows?.map(
									({ metadata }, index, allClientWorkflowsNav) => {
										const workflow = metadata?.workflows[0];
										const workflowName = workflow?.name;
										const workflowId = workflow?.id;
										const prevOfActive =
											allClientWorkflowsNav[index + 1]?.metadata?.workflows[0]
												?.id == activeTab;

										return (
											<>
												{index == 0 && (
													<div
														style={{ padding: '8px' }}
														className={`w-100 main-workflow-sidebar-item ${
															prevOfActive ? 'prev' : ''
														}`}
													></div>
												)}
												<NewSidebarWorkflowItem
													key={workflowId}
													uniqueId={workflowId}
													label={`${workflowName}`}
													isActive={activeTab == workflowId}
													setActiveTab={changeWorkflowActiveTab}
													isDraft={false}
													selectableItems={true}
													handleCheckbox={toggleWorkflowsToAdd}
													checkBoxId={workflowId}
													isItemChecked={workflowsToAdd?.includes(
														workflowId,
													)}
												/>
												<div
													style={{ padding: '8px' }}
													className={`w-100 main-workflow-sidebar-item ${
														prevOfActive ? 'prev' : ''
													}`}
												></div>
											</>
										);
									},
								)}
							</div>
						</div>
					</div>
				</div>
			</Sidebar>
			<div className='content-container'>
				<div
					className='Client-Profile w-100 d-flex flex-column'
					style={{
						paddingTop: '12px',
						paddingBottom: '12px',
					}}
				>
					<div className='h-100 d-flex flex-column'>
						<div className='d-flex flex-column' style={{ gap: '32px' }}>
							<div className='d-flex flex-column' style={{ gap: '8px' }}>
								<div
									onClick={() => history.push(routes.clients)}
									className='primary-text d-flex align-items-center pointer '
								>
									<ArrowLeftIcon />
									<h2 className='HeadlineThreeBook text-left'>Clients</h2>
								</div>

								<ClientProfilePreviewActionBar />
							</div>

							<div style={{ paddingBottom: '32px' }} className=' w-100'>
								<div
									className='Profile-TopBar'
									style={{ height: '63px', borderRadius: '4px' }}
								>
									<div className='d-flex flex-row align-items-center'>
										<Avatar
											sx={{
												bgcolor: `#FED0A5`,
												color: `#333`,
												marginRight: '10px',
												height: '36px',
												width: '36px',
												fontSize: '0.8rem',
											}}
											alt={client?.company_name}
											src='/broken-image.jpg'
										/>
										<div className='d-flex flex-column' style={{ gap: '1px' }}>
											<h6 className='HeadlineThreeBold pt-1 text-left font-weight-bolder light-dark'>
												{client?.company_name}
											</h6>
											<h6 className='light-dark BodyTwoLight m-0 text-left'>
												{clientTeam?.name}
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div
							style={{
								flex: 1,
								boxSizing: 'border-box',
							}}
						>
							{activeTab == 'PROFILE' && (
								<section id='PROFILE' className='client-profile'>
									<div
										className='d-flex flex-column w-100 align-items-center'
										style={{ gap: '12px' }}
									>
										<div className='text-left w-100 d-flex align-items-center justify-content-between information-header'>
											<span className='d-flex' style={{ gap: '12px' }}>
												<ClientDetails />
												<h3 className='HeadlineThreeBold'>
													Client Details
												</h3>
											</span>
										</div>
										<div className='Profile-Content'>
											<ClientInformation
												clientTeam={clientTeam?.name}
												company_name={client?.company_name}
												business_description={client?.business_description}
												dba={client?.dba}
												industry={client?.industry}
												business_type={client?.business_type}
												company_phone={client?.company_phone}
												website={client?.website}
												address={client?.address}
												city={client?.city}
												state={client?.state}
												zip={client?.zip}
												suit_floor={client?.suit_floor}
												date={
													client?.date
														? moment(client?.date).format(date_format)
														: ''
												}
												ein={client?.ein}
												ssn={client?.ssn}
												fiscal_year_end={client?.fiscal_year_end}
												accounting={client?.accounting}
												close_week={client?.close_week}
												start_date={
													client?.start_date
														? moment(client?.start_date).format(
																date_format,
														  )
														: ''
												}
												// referral={refferedBy || ''}
												country={
													Country.getCountryByCode(client?.country)
														?.name || ''
												}
											/>
										</div>
									</div>

									<div
										className='d-flex flex-column w-100 align-items-center '
										style={{ gap: '12px' }}
									>
										<span className='text-left w-100 d-flex align-items-center information-header'>
											<span className='d-flex' style={{ gap: '12px' }}>
												<ClientAddress />
												<h3 className='HeadlineThreeBold'>
													Business Locations
												</h3>
											</span>
										</span>

										<div className='w-100 d-flex flex-column overflow-auto shadow-border'>
											<div
												style={{
													minWidth: '70vw',
													width: '100%',
												}}
											>
												<TableHeader
													ClassName='TableHeader flex'
													border={false}
												>
													<TableHeaderItem
														label='Country'
														width={TableItemWidth3}
													/>
													<TableHeaderItem
														label='Street'
														width={TableItemWidth3}
													/>
													<TableHeaderItem
														label='Suite / Floor'
														width={TableItemWidth4}
													/>
													<TableHeaderItem
														label='City/Town'
														width={TableItemWidth3}
													/>
													<TableHeaderItem
														label='State/Province/Region'
														width={TableItemWidth3}
													/>
													<TableHeaderItem
														label='Zip Code/Postal Code'
														width={TableItemWidth4}
													/>
												</TableHeader>

												<div className='TableRow d-flex align-items-center'>
													<TableRowItem
														ClassName='text-left'
														label={
															Country.getCountryByCode(
																clientLocation?.country,
															)?.name || ''
														}
														width={TableItemWidth3}
													/>
													<TableRowItem
														ClassName='text-left'
														label={clientLocation?.hq}
														width={TableItemWidth3}
													/>
													<TableRowItem
														ClassName=' text-left'
														label={clientLocation?.street}
														width={TableItemWidth4}
													/>
													<TableRowItem
														ClassName=' text-left'
														label={clientLocation?.city}
														width={TableItemWidth3}
														// input={shouldEdit[i]}
													/>
													<TableRowItem
														ClassName=' text-left'
														label={clientLocation?.state}
														width={TableItemWidth3}
													/>
													<TableRowItem
														ClassName=' text-left'
														label={clientLocation?.zip}
														width={TableItemWidth4}
													/>
												</div>
											</div>
										</div>
									</div>
								</section>
							)}

							{activeTab == 'ENGAGEMENT_OVERVIEW' && (
								<section className='client-profile'>
									<div
										className='d-flex flex-column w-100 align-items-center'
										style={{ gap: '1em' }}
									>
										<div className='text-left w-100 d-flex align-items-center justify-content-between information-header'>
											<span className='d-flex' style={{ gap: '12px' }}>
												<Billing />
												<h3 className='HeadlineThreeBold'>
													Billing Details
												</h3>
											</span>
										</div>
										<div className='Profile-Content'>
											{clientBilling ? (
												<div
													className='d-flex row w-100 justify-content-between information-content'
													style={{
														borderTop: '1px solid  #EAEAEA ',
														padding: '1em',
														gap: '2%',
														background: 'white',
														width: '100%',
													}}
												>
													<div
														className='d-flex flex-column text-left'
														style={{
															minHeight: '200px',
															gap: '0.8rem',
															width: '100%',
														}}
													>
														<div
															style={{
																display: 'flex',
																justifyContent: 'space-between',
															}}
														>
															<span className='BodyOneBold'>
																Payment and Rate Details
															</span>
														</div>
														<div
															className='d-flex flex-column'
															style={{ gap: '0.8rem' }}
														>
															<div className='text-left ProfilePage'>
																<CustomIconText
																	edit={false}
																	label='Payment Type'
																	name='payment_type'
																	value={
																		clientPaymentTypes?.find(
																			(item) =>
																				item.value ==
																				clientBilling?.payment_type,
																		)?.label
																	}
																/>
															</div>

															<div className='text-left ProfilePage'>
																<CustomIconText
																	edit={false}
																	label='Payment Frequency'
																	name='payment_mode'
																	className={'text-left'}
																	value={
																		clientPaymentModes?.find(
																			(item) =>
																				item.value ==
																				clientBilling?.payment_mode,
																		)?.label
																	}
																/>
															</div>

															<div className='text-left ProfilePage'>
																<CustomIconText
																	label={`Rate`}
																	name='rate'
																	type='number'
																	edit={false}
																	value={
																		clientBilling?.rate
																			? `${currencySymbol}${numberWithCommas(
																					clientBilling?.rate,
																			  )}`
																			: ''
																	}
																/>
															</div>

															<div className='text-left ProfilePage'>
																<CustomIconText
																	edit={false}
																	label='Rate Start Date'
																	name='bill_start_date'
																	value={
																		clientBilling?.bill_start_date
																			? moment(
																					clientBilling?.bill_start_date,
																			  ).format(date_format)
																			: ''
																	}
																/>
															</div>
														</div>
													</div>
												</div>
											) : (
												<div
													className='d-flex row w-100 justify-content-between information-content'
													style={{
														borderTop: '1px solid  #EAEAEA ',
														padding: '1em',
														gap: '2%',
														background: 'white',
														width: '100%',
													}}
												>
													<h3 className='BodyTwoLight mb-0 table-header-text  ml-1 m-auto opacity-75'>
														No billing against this client. Create a new
														billing!
													</h3>
												</div>
											)}
										</div>
									</div>
								</section>
							)}

							{Number.isInteger(activeTab) && (
								<section id='PROFILE' className='client-profile'>
									<div
										className='d-flex flex-column w-100 align-items-center'
										style={{ gap: '12px' }}
									>
										<div
											style={{
												padding: '12px 18px',
												background: 'white',
											}}
											className='text-left w-100 d-flex align-items-center justify-content-between'
										>
											<span className='d-flex' style={{ gap: '12px' }}>
												<h3 className='HeadlineThreeBold'>
													{selectedClientworkflow?.name}
												</h3>
											</span>
										</div>
										<div className='Profile-Content' style={{ gap: '12px' }}>
											<div className='client-workflow-task-section'>
												<span
													className='d-flex task-section-header'
													style={{ gap: '12px' }}
												>
													<TaskIcon />
													<h3 className='HeadlineThreeBold'>Tasks</h3>
												</span>
												{selectedClientworkflow?.task_templates?.map(
													(task, index, total_tasks) => {
														const dependent_task_name =
															total_tasks?.find(
																(item) =>
																	item.id ==
																	task.dependent_task_id,
															)?.title;

														return (
															<ClientWorkflowTask
																key={task.id}
																disableActions={true}
																task={task}
																dependent_task_name={
																	dependent_task_name
																}
															/>
														);
													},
												)}
											</div>
										</div>
									</div>
								</section>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ClientProfilePreview;
