import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../sidebar/SideBar';
import { getUserSettings } from '../../hooks/getUserSettings';
import DropDown from '../Inputs/DropDown';
import { getTimeRange, TIME_RANGE_OPTIONS } from '../../utilities/TimeRangeUtils';
import React, { useEffect, useMemo, useState } from 'react';
import EditableRadioButton from '../Inputs/EditableRadioButton';
import { roundUpConsumedTimeInDecimal, TASK_TYPE } from '../../utilities/utilities';
import * as TimeReportsActions from '../../redux/actions/time-reports-actions-v2';
import * as workspaceActions from '../../redux/actions/dashboard/workspaces-actions';
import SkeletonLoading from '../Others/SkeletonLoading';
import { useHistory, useLocation } from 'react-router';
import { CSVLink } from 'react-csv';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import NewDateRangePicker from '../Inputs/NewDateRangePicker';

const groupByOptions = [
	{ id: 'workflow', value: 'Workflow' },
	{ id: 'task', value: 'Task' },
];

const sortByOptions = [
	{ id: 'workflow', value: 'Workflow' },
	{ id: 'date', value: 'Date' },
];

const WorkflowTimeReportsHeaders = [
	{ label: 'Workflow', key: 'workflow' },
	{ label: 'Client', key: 'client' },
	{ label: 'Logged Hours', key: 'loggedHours' },
];

const TaskTimeReportsHeaders = [
	{ label: 'Date', key: 'date' },
	{ label: 'Workflow', key: 'workflow' },
	{ label: 'Task', key: 'task' },
	{ label: 'Client', key: 'client' },
	{ label: 'Logged Hours', key: 'loggedHours' },
];

function TimeReportsPreviewUser() {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [groupBy, setGroupBy] = useState('Workflow');
	const [sortBy, setSortBy] = useState('Workflow');
	const [dateRange, setDateRange] = useState(TIME_RANGE_OPTIONS[2].value);
	const [dateRanges, setDateRanges] = useState(null);
	const [timeReportsData, setTimeReportsData] = useState([]);
	const [loadingTimeReports, setLoadingTimeReports] = useState(true);

	const userDetails = useSelector((state) => state.authReducer.userDetails);

	const csvHeaders = groupBy == 'Workflow' ? WorkflowTimeReportsHeaders : TaskTimeReportsHeaders;
	const userData = location?.state;

	const { date_format } = getUserSettings(userDetails);

	const handleBreakDownBy = (event) => {
		setTimeReportsData([]);
		const value = event.target.value;
		setGroupBy(value);
	};

	const handleSortBy = (event) => {
		const value = event.target.value;
		setSortBy(value);
	};

	const onTimeChange = (event) => {
		setDateRange(event.target.value);
	};

	const sortByWorkflow = (data = []) => {
		return data?.sort((a, b) => {
			if (a.workflow < b.workflow) return -1;
			if (a.workflow > b.workflow) return 1;
			return 0;
		});
	};

	const sortByDate = (data = []) => {
		return data?.sort((a, b) => {
			if (a.date < b.date) return -1;
			if (a.date > b.date) return 1;
			return 0;
		});
	};

	const TimeReportsCalculationGroupByWorkflow = (data) => {
		const clientWorkflows = {};

		data.forEach((item) => {
			const clientWorkflow = item.task.my_task
				? item.task.my_task.client_workflow
				: item.task.client_workflow;
			const myTaskType = item.task.my_task ? item.task.my_task.task_type : null;
			const client = item.task.client ? item.task.client : { company_name: '-' };
			const consumedTime = parseInt(item.consumed_time);

			let workflowId;
			let displayName;

			if (clientWorkflow) {
				workflowId = clientWorkflow.id;
				displayName = clientWorkflow.display_name.trim();
			} else if (myTaskType === TASK_TYPE.MEETING || myTaskType === TASK_TYPE.ADHOC) {
				workflowId = myTaskType;
				displayName = myTaskType === TASK_TYPE.MEETING ? 'Meeting' : 'Ad Hoc';
			} else {
				return;
			}

			if (!clientWorkflows[workflowId]) {
				clientWorkflows[workflowId] = {
					displayName: displayName,
					id: workflowId,
					totalConsumedTime: 0,
					clients: {},
				};
			}

			const clientId = client.id || '-';
			const clientName =
				client?.dba == '' || client?.dba == null ? client?.company_name : client?.dba;

			if (!clientWorkflows[workflowId].clients[clientId]) {
				clientWorkflows[workflowId].clients[clientId] = {
					clientName: clientName,
					id: clientId,
					totalConsumedTime: 0,
					totalClientLoggedHours: '0.00 hrs',
				};
			}

			clientWorkflows[workflowId].clients[clientId].totalConsumedTime += consumedTime;
		});

		const result = Object.values(clientWorkflows).map((workflow) => {
			// Filter out clients with less than 15 minutes (900000 milliseconds)
			const filteredClients = Object.values(workflow.clients).filter(
				(client) => client.totalConsumedTime >= 900000,
			);

			// Recalculate the total consumed time for the workflow after filtering clients
			const totalConsumedTime = filteredClients.reduce(
				(acc, client) => acc + client.totalConsumedTime,
				0,
			);

			return {
				displayName: workflow.displayName,
				id: workflow.id,
				totalConsumedTime: totalConsumedTime,
				totalWorkflowLoggedHours: roundUpConsumedTimeInDecimal(totalConsumedTime / 3600000),
				clients: filteredClients.map((client) => ({
					clientName: client.clientName,
					id: client.id,
					totalConsumedTime: client.totalConsumedTime,
					totalClientLoggedHours: roundUpConsumedTimeInDecimal(
						client.totalConsumedTime / 3600000,
					),
				})),
			};
		});

		// Filter workflows whose total consumed time is greater than or equal to 15 minutes
		return result?.filter((item) => item.totalConsumedTime >= 900000);
	};

	const TimeReportsCalculationGroupByTask = (data) => {
		return data
			?.map((item) => {
				const clientName =
					item?.dba == '' || item?.dba == null ? item?.company_name : item?.dba;
				const consumedTime = Number(item.consumed_time);
				const clientWorkflowId = item.client_workflow_id
					? item.client_workflow_id
					: item.my_task_client_workflow_id;
				const clientWorkflowName = item.client_workflow_name
					? item.client_workflow_name
					: item.my_task_client_workflow_name;
				return {
					taskType: item.task_type,
					clientWorkflowId: clientWorkflowId,
					date: item.logged_date,
					workflow: clientWorkflowId
						? clientWorkflowName
						: item.task_type === TASK_TYPE.MEETING
						? 'Meeting'
						: 'Ad Hoc',
					task: item.title,
					client: clientName,
					consumedTime,
					loggedHours: roundUpConsumedTimeInDecimal(consumedTime / 3600000),
				};
			})
			.filter((item) => item.consumedTime >= 900000);
	};

	const totalConsumedTimeSum = useMemo(() => {
		if (!timeReportsData?.length) return 0;

		if (groupBy == 'Workflow')
			return roundUpConsumedTimeInDecimal(
				timeReportsData.reduce((acc, workflow) => {
					return acc + workflow.totalConsumedTime;
				}, 0) / 3600000,
			);

		if (groupBy == 'Task')
			return roundUpConsumedTimeInDecimal(
				timeReportsData.reduce((acc, item) => {
					const consumedTime = item.consumedTime;
					return acc + consumedTime;
				}, 0) / 3600000,
			);
	}, [timeReportsData, groupBy]);

	const csvDataForWorkflowTimeReports = useMemo(() => {
		if (groupBy == 'Task') return [];
		return timeReportsData.flatMap((workflow) => [
			{
				workflow: workflow.displayName,
				client: '-',
				loggedHours: workflow.totalWorkflowLoggedHours,
			},
			...workflow.clients.map((client) => ({
				workflow: '',
				client: client.clientName,
				loggedHours: client.totalClientLoggedHours,
			})),
		]);
	}, [timeReportsData]);

	useEffect(() => {
		if (dateRanges && userData?.id) {
			setLoadingTimeReports(true);
			dispatch(
				TimeReportsActions.getUserTimeExportReports(
					userData?.id,
					groupBy.toLowerCase(),
					dateRanges.from.format('YYYY-MM-DD'),
					dateRanges.to.format('YYYY-MM-DD'),
				),
			)
				.then((result) => {
					if (groupBy == 'Workflow') {
						const timeReportDataRestructure =
							TimeReportsCalculationGroupByWorkflow(result);
						setTimeReportsData(timeReportDataRestructure);
					}
					if (groupBy == 'Task') {
						const timeReportDataRestructure = TimeReportsCalculationGroupByTask(result);
						if (sortBy == 'Workflow')
							setTimeReportsData(sortByWorkflow(timeReportDataRestructure));
						if (sortBy == 'Date')
							setTimeReportsData(sortByDate(timeReportDataRestructure));
					}
					setLoadingTimeReports(false);
				})
				.catch((error) => {
					dispatch(workspaceActions.setSnackBarIsOpen(false, true, error?.message));
					setLoadingTimeReports(false);
				});
		}
	}, [dateRanges, groupBy]);

	useEffect(() => {
		if (sortBy == 'Date') {
			setLoadingTimeReports(true);
			setTimeout(() => {
				sortByDate(timeReportsData);
				setLoadingTimeReports(false);
			}, 2000);
		}
		if (sortBy == 'Workflow') {
			setLoadingTimeReports(true);
			setTimeout(() => {
				sortByWorkflow(timeReportsData);
				setLoadingTimeReports(false);
			}, 2000);
		}
	}, [sortBy]);

	useEffect(() => {
		const selectedDateRanges = getTimeRange(dateRange);
		if (selectedDateRanges) setDateRanges(selectedDateRanges);
	}, [dateRange]);

	return (
		<div className='AppBody'>
			<Sidebar title='Time Reports'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'></div>
				</div>
			</Sidebar>
			<div className='time-report-preview'>
				<div className='time-report-preview-content-wrapper'>
					<div className='py-2 d-flex flex-row align-items-center pointer primary-text'>
						<ArrowLeftIcon />
						<h2
							className='HeadlineThreeBook text-left'
							onClick={() => history.push(`/time-reports-user/${userData?.id}`)}
						>
							{userData?.name}
						</h2>
					</div>

					<div
						className='d-flex flex-column'
						style={{
							background: 'white',
							padding: '18px',
							borderRadius: '4px',
							gap: '24px',
						}}
					>
						<div className='w-100 d-flex justify-content-between'>
							<SkeletonLoading
								loading={loadingTimeReports}
								variant='text'
								width='20%'
								height={40}
							>
								<span className='HeadlineThreeBold'>{userData?.name}</span>
							</SkeletonLoading>

							<SkeletonLoading
								loading={loadingTimeReports}
								variant='button'
								className='rounded-1'
								width='15%'
								height={40}
							>
								<button className='rounded-button-1'>
									<CSVLink
										data={
											groupBy == 'Workflow'
												? csvDataForWorkflowTimeReports
												: timeReportsData
										}
										headers={csvHeaders}
										filename={`${userData?.name}_${dateRanges?.from?.format(
											date_format,
										)} - ${dateRanges?.to?.format(date_format)}.csv`}
										className=' text-white text-decoration-none'
									>
										Download
									</CSVLink>
								</button>
							</SkeletonLoading>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								borderRadius: '4px',
								padding: '18px',
								gap: '24px',
								background: '#F7F9FA',
								width: '100%',
							}}
						>
							<div
								className='w-100 d-flex'
								style={{ gap: '24px', background: '#F7F9FA' }}
							>
								<SkeletonLoading
									loading={loadingTimeReports}
									variant='rectangular'
									className='rounded-1'
									width='25%'
									height='30px'
								>
									<div className='improved-input-designs text-left w-25'>
										<label>Date Range</label>
										<DropDown
											normalDropDown
											style={{
												height: '30px',
												borderRadius: '4px',
												minWidth: '200px',
											}}
											onChange={onTimeChange}
											options={TIME_RANGE_OPTIONS}
											value={dateRange}
											disableIcon
											disableNull
										/>
									</div>
								</SkeletonLoading>

								{dateRange === TIME_RANGE_OPTIONS[0].value && (
									<SkeletonLoading
										loading={loadingTimeReports}
										variant='rectangular'
										className='rounded-1'
										width='25%'
										height='30px'
									>
										<div className='d-flex w-25' style={{ gap: '24px' }}>
											<NewDateRangePicker
												label='Select Date Ranges'
												dateRanges={dateRanges}
												applyDates={(dates) => setDateRanges(dates)}
											/>
										</div>
									</SkeletonLoading>
								)}

								<SkeletonLoading
									loading={loadingTimeReports}
									variant='rectangular'
									className='rounded-1'
									width='25%'
									height='30px'
								>
									<div className='improved-input-designs text-left w-25'>
										<label>Breakdown Report</label>

										<div className='h-100 d-flex align-items-center'>
											<EditableRadioButton
												value={groupBy}
												onChange={handleBreakDownBy}
												options={groupByOptions}
												Edit
											/>
										</div>
									</div>
								</SkeletonLoading>

								{groupBy == 'Task' && (
									<SkeletonLoading
										loading={loadingTimeReports}
										variant='rectangular'
										className='rounded-1'
										width='25%'
										height='30px'
									>
										<div className='improved-input-designs text-left w-25'>
											<label>Group by</label>

											<div className='h-100 d-flex align-items-center'>
												<EditableRadioButton
													value={sortBy}
													onChange={handleSortBy}
													options={sortByOptions}
													Edit
												/>
											</div>
										</div>
									</SkeletonLoading>
								)}
							</div>

							{dateRanges && (
								<div className='date-range-bar d-flex justify-content-between'>
									<SkeletonLoading
										loading={loadingTimeReports}
										className='rounded-1'
										width='40%'
										height={34}
										variant='rectangular'
									>
										<div className='HeadlineTwoBold'>
											{`${dateRanges.from.format(date_format)} -
										${dateRanges.to.format(date_format)}`}
										</div>
									</SkeletonLoading>

									<SkeletonLoading
										loading={loadingTimeReports}
										className='rounded-1'
										width='20%'
										height={34}
										variant='rectangular'
									>
										<div className='HeadlineTwoBold'>
											{`Total Hours ${totalConsumedTimeSum}`}
										</div>
									</SkeletonLoading>
								</div>
							)}

							<div
								style={{
									boxSizing: 'border-box',
								}}
								className='d-flex flex-grow-1'
							>
								{groupBy == 'Workflow' && (
									<TimeReportsGroupByWorkflow
										loading={loadingTimeReports}
										data={timeReportsData}
									/>
								)}
								{groupBy == 'Task' && (
									<TimeReportsGroupByTask
										loading={loadingTimeReports}
										data={timeReportsData}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TimeReportsPreviewUser;

const TimeReportsGroupByWorkflow = ({ data, loading = true }) => {
	return (
		<table className='TimeReportsPreviewTable'>
			<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
				<thead>
					<tr>
						<th>Workflow</th>
						<th>Client</th>
						<th>Logged Hours</th>
					</tr>
				</thead>
			</SkeletonLoading>

			<tbody>
				{data.length == 0 && (
					<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
						<tr>
							<td className='text-center light-text' colSpan={3}>
								No Time Reports in this period
							</td>
						</tr>
					</SkeletonLoading>
				)}
				{data?.map((timeData) => {
					return (
						<React.Fragment key={timeData?.id}>
							<SkeletonLoading
								loading={loading}
								variant='text'
								width='100%'
								height={45}
							>
								<tr>
									<td>{timeData?.displayName}</td>
									<td>-</td>
									<td className='bold-td'>
										{timeData?.totalWorkflowLoggedHours}
									</td>
								</tr>
								{timeData?.clients?.map((client) => {
									return (
										<tr key={client?.id}>
											<td></td>
											<td>{client?.clientName}</td>
											<td>{client?.totalClientLoggedHours}</td>
										</tr>
									);
								})}
							</SkeletonLoading>
						</React.Fragment>
					);
				})}
				<tr></tr>
			</tbody>
		</table>
	);
};

const TimeReportsGroupByTask = ({ data, loading = true }) => {
	return (
		<table className='TimeReportsPreviewTable'>
			<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
				<thead>
					<tr>
						<th>Date</th>
						<th>Workflow</th>
						<th>Task</th>
						<th>Client</th>
						<th>Logged Hours</th>
					</tr>
				</thead>
			</SkeletonLoading>

			<tbody>
				{data.length == 0 && (
					<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
						<tr>
							<td className='text-center light-text' colSpan={5}>
								No Time Reports in this period
							</td>
						</tr>
					</SkeletonLoading>
				)}
				{data.map((item, key) => {
					return (
						<React.Fragment key={item.loggedHours + key}>
							<SkeletonLoading
								loading={loading}
								variant='text'
								width='100%'
								height={45}
							>
								<tr>
									<td>{item.date}</td>
									<td>{item.workflow}</td>
									<td>{item.task}</td>
									<td>{item.client}</td>
									<td>{item.loggedHours}</td>
								</tr>
							</SkeletonLoading>
						</React.Fragment>
					);
				})}
				<tr></tr>
			</tbody>
		</table>
	);
};
