const user = {
	name: 'Lorum seump',
	email: 'micheal.anthony42@gmail.com',
	role: 'Team Lead',
	team: 'Team',
};
const client = {
	name: 'Lorum seump',
	industry: 'Clothing',
	business: 'Architect',
	state: 'Ohio',
};
const team = {
	name: 'Lorum seump',
	des: 'Lorem ipsum dolor consectetur adipiscing elit sit amet, consectetur adipiscing elit.',
};

export const BillTypes = [
	{ id: 1, value: 'Billable' },
	{ id: 2, value: 'Non-Billable' },
];

export const RepeatTypes = [
	{
		value: 'DAILY',
		label: 'DAILY',
	},
	{
		value: 'WEEKLY',
		label: 'WEEKLY',
	},
	{
		value: 'MONTHLY',
		label: 'MONTHLY',
	},
	{
		value: 'ANNUALLY',
		label: 'ANNUALLY',
	},
];

export const weeklyTasks = [
	{
		id: 1,
		business_type: 'Software',
		close_week: 'Pending',
		company_name: 'Venturenox',
		company_phone: 'Pending',
		created_at: '2022-02-17T08:24:46.960Z',
		date: '2022-02-18',
		dba: 'Dba',
		document_storage: 'Pending',
		ein: '92-1234567',
		fiscal_year_end: 'Pending',
		industry: 'tech',
		software: 'Pending',
		start_date: 'Pending',
		state: '17',
		updated_at: '2022-02-17T08:24:46.961Z',
		tasks: [
			{
				id: 1,
				assignee_user_id: 10002,
				budgeted_hours: 4,
				status: 'past-due',
				created_at: '2022-02-21T11:09:24.766Z',
				dependent_task_id: null,
				description: 'halalal',
				due_date: '2022-02-23T11:09:00.000Z',
				is_billable: true,
				repeat_time: null,
				repeat_type: null,
				start_date: null,
				task_type_id: 1,
				title: 'task 2',
				updated_at: '2022-02-21T11:09:24.767Z',
				workflow_id: null,
			},
			{
				id: 2,
				assignee_user_id: 10002,
				budgeted_hours: 2,
				status: 'pending',
				created_at: '2022-02-21T11:09:24.766Z',
				dependent_task_id: null,
				description: 'This is task 2',
				due_date: '2022-02-23T11:09:00.000Z',
				is_billable: true,
				repeat_time: null,
				repeat_type: null,
				start_date: null,
				task_type_id: 1,
				title: 'task 3',
				updated_at: '2022-02-21T11:09:24.767Z',
				workflow_id: null,
			},
		],
	},
	{
		id: 2,
		business_type: 'Industry',
		close_week: 'Pending',
		company_name: 'Hello',
		company_phone: 'Pending',
		created_at: '2022-02-17T08:24:46.960Z',
		date: '2022-02-18',
		dba: 'Dba',
		document_storage: 'Pending',
		ein: '92-1234567',
		fiscal_year_end: 'Pending',
		industry: 'tech',
		software: 'Pending',
		start_date: 'Pending',
		state: '19',
		updated_at: '2022-02-17T08:24:46.961Z',
		tasks: [
			{
				id: 1,
				assignee_user_id: 10002,
				budgeted_hours: 4,
				status: 'past-due',
				created_at: '2022-02-21T11:09:24.766Z',
				dependent_task_id: null,
				description: 'halalal',
				due_date: '2022-02-23T11:09:00.000Z',
				is_billable: true,
				repeat_time: null,
				repeat_type: null,
				start_date: null,
				task_type_id: 1,
				title: 'task ',
				updated_at: '2022-02-21T11:09:24.767Z',
				workflow_id: null,
			},
			{
				id: 2,
				assignee_user_id: 10002,
				budgeted_hours: 2,
				status: 'pending',
				created_at: '2022-02-21T11:09:24.766Z',
				dependent_task_id: null,
				description: 'This is task 2',
				due_date: '2022-02-23T11:09:00.000Z',
				is_billable: true,
				repeat_time: null,
				repeat_type: null,
				start_date: null,
				task_type_id: 1,
				title: 'task 2',
				updated_at: '2022-02-21T11:09:24.767Z',
				workflow_id: null,
			},
			{
				id: 3,
				assignee_user_id: 10002,
				budgeted_hours: 6,
				status: 'blocked',
				created_at: '2022-02-21T11:09:24.766Z',
				dependent_task_id: null,
				description: 'This is task 3',
				due_date: '2022-02-23T11:09:00.000Z',
				is_billable: true,
				repeat_time: null,
				repeat_type: null,
				start_date: null,
				task_type_id: 1,
				title: 'task 3',
				updated_at: '2022-02-21T11:09:24.767Z',
				workflow_id: null,
			},
		],
	},
];

export const Clients = [
	client,
	client,
	client,
	client,
	client,
	client,
	client,
	client,
	client,
	client,
	client,
	client,
	client,
	client,
	client,
	client,
	client,
	client,
	client,
];
export const Teams = [
	team,
	team,
	team,
	team,
	team,
	team,
	team,
	team,
	team,
	team,
	team,
	team,
	team,
	team,
	team,
	team,
	team,
	team,
	team,
];
export const Users = [
	user,
	user,
	user,
	user,
	user,
	user,
	user,
	user,
	user,
	user,
	user,
	user,
	user,
	user,
	user,
	user,
	user,
	user,
	user,
];

export const states2 = [
	{ label: 'Alabama', value: 1 },
	{ label: 'Alaska', value: 2 },
	{ label: 'Arizona', value: 3 },
	{ label: 'Arkansas', value: 4 },
	{ label: 'California', value: 5 },
	{ label: 'Colorado', value: 6 },
	{ label: 'Connecticut', value: 7 },
	{ label: 'Delaware', value: 8 },
	{ label: 'Florida', value: 9 },
	{ label: 'Georgia', value: 10 },
	{ label: 'Hawaii', value: 11 },
	{ label: 'Idaho', value: 12 },
	{ label: 'Illinois', value: 13 },
	{ label: 'Indiana', value: 14 },
	{ label: 'Iowa', value: 15 },
	{ label: 'Kansas', value: 16 },
	{ label: 'Kentucky', value: 17 },
	{ label: 'Louisiana', value: 18 },
	{ label: 'Maine', value: 19 },
	{ label: 'Maryland', value: 20 },
	{ label: 'Massachusetts', value: 21 },
	{ label: 'Michigan', value: 22 },
	{ label: 'Minnesota', value: 23 },
	{ label: 'Mississippi', value: 24 },
	{ label: 'Missouri', value: 25 },
	{ label: 'Montana', value: 26 },
	{ label: 'Nebraska', value: 27 },
	{ label: 'Nevada', value: 28 },
	{ label: 'New Hampshire', value: 29 },
	{ label: 'New Jersey', value: 30 },
	{ label: 'New Mexico', value: 31 },
	{ label: 'New York', value: 32 },
	{ label: 'North Carolina', value: 33 },
	{ label: 'North Dakota', value: 34 },
	{ label: 'Ohio', value: 35 },
	{ label: 'Oklahoma', value: 36 },
	{ label: 'Oregon', value: 37 },
	{ label: 'Pennsylvania', value: 38 },
	{ label: 'Rhode Island', value: 39 },
	{ label: 'South Carolina', value: 40 },
	{ label: 'South Dakota', value: 41 },
	{ label: 'Tennessee', value: 42 },
	{ label: 'Texas', value: 43 },
	{ label: 'Utah', value: 44 },
	{ label: 'Vermont', value: 45 },
	{ label: 'Virginia', value: 46 },
	{ label: 'Washington', value: 47 },
	{ label: 'West Virginia', value: 48 },
	{ label: 'Wisconsin', value: 49 },
	{ label: 'Wyoming', value: 50 },
]?.sort((a, b) => a.label.localeCompare(b.label));

export const states = [
	{ value: 'Alabama', label: 'Alabama' },
	{ value: 'Alaska', label: 'Alaska' },
	{ value: 'Arizona', label: 'Arizona' },
	{ value: 'Arkansas', label: 'Arkansas' },
	{ value: 'California', label: 'California' },
	{ value: 'Colorado', label: 'Colorado' },
	{ value: 'Connecticut', label: 'Connecticut' },
	{ value: 'Delaware', label: 'Delaware' },
	{ value: 'Florida', label: 'Florida' },
	{ value: 'Georgia', label: 'Georgia' },
	{ value: 'Hawaii', label: 'Hawaii' },
	{ value: 'Idaho', label: 'Idaho' },
	{ value: 'Illinois', label: 'Illinois' },
	{ value: 'Indiana', label: 'Indiana' },
	{ value: 'Iowa', label: 'Iowa' },
	{ value: 'Kansas', label: 'Kansas' },
	{ value: 'Kentucky', label: 'Kentucky' },
	{ value: 'Louisiana', label: 'Louisiana' },
	{ value: 'Maine', label: 'Maine' },
	{ value: 'Maryland', label: 'Maryland' },
	{ value: 'Massachusetts', label: 'Massachusetts' },
	{ value: 'Michigan', label: 'Michigan' },
	{ value: 'Minnesota', label: 'Minnesota' },
	{ value: 'Mississippi', label: 'Mississippi' },
	{ value: 'Missouri', label: 'Missouri' },
	{ value: 'Montana', label: 'Montana' },
	{ value: 'Nebraska', label: 'Nebraska' },
	{ value: 'Nevada', label: 'Nevada' },
	{ value: 'New Hampshire', label: 'New Hampshire' },
	{ value: 'New Jersey', label: 'New Jersey' },
	{ value: 'New Mexico', label: 'New Mexico' },
	{ value: 'New York', label: 'New York' },
	{ value: 'North Carolina', label: 'North Carolina' },
	{ value: 'North Dakota', label: 'North Dakota' },
	{ value: 'Ohio', label: 'Ohio' },
	{ value: 'Oklahoma', label: 'Oklahoma' },
	{ value: 'Oregon', label: 'Oregon' },
	{ value: 'Pennsylvania', label: 'Pennsylvania' },
	{ value: 'Rhode Island', label: 'Rhode Island' },
	{ value: 'South Carolina', label: 'South Carolina' },
	{ value: 'South Dakota', label: 'South Dakota' },
	{ value: 'Tennessee', label: 'Tennessee' },
	{ value: 'Texas', label: 'Texas' },
	{ value: 'Utah', label: 'Utah' },
	{ value: 'Vermont', label: 'Vermont' },
	{ value: 'Virginia', label: 'Virginia' },
	{ value: 'Washington', label: 'Washington' },
	{ value: 'West Virginia', label: 'West Virginia' },
	{ value: 'Wisconsin', label: 'Wisconsin' },
	{ value: 'Wyoming', label: 'Wyoming' },
].sort((a, b) => a.label.localeCompare(b.label));

export const Departments = [
	{ label: 'Accounting and Audit', value: 'Accounting and Audit' },
	{ label: 'Taxation', value: 'Taxation' },
	{ label: 'Advisory and Consulting', value: 'Advisory and Consulting' },
	{ label: 'Bookkeeping', value: 'Bookkeeping' },
	{ label: 'Payroll', value: 'Payroll' },
	{ label: 'Forensic Accounting', value: 'Forensic Accounting' },
	{ label: 'IT and Technology', value: 'IT and Technology' },
	{ label: 'Compliance and Regulatory', value: 'Compliance and Regulatory' },
	{ label: 'Human Resources', value: 'Human Resources' },
	{
		label: 'Marketing and Business Development',
		value: 'Marketing and Business Development',
	},
	{ label: 'Client Services', value: 'Client Services' },
	{ label: 'Finance and Treasury', value: 'Finance and Treasury' },
	{
		label: 'Administration and Operations',
		value: 'Administration and Operations',
	},
	{ label: 'Legal', value: 'Legal' },
	{ label: 'Research and Development', value: 'Research and Development' },
	{ label: 'Quality Control', value: 'Quality Control' },
	{ label: 'Training and Development', value: 'Training and Development' },
	{
		label: 'Sustainability and Environmental Accounting',
		value: 'Sustainability and Environmental Accounting',
	},
	{ label: 'International Accounting', value: 'International Accounting' },
	{ label: 'Special Projects', value: 'Special Projects' },
];

export const industries2 = [
	{
		label: 'Accounting/Advisory Services',
		value: 'Accounting/Advisory Services',
	},
	{ label: 'Accounting/Tax Services', value: 'Accounting/Tax Services' },
	{
		label: 'Advertising/Marketing/Sales',
		value: 'Advertising/Marketing/Sales',
	},
	{ label: 'Architecture Services', value: 'Architecture Services' },
	{ label: 'Artist Gallery', value: 'Artist Gallery' },
	{ label: 'Athletics', value: 'Athletics' },
	{ label: 'Attorneys/Law Firms', value: 'Attorneys/Law Firms' },
	{ label: 'Banking', value: 'Banking' },
	{ label: 'Bookkeeping', value: 'Bookkeeping' },
	{ label: 'Design', value: 'Design' },
	{ label: 'Education', value: 'Education' },
	{ label: 'Entertainment', value: 'Entertainment' },
	{ label: 'Family Office', value: 'Family Office' },
	{ label: 'Fitness', value: 'Fitness' },
	{ label: 'Food & Beverage', value: 'Food & Beverage' },
	{ label: 'Finance/CFO Services', value: 'Finance/CFO Services' },
	{ label: 'Finance/Financial Advisor', value: 'Finance/Financial Advisor' },
	{
		label: 'Finance/Securities/Investments',
		value: 'Finance/Securities/Investments',
	},
	{ label: 'Healthcare', value: 'Healthcare' },
	{ label: 'Hospitality', value: 'Hospitality' },
	{ label: 'Human Resources', value: 'Human Resources' },
	{ label: 'Insurance', value: 'Insurance' },
	{ label: 'Managed Service Providers', value: 'Managed Service Providers' },
	{ label: 'Non-Profit', value: 'Non-Profit' },
	{ label: 'Other Business Services', value: 'Other Business Services' },
	{ label: 'Production', value: 'Production' },
	{ label: 'Professional Service', value: 'Professional Service' },
	{ label: 'Public Relations', value: 'Public Relations' },
	{ label: 'Publishing', value: 'Publishing' },
	{ label: 'Retail/Ecommerce', value: 'Retail/Ecommerce' },
	{ label: 'Real Estate', value: 'Real Estate' },
	{ label: 'Research', value: 'Research' },
	{ label: 'Technology', value: 'Technology' },
	{ label: 'Wholesale', value: 'Wholesale' },
	{ label: 'Fine Arts', value: 'Fine Arts' },
	{ label: 'Agencies', value: 'Agencies' },
	{ label: 'Agriculture', value: 'Agriculture' },
	{ label: 'Construction', value: 'Construction' },

].sort((a, b) => a.label.localeCompare(b.label));

const newIndustry = { label: 'Other Industry', value: 'Other Industry' };
industries2.push(newIndustry);

export const business_types2 = [
	{ label: 'LLC', value: 'LLC' },
	{ label: 'LLP', value: 'LLP' },
	{ label: 'S-Corp', value: 'S-Corp' },
	{ label: 'C-Corp', value: 'C-Corp' },
	{ label: 'LTD', value: 'LTD' },
	{ label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
	{ label: 'Individual', value: 'Individual' },
    { label: 'Company Director', value: 'Company Director'}
]?.sort((a, b) => a.value.localeCompare(b.value));

export const timeZones = [
	{
		label: 'ET (UTC-05:00) (New York)',
		value: 'ET (UTC-05:00) (New York)',
	},
	{
		label: 'CT (UTC-06:00) (Illinois)',
		value: 'CT (UTC-06:00) (Illinois)',
	},
	{
		label: 'MT (UTC-07:00) (Colorado)',
		value: 'MT (UTC-07:00) (Colorado)',
	},
	{
		label: 'PT (UTC-08:00) (Nevada)',
		value: 'PT (UTC-08:00) (Nevada)',
	},
]?.sort((a, b) => a.value.localeCompare(b.value));

export const identificationTypes = [
	{ label: 'SSN', value: 1 },
	{ label: 'EIN', value: 2 },
];
export const costMode = [
	{ id: 1, label: 'Hourly Pay', value: 'hourly' },
	{ id: 2, label: 'Annual Salary', value: 'annual' },
];

export const leaveTypes = [
	{ label: 'Annual', value: 1 },
	{ label: 'Vacation', value: 2 },
	{ label: 'PTO', value: 3 },
	{ label: 'Medical', value: 4 },
];
