import * as Actions from '../actions/action-center';

const initialState = {
	leaveForm: {
		dates: [],
	},
	leaveTypes: [],
	loading: false,
	leaves: [],
	userLeaves: [],
	onboarding: null,
	userStatus: {},
	is_pause_notifications: false,
	status: null,
	icon: null,
	initialLoading: false,
	notifications: [],
	approvalPendingTasks: [],
	selectedTime: { time: 'Please choose an option' },
	actionSideBarComponent: null,
};

const ActionCenterReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case Actions.HANDLE_LEAVE_CHANGE:
			return (state = {
				...state,
				leaveForm: {
					...state.leaveForm,
					[payload.name]: payload.value,
				},
			});
		case Actions.HANDLE_DATES_CHANGE:
			return (state = {
				...state,
				leaveForm: {
					...state.leaveForm,
					dates: payload,
				},
			});
		case Actions.GET_LEAVE_TYPES:
			return (state = {
				...state,
				leaveTypes: payload,
			});
		case Actions.SUBMIT_LEAVES_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.SUBMIT_LEAVES_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.SUBMIT_LEAVES_FORM:
			return (state = {
				...state,
				loading: false,
				leaveForm: {},
			});
		case Actions.GET_LEAVES:
			return (state = {
				...state,
				leaves: payload,
			});
		case Actions.GET_USER_LEAVES:
			return (state = {
				...state,
				userLeaves: payload,
			});
		case Actions.UPDATE_LEAVE_STATUS:
			var leaveIndex = state.leaves.findIndex((leave) => leave.id === payload.id);
			var tempLeaveArr = [...state.leaves];

			tempLeaveArr[leaveIndex].status = payload.status;

			return (state = {
				...state,
				leaves: [...tempLeaveArr],
			});
		case Actions.RESET_LEAVES_MODAL:
			return (state = {
				...state,
				leaveForm: {},
			});
		case Actions.GET_ONBOARDING:
			return (state = {
				...state,
				onboarding: payload,
			});
		case Actions.SET_STATUS:
			return (state = {
				...state,
				status: payload,
			});
		case Actions.SET_EMOJI:
			return (state = {
				...state,
				icon: payload,
			});
		case Actions.SET_SELECTED_TIME:
			return (state = {
				...state,
				selectedTime: payload,
			});
		case Actions.SET_YOURSELF_AWAY_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.SET_YOURSELF_AWAY:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.SET_YOURSELF_AWAY_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.UPDATE_YOURSELF_AWAY_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.UPDATE_YOURSELF_AWAY:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.UPDATE_YOURSELF_AWAY_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_USER_STATUS_START:
			return (state = {
				...state,
				initialLoading: true,
			});
		case Actions.GET_USER_STATUS:
			return (state = {
				...state,
				userStatus: payload,
				initialLoading: false,
			});
		case Actions.SET_PAUSE_NOTIFICATIONS:
			return (state = {
				...state,
				is_pause_notifications: payload,
			});
		case Actions.GET_APPROVAL_PENDING:
			return (state = {
				...state,
				approvalPendingTasks: payload,
			});
		case Actions.GET_NOTIFICATIONS:
			return (state = {
				...state,
				notifications: payload,
			});
		case Actions.UPDATE_NOTIFICATIONS:
			return (state = {
				...state,
				notifications: payload,
			});
		case Actions.SET_ACTION_SIDE_BAR_COMPONENT:
			return (state = {
				...state,
				actionSideBarComponent: payload,
			});
		default:
			return state;
	}
};

export default ActionCenterReducer;
