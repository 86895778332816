/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useEffect, useMemo, useState } from 'react';
import Sidebar from '../components/sidebar/SideBar';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../redux/actions/workflow-builder-actions';
import * as clientActions from '../redux/actions/client-actions';
import { CircularProgress, Input, Modal } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import { executivePermissions, getUniqueObjectArray, isTeamManager } from '../utilities/utilities';
import { ReactComponent as BookOpenIcon } from '../assets/icons/BookOpen.svg';
import { ReactComponent as Ellipse } from '../assets/icons/Ellipse.svg';
import NewSidebarItem from '../components/sidebar/NewSidebarItem';
import CompanyLibrary from '../components/WorkflowBuilder/CompanyLibrary';
import PreBuiltLibrary from '../components/WorkflowBuilder/PreBuiltLibrary';
import AddToClientModal from '../components/Modal/AddToClientModal';
import Filter from '../components/sidebar/Filter';
import useModal from '../components/Modal/useModal';
import DuplicateWorkflows from '../components/Modal/DuplicateWorkflows';
import * as builderActions from '../redux/actions/workflow-builder-actions';
import { lightGreen } from '../components/Style';
import { setSnackBarIsOpen } from '../redux/actions/dashboard/workspaces-actions';
import PreBuildDuplicateWorkflows from '../components/Modal/PreBuildDuplicateWorkflows';
import AlertBar from '../components/sub_components/AlertBar';
import PrebuildDraftSuccesWorkflows from '../components/Modal/PrebuildDraftSuccesWorkflows';
import ToggleButton from '../components/Inputs/ToggleButton';

export default function WorkflowLibrary() {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [isPrebuiltChecked, setIsPrebuiltChecked] = useState([]);
	const [isPublishChecked, setIsPublishChecked] = useState([]);
	const [nameSearch, setNameSearch] = useState('');
	const [selectedCategories, setSelectedCategories] = useState([]);
	// const [addToClientModal, setAddToClientModal] = useState(false);
	const [selectedWorkflow, setSelectedWorkflow] = useState(null);
	const [draftedWorkflows, setDraftedWorkflows] = useState([]);
	const [groupByCategory, setGroupByCategory] = useState(false);
	const [createWorkflowLoading, setCreateWorkflowLoading] = useState(false);

	const duplicateWorkflows = useSelector(
		(state) => state.WorkflowBuilder.workflowsToActivate,
	).duplicate_workflows;
	const duplicateDraftWorkflows = useSelector(
		(state) => state.WorkflowBuilder.workflowsToDraft,
	).draft_duplicate_workflows;

	const prebuiltWorkflows = useSelector((state) => state.WorkflowBuilder.prebuiltWorkflows);
	const profileData = useSelector((state) => state.profileReducer.profileData);

	const clientForbuilder = location?.state;

	let companyWorkflows = useSelector((state) => state.WorkflowBuilder.companyWorkflows);
	const actionsloading = useSelector((state) => state.WorkflowBuilder.builderLoadSave);
	const loading = useSelector((state) => state.WorkflowBuilder.loading);
	const user = useSelector((state) => state.authReducer.user);

	const [activeTab, setActiveTab] = useState('COMPANY_LIBRARY');
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [statusFilters, setStatusFilters] = useState({
		published: false,
		draft: false,
	});

	const filterOptions = useMemo(() => {
		return [
			{ label: 'Published', value: 'published', is_checked: statusFilters?.published },
			{ label: 'Draft', value: 'draft', is_checked: statusFilters?.draft },
		];
	}, [statusFilters]);

	let categoriesFilter =
		activeTab === 'COMPANY_LIBRARY' ? [...companyWorkflows] : [...prebuiltWorkflows];

	if (categoriesFilter?.length > 0) {
		categoriesFilter = categoriesFilter?.map((workflow) => {
			return {
				label: workflow.name,
				value: workflow.id,
				is_checked: selectedCategories?.includes(workflow?.id),
			};
		});
	}

	companyWorkflows = useMemo(() => {
		if (!statusFilters?.published && !statusFilters.draft) {
			return companyWorkflows;
		} else
			return companyWorkflows
				.map((category) => ({
					...category,
					workflows: category.workflows.filter(
						(workflow) =>
							(statusFilters?.published && workflow.status === 'PUBLISHED') ||
							(statusFilters.draft && workflow.status === 'DRAFT'),
					),
				}))
				.filter((category) => category.workflows.length > 0);
	}, [statusFilters, companyWorkflows]);

	const handleCheckBoxes = (workflow, stateUpdater, maxItems = 10) => {
		stateUpdater((prevChecked) => {
			if (prevChecked.some((item) => item.id === workflow.id)) {
				return prevChecked.filter((item) => item.id !== workflow.id);
			} else {
				if (prevChecked.length >= maxItems) {
					showMaxAlert();
					return prevChecked;
				}
				if (prevChecked.length >= 9) {
					showMaxAlert();
				}
				return [...prevChecked, workflow];
			}
		});
	};

	const showMaxAlert = () => {
		dispatch(
			setSnackBarIsOpen(
				false,
				true,
				activeTab == 'COMPANY_LIBRARY'
					? 'You can add up to 10 workflows at once. Add the selected workflows to the client and add more workflows later.'
					: 'You can save up to 10 workflows at a time. Save the selected workflows now and save more workflows later.',
			),
		);
	};

	const createNewWorkflow = () => {
		setCreateWorkflowLoading(true);
		dispatch(Actions.createNewWorkflowFromLibrary(history, clientForbuilder))
			.then(() => setCreateWorkflowLoading(false))
			.catch(() => setCreateWorkflowLoading(false));
	};

	const navigationItems = [
		{ name: 'Company Library', sectionId: 'COMPANY_LIBRARY' },
		{ name: 'Levvy Prebuilt Library', sectionId: 'PREBUILT_LIBRARY' },
	];

	isTeamManager(user?.user_roles?.map((item) => item.role_id)) && navigationItems?.pop();

	const tabChangeHandler = useCallback(
		(tab) => () => {
			setActiveTab(tab);
		},
		[],
	);

	const changeHandler = (event) => {
		setNameSearch(event.target.value);
	};

	const moveWorkflowsToDraft = (workflow) => {
		dispatch(
			Actions.moveWorkflowsToDraft({
				workflows: isPrebuiltChecked.length
					? isPrebuiltChecked?.map((isCheck) => {
							return { id: isCheck.id, name: isCheck.name };
					  })
					: [workflow],
			}),
		)
			.then((response) => {
				handleDraftWorflowSuccess(response?.saved_workflows, false);
			})
			.catch((error) => {
				//
			});
		setIsPrebuiltChecked([]);
	};

	const handleDraftWorflowSuccess = (savedWorkflows, duplicateFlow) => {
		setDraftedWorkflows(savedWorkflows);
		ModalType('DRAFT_WORKFLOWS_SUCCESS_MODAL');
		!duplicateFlow && toggleModal();
	};

	const handleSelectClient = (workflow) => {
		setSelectedWorkflow(workflow);
		dispatch(Actions.setClientSelectedForWorkflowActivation());
		ModalType('ADD_TO_CLIENT_MODAL');
		toggleModal();
	};

	const handleAddToClient = (clientID, workflow) => {
		let workflowArr = workflow
			? [workflow]
			: selectedWorkflow
			? [selectedWorkflow]
			: [...isPublishChecked];

		workflowArr = workflowArr?.map((workflow) => {
			return {
				...workflow,
				name: workflow?.name?.trim(),
				status: 'draft',
				task_templates: workflow.task_templates.map((task) => {
					return {
						...task,
						budgeted_hours: parseFloat(task.budgeted_hours) || 0.1,
					};
				}),
			};
		});

		dispatch(Actions.activateWorkflow(clientID, workflowArr, null, history)).then((data) => {
			if (data?.duplicate_workflows?.length !== 0) {
				dispatch(
					builderActions.setWorkflowsToActivate({
						duplicate_workflows: data?.duplicate_workflows,
						valid_workflows: data?.valid_workflows,
					}),
				);
			}
			if (data?.duplicate_workflows?.length == 0 && data?.valid_workflows?.length) {
				dispatch(clientActions.setClientProfileActiveTab(data?.valid_workflows[0]?.id));
				dispatch(builderActions.setSelectedClientWorkflow(data?.valid_workflows[0]?.id));
				dispatch(builderActions.setWorkflowsToActivate());
				history.push(`/client-profile/${clientID}`);
			}
		});
	};

	const publishWorkflows = (workflowId) => {
		dispatch(
			Actions.publishWorkflowsFromDraft({
				workflows: [workflowId],
			}),
		);
	};

	const archiveWorkflows = (wokflowId) => {
		dispatch(Actions.archiveWorkflow(wokflowId))
			.then((response) => {
				if (response.status === 'DRAFT') {
					dispatch(Actions.filterCompanyWorkflow(response));
					dispatch(setSnackBarIsOpen(true, true, 'Workflow is successfully deleted'));
				}
				if (response.status === 'PUBLISHED') {
					dispatch(Actions.updateCompanyWorkflow(response));
					dispatch(setSnackBarIsOpen(true, true, 'Workflow is successfully archived'));
				}
			})
			.catch((error) => {
				dispatch(setSnackBarIsOpen(false, true, error.message));
			});
	};

	const toggleCategoryFilter = () => {
		setGroupByCategory(!groupByCategory);
	};

	const handleFilterCategories = (event) => {
		const { value } = event.target;
		if (selectedCategories?.includes(parseInt(value))) {
			setSelectedCategories(
				selectedCategories?.filter((category) => category !== parseInt(value)),
			);
		} else {
			setSelectedCategories([...selectedCategories, parseInt(value)]);
		}
	};

	const handleFilterWorkflowStatus = (event) => {
		const { value } = event.target;
		setStatusFilters((prev) => {
			return {
				...prev,
				[value]: !prev[value],
			};
		});
	};

	const clearFilterWorkflowStatus = () => {
		setStatusFilters({
			published: false,
			draft: false,
		});
	};

	const InputClass = {
		width: '100%',
		fontFamily: 'CentraLight',
		fontWeight: 'light',
		fontSize: '14px !important',
		lineHeight: '18px',
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.25)',
		padding: '8px 6px 2px 8px',
		borderRadius: '4px',
	};

	useEffect(() => {
		if (duplicateWorkflows?.length > 0) {
			!modalIsOpen && toggleModal();
			ModalType('DUPLICATE_WORKFLOWS');
		}
		if (duplicateDraftWorkflows?.length > 0) {
			!modalIsOpen && toggleModal();
			ModalType('DUPLICATE_PREBUILD_WORKFLOWS');
		}
	}, [duplicateWorkflows, duplicateDraftWorkflows]);

	useEffect(() => {
		prebuiltWorkflows?.length < 1 && dispatch(Actions.getPreBuiltWorkflows());
		companyWorkflows?.length < 1 && dispatch(Actions.getCompanyWorkflows());
		dispatch(Actions.setTaskTemplatesArray([]));
		dispatch(clientActions.getClients());
	}, [dispatch]);

	useEffect(() => {
		setSelectedCategories([]);
		setNameSearch('');
	}, [activeTab]);

	useEffect(() => {
		if (companyWorkflows?.length == 0) {
			setActiveTab('PREBUILT_LIBRARY');
		} else {
			!modalIsOpen && setActiveTab('COMPANY_LIBRARY');
		}
	}, [companyWorkflows?.length]);

	return (
		<div className='AppBody'>
			<Sidebar title='Workflow Library'>
				<div className='new-sidebar-content-wrapper'>
					<div className='User-filters'>
						<div className='new-side-menu BodyTwoLight-bold m-0 d-flex flex-column align-items-start'>
							{navigationItems?.map((item, index) => {
								const prevOfActive =
									navigationItems[index + 1]?.sectionId == activeTab;
								return (
									<>
										{index == 0 && (
											<div
												style={{ padding: '8px' }}
												className={`w-100 main-sidebar-item ${
													navigationItems[0].sectionId == activeTab
														? 'prev'
														: ''
												}`}
											></div>
										)}
										<NewSidebarItem
											key={item.sectionId}
											uniqueId={item.sectionId}
											label={item.name}
											isActive={activeTab == item.sectionId}
											setActiveTab={tabChangeHandler}
										/>
										<div
											style={{ padding: '8px' }}
											className={`w-100 main-sidebar-item ${
												prevOfActive ? 'prev' : ''
											}`}
										></div>
									</>
								);
							})}
							<div
								className='main-sidebar-item w-100 d-flex justify-content-center'
								style={{ padding: '0 12px' }}
							>
								<Input
									sx={InputClass}
									disableUnderline={true}
									size='small'
									placeholder='Search Workflow'
									name='first'
									value={nameSearch}
									onChange={changeHandler}
								/>
							</div>
							{activeTab == 'COMPANY_LIBRARY' && (
								<div className=' main-sidebar-item justify-content-center w-100'>
									<div
										className='main-sidebar-item SidebarListWrapper d-flex'
										style={{ padding: '16px', gap: '10px' }}
									>
										<Filter
											title='Filter'
											options={filterOptions}
											onChange={handleFilterWorkflowStatus}
											onClear={clearFilterWorkflowStatus}
										/>
										<div className='w-100 d-flex align-items-center justify-content-between'>
											<label className='BodyTwoLight white-text text-left m-0'>
												Group by Category
											</label>
											<ToggleButton
												checked={groupByCategory}
												onChange={toggleCategoryFilter}
											/>
										</div>
									</div>
								</div>
							)}
							<div className=' main-sidebar-item justify-content-center w-100'>
								<div
									className='main-sidebar-item SidebarListWrapper'
									style={{ padding: '16px' }}
								>
									<Filter
										title='Categories'
										options={getUniqueObjectArray(categoriesFilter)}
										onChange={(e) => handleFilterCategories(e)}
										onClear={() => setSelectedCategories([])}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Sidebar>
			<div
                 className='content-container content-overflow'
			>
				<AlertBar />
				<div
					className='workflow-browser w-100 d-flex flex-column'
					style={{
						paddingTop: '35px',
						backgroundColor: '#EEF2F3',
						padding: '24px 32px',
					}}
				>
					{clientForbuilder?.clientName && (
						<div
							className='py-2 pointer'
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								color: `${lightGreen}`,
							}}
						>
							<ArrowLeftIcon />

							<h2
								onClick={() =>
									history.push(`client-profile/${clientForbuilder?.clientId}`)
								}
								className='HeadlineThreeBook text-left'
							>
								{clientForbuilder.clientName}
							</h2>
						</div>
					)}
					<div className='workflow-browser-header'>
						<div className='d-flex align-items-center' style={{ gap: '12px' }}>
							<BookOpenIcon width={32} height={32} />
							<span className='HeadlineTwoBold pl-2 mt-1'>
								{navigationItems?.find((item) => activeTab == item.sectionId)?.name}
							</span>
						</div>
						{activeTab !== 'COMPANY_LIBRARY' ||
							(executivePermissions?.includes(user?.userRole?.id) && (
								<div
									className='d-flex justify-content-between align-items-center'
									style={{
										gap: '12px',
									}}
								>
									<span
										className='BodyOneLight primary-text pointer'
										onClick={() => history.push('/archived-workflow')}
									>
										Archived Workflows
									</span>
									<Ellipse />
									<span
										className='BodyOneLight primary-text pointer'
										style={{
											opacity: createWorkflowLoading ? 0.5 : 1,
											width: '160px',
										}}
										onClick={createNewWorkflow}
									>
										{createWorkflowLoading ? (
											<CircularProgress
												size={20}
												sx={{ color: '#fb7a03' }}
												disableShrink
											/>
										) : (
											'Create New Workflow'
										)}
									</span>
								</div>
							))}
					</div>

					{clientForbuilder && (
						<div className='workflow-categories-header' style={{ marginTop: '32px' }}>
							<span className='BodyOneLight'>
								You are browsing workflows for{' '}
								<span className='BodyOneLight primary-text'>
									{clientForbuilder?.clientName}
								</span>
							</span>
						</div>
					)}

					{activeTab === 'PREBUILT_LIBRARY' || activeTab === 'COMPANY_LIBRARY' ? (
						<div className='workflow-library-switcher'>
							<div>
								<span className='BodyOneLight'>
									{activeTab === 'PREBUILT_LIBRARY' ? (
										<div
											className='d-flex align-items-center'
											style={{ gap: '5rem' }}
										>
											<span className='d-flex align-items-center'>
												<span
													className='d-flex align-items-center'
													style={{ gap: '16px' }}
													onClick={() => setIsPrebuiltChecked([])}
												>
													<span
														className={`BodyOneLight pt-1 primary-text pointer ${
															isPrebuiltChecked?.length == 0
																? 'opacity-50'
																: ''
														} `}
													>
														Clear All
													</span>
												</span>
												<span
													className='pt-1'
													style={{ paddingLeft: '16px' }}
												>
													{`${isPrebuiltChecked.length}/10`}{' '}
													{isPrebuiltChecked.length > 1
														? 'workflows'
														: 'workflow'}{' '}
													selected{' '}
												</span>
											</span>
											<button
												className='rounded-button d-flex align-items- justify-content-center'
												disabled={isPrebuiltChecked.length < 1}
												style={{
													width: '180px',
												}}
												onClick={moveWorkflowsToDraft}
											>
												{actionsloading ? (
													<CircularProgress
														size={20}
														sx={{ color: 'white' }}
														disableShrink
													/>
												) : (
													<p className='BodyTwoBold m-0 p-0 pt-1'>
														Save as Draft
													</p>
												)}
											</button>
										</div>
									) : activeTab === 'COMPANY_LIBRARY' ? (
										<div
											className='d-flex align-items-center'
											style={{ gap: '5rem' }}
										>
											<span className='d-flex align-items-center'>
												<span
													className='d-flex align-items-center'
													style={{ gap: '16px' }}
													onClick={() => setIsPublishChecked([])}
												>
													<span
														className={`BodyOneLight pt-1 primary-text pointer ${
															isPublishChecked.length == 0
																? 'opacity-50'
																: ''
														} `}
													>
														Clear All
													</span>
												</span>
												<span
													className='pt-1'
													style={{ paddingLeft: '16px' }}
												>
													{`${isPublishChecked.length}/10`}{' '}
													{isPublishChecked.length > 1
														? 'workflows'
														: 'workflow'}{' '}
													selected{' '}
												</span>
											</span>
											<button
												className='rounded-button d-flex align-items- justify-content-center'
												onClick={() => {
													clientForbuilder
														? handleAddToClient(
																clientForbuilder?.clientId,
														  )
														: handleSelectClient(null);
												}}
												disabled={
													isPublishChecked.length < 1 || actionsloading
												}
											>
												{actionsloading ? (
													<CircularProgress
														size={20}
														sx={{ color: 'white' }}
														disableShrink
													/>
												) : (
													<p className='BodyTwoBold m-0 p-0 pt-1'>
														Add To Client
													</p>
												)}
											</button>
										</div>
									) : null}
								</span>
							</div>
						</div>
					) : null}
					<div>
						{activeTab === 'PREBUILT_LIBRARY' && (
							<PreBuiltLibrary
								prebuiltWorkflows={prebuiltWorkflows
									?.filter(
										(category) =>
											selectedCategories?.length === 0 ||
											selectedCategories?.includes(category?.id),
									)
									?.map((category) => {
										const filteredWorkflows =
											category?.workflow_libraries?.filter((workflow) =>
												workflow?.name
													?.toLowerCase()
													.includes(nameSearch?.toLowerCase()),
											);
										return filteredWorkflows?.length > 0
											? {
													...category,
													workflow_libraries: filteredWorkflows,
											  }
											: null;
									})
									?.filter((category) => category !== null)}
								setActiveTab={setActiveTab}
								handleCheckBoxes={(workflow) => {
									handleCheckBoxes(workflow, setIsPrebuiltChecked);
								}}
								isChecked={isPrebuiltChecked}
								moveWorkflowsToDraft={moveWorkflowsToDraft}
							/>
						)}
						{activeTab === 'COMPANY_LIBRARY' && (
							<div>
								<CompanyLibrary
									companyWorkflows={companyWorkflows
										?.filter(
											(workflow) =>
												selectedCategories?.length === 0 ||
												selectedCategories?.includes(workflow?.id),
										)
										?.map((category) => {
											const filteredWorkflows = category?.workflows
												?.filter((workflow) =>
													workflow?.name
														?.toLowerCase()
														.includes(nameSearch?.toLowerCase()),
												)
												?.filter((workflow) => !workflow?.deleted);
											return filteredWorkflows?.length > 0
												? {
														...category,
														workflows: filteredWorkflows,
												  }
												: null;
										})
										?.filter((category) => category !== null)}
									profileData={profileData}
									handleCheckBoxes={(workflowId) =>
										handleCheckBoxes(workflowId, setIsPublishChecked)
									}
									isChecked={isPublishChecked}
									setActiveTab={setActiveTab}
									handleSelectClient={handleSelectClient}
									publishWorkflows={publishWorkflows}
									archivePublishedWorkflows={archiveWorkflows}
									handleAddToClient={handleAddToClient}
									groupByCategory={groupByCategory}
								/>
							</div>
						)}
					</div>
				</div>
			</div>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'ADD_TO_CLIENT_MODAL' && (
							<AddToClientModal onClose={toggleModal} onDone={handleAddToClient} />
						)}

						{ModalName == 'DUPLICATE_WORKFLOWS' && (
							<DuplicateWorkflows
								onClose={toggleModal}
								clientId={clientForbuilder?.clientId}
							/>
						)}

						{ModalName == 'DUPLICATE_PREBUILD_WORKFLOWS' && (
							<PreBuildDuplicateWorkflows
								onClose={toggleModal}
								handleDraftWorflowSuccess={handleDraftWorflowSuccess}
							/>
						)}

						{ModalName == 'DRAFT_WORKFLOWS_SUCCESS_MODAL' && (
							<PrebuildDraftSuccesWorkflows
								onClose={toggleModal}
								draftedWorkflows={draftedWorkflows}
								setActiveTab={setActiveTab}
							/>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}
