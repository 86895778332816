/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as invoiceActions from '../redux/actions/invoices-actions';
import { useParams } from 'react-router';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { setSnackBarIsOpen } from '../redux/actions/dashboard/workspaces-actions';
import FormButtonOne from '../components/Buttons/FormButtonOne';

const PdfPreview = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const [invoiceData, setInvoiceData] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		dispatch(invoiceActions.getInvoicePreview(id))
			.then((res) => {
				setInvoiceData(res);
			})
			.catch((err) => {
				console.log(err, 'err');
			});
	}, [dispatch]);

	const downloadPdf = () => {
		setLoading(true);
		dispatch(invoiceActions.downloadInvoicePdf(id))
			.then((response) => {
				const href = URL.createObjectURL(response);

				const link = document.createElement('a');
				link.href = href;
				link.target = '_blank';
				link.setAttribute('download', 'invoice.pdf');

				document.body.appendChild(link);

				link.click();

				document.body.removeChild(link);
				URL.revokeObjectURL(href);
				setLoading(false);
			})
			.catch((error) => {
				dispatch(setSnackBarIsOpen(true, true, error.message));
			});
	};

	return (
		<div className='container mt-2' style={{ width: '100vw' }}>
			<div className='card h-100 p-4'>
				{invoiceData == null ? (
					<div className='h-100 d-flex align-items-center'>
						<CircularProgress
							style={{
								color: '#fa7b03',
								height: '50px',
								width: '50px',
							}}
						/>
					</div>
				) : (
					<div>
						<div className='w-100 mb-4 d-flex justify-content-between'>
							<h2 className='text-left'>Invoice</h2>
							<div className='text-right mt-2'>
								<FormButtonOne
									onClick={downloadPdf}
									type='button'
									loading={loading}
								>
									Download as PDF
								</FormButtonOne>
							</div>
						</div>
						<div
							className='d-flex flex-column align-items-start'
							style={{ width: '60%', gap: '5px', marginBottom: '80px' }}
						>
							<div className='d-flex w-100 justify-content-between'>
								<p className='w-50 text-left m-0'>
									<span className='DescriptionLight'>Invoice Number</span>
								</p>
								<p className='w-50 text-left m-0 DescriptionLight'>
									{' '}
									{invoiceData?.invoice_no}
								</p>
							</div>
							<div className='d-flex w-100 justify-content-between'>
								<p className='w-50 text-left m-0'>
									<span className='DescriptionLight'>Date of Issue</span>
								</p>
								<p className='w-50 text-left m-0 DescriptionLight'>
									{' '}
									{moment
										.utc(invoiceData?.issue_date)
										.format(invoiceData?.date_format)}
								</p>
							</div>
							<div className='d-flex w-100 justify-content-between'>
								<p className='w-50 text-left m-0'>
									<span className='DescriptionLight'>Due Date</span>
								</p>
								<p className='w-50 text-left m-0 DescriptionLight'>
									{' '}
									{moment
										.utc(invoiceData?.due_date)
										.format(invoiceData?.date_format)}
								</p>
							</div>
						</div>
						<div
							className='d-flex justify-content-between mb-3'
							style={{ width: '65%' }}
						>
							<div>
								<p className='text-left m-0'>
									<span className='DescriptionOne'>From</span>
								</p>
								{invoiceData?.from?.country && (
									<p className='text-left m-0 DescriptionLight'>
										{invoiceData.from.country}
									</p>
								)}
								{invoiceData?.from?.name && (
									<p className='text-left m-0 DescriptionLight'>
										{invoiceData.from.name}
									</p>
								)}
							</div>
							<div>
								<p className='text-left m-0'>
									<span className='DescriptionOne'>Invoice For</span>
								</p>
								{invoiceData?.invoice_for?.dba ||
								invoiceData?.invoice_for?.company_name ? (
									<p className='text-left m-0 DescriptionLight'>
										{invoiceData.invoice_for.dba ||
											invoiceData.invoice_for.company_name}
									</p>
								) : null}
								{invoiceData?.invoice_for?.billing_contact?.first_name && (
									<p className='text-left m-0 DescriptionLight'>
										{invoiceData.invoice_for.billing_contact.first_name}
									</p>
								)}
								{invoiceData?.invoice_for?.billing_contact?.last_name && (
									<p className='text-left m-0 DescriptionLight'>
										{invoiceData.invoice_for.billing_contact.last_name}
									</p>
								)}
								{invoiceData?.invoice_for?.billing_contact?.phone_no && (
									<p className='text-left m-0 DescriptionLight'>
										{invoiceData.invoice_for.billing_contact.phone_no}
									</p>
								)}
								{invoiceData?.invoice_for?.billing_contact?.email_to && (
									<p className='text-left m-0 DescriptionLight'>
										{invoiceData.invoice_for.billing_contact.email_to}
									</p>
								)}
								{(invoiceData?.invoice_for?.billing_address?.floor ||
									invoiceData?.invoice_for?.billing_address?.street) && (
									<p className='text-left m-0 DescriptionLight'>
										{`${invoiceData.invoice_for.billing_address.floor || ''}${
											invoiceData.invoice_for.billing_address.street
												? (invoiceData.invoice_for.billing_address.floor
														? ', '
														: '') +
												  invoiceData.invoice_for.billing_address.street
												: ''
										}`}
									</p>
								)}
								{(invoiceData?.invoice_for?.billing_address?.state ||
									invoiceData?.invoice_for?.billing_address?.zip) && (
									<p className='text-left m-0 DescriptionLight'>
										{`${invoiceData.invoice_for.billing_address.state || ''}${
											invoiceData.invoice_for.billing_address.zip
												? (invoiceData.invoice_for.billing_address.state
														? ', '
														: '') +
												  invoiceData.invoice_for.billing_address.zip
												: ''
										}`}
									</p>
								)}

								{invoiceData?.invoice_for?.billing_address?.country && (
									<p className='text-left m-0 DescriptionLight'>
										{invoiceData.invoice_for.billing_address.country}
									</p>
								)}
							</div>
						</div>

						<div>
							<p className='text-left DescriptionLight'>
								<span className='DescriptionOne'>Billing Period:</span>{' '}
								{`${moment
									.utc(invoiceData?.billing_period?.start_date)
									.format(invoiceData?.date_format)} - ${moment
									.utc(invoiceData?.billing_period?.end_date)
									.format(invoiceData?.date_format)}`}
							</p>
						</div>

						<hr />

						<table className='table table-borderless'>
							<thead className='table-light'>
								<tr>
									<th
										className='text-left DescriptionLight'
										style={{ textAlign: 'left', width: '50%' }}
									>
										Description
									</th>
									<th
										className='text-left DescriptionLight'
										style={{ textAlign: 'left', width: '15%' }}
									>
										Quantity
									</th>
									<th
										className='text-left DescriptionLight'
										style={{ textAlign: 'left', width: '20%' }}
									>
										Unit Price
									</th>
									<th
										className='text-left DescriptionLight'
										style={{ textAlign: 'left', width: '15%' }}
									>
										Amount
									</th>
								</tr>
							</thead>
							<tbody>
								{invoiceData?.line_items?.map((item, index) => (
									<tr key={index}>
										<td
											className='text-left'
											style={{ textAlign: 'left', width: '50%' }}
										>
											{item.description}
										</td>
										<td
											className='text-left'
											style={{ textAlign: 'left', width: '15%' }}
										>
											{item.quantity}
										</td>
										<td
											className='text-left'
											style={{ textAlign: 'left', width: '20%' }}
										>
											{invoiceData?.currency_symbol}
											{item.unit_amount}
										</td>
										<td
											className='text-left'
											style={{ textAlign: 'left', width: '15%' }}
										>
											{invoiceData?.currency_symbol}
											{item.total_amount}
										</td>
									</tr>
								))}

								<tr>
									<td colSpan='4'></td>
								</tr>

								<tr>
									<td></td>
									<td></td>

									<td
										className='text-left'
										style={{
											textAlign: 'left',
											width: '20%',
											borderTop: '1px solid #EDEDED',
										}}
									>
										<span>Sub Total</span>
									</td>
									<td
										className='text-left'
										style={{
											textAlign: 'left',
											width: '15%',
											borderTop: '1px solid #EDEDED',
										}}
									>
										<span>
											{invoiceData?.currency_symbol}
											{invoiceData?.total_amount}
										</span>
									</td>
								</tr>
								<tr>
									<td></td>
									<td></td>

									<td
										className='text-left'
										style={{
											textAlign: 'left',
											width: '20%',
											borderTop: '1px solid #EDEDED',
										}}
									>
										<span>Discount</span>
									</td>
									<td
										className='text-left'
										style={{
											textAlign: 'left',
											width: '15%',
											borderTop: '1px solid #EDEDED',
										}}
									>
										<span>
											-{invoiceData?.currency_symbol}
											{invoiceData?.total_discount || 0}
										</span>
									</td>
								</tr>

								<tr>
									<td></td>
									<td></td>

									<td
										className='text-left'
										style={{
											textAlign: 'left',
											width: '20%',
											borderTop: '1px solid #EDEDED',
										}}
									>
										<span>Amount Due</span>
									</td>
									<td
										className='text-left'
										style={{
											textAlign: 'left',
											width: '15%',
											borderTop: '1px solid #EDEDED',
										}}
									>
										<span>
											{invoiceData?.currency_symbol}
											{invoiceData?.amount_due}
										</span>
									</td>
								</tr>
							</tbody>
						</table>

						{invoiceData?.notes && (
							<div className='mt-4 w-100'>
								<h5 className='text-left'>Notes</h5>
								<p
									className='text-left'
									style={{
										maxwidth: '100%',
										maxHeight: '80px',
										overflow: 'auto',
									}}
								>
									{invoiceData?.notes}
								</p>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default PdfPreview;
