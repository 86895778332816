import { useEffect, useState } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import { useDispatch } from 'react-redux';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import InputTaskDetails from '../Inputs/InputTaskDetails';
import ToggleButton from '../Inputs/ToggleButton';
import { numberRegex } from '../../utilities/utilities';

const RequestDocumentModal = ({ inviteLink, closeHandler, prevReminder = {} }) => {
	const dispatch = useDispatch();

	const [reminder, setReminder] = useState(Boolean(prevReminder.reminder));
	const [actionLoading, setActionLoading] = useState({
		update: false,
		inviteReminder: false,
	});
	const [reminderValues, setReminderValues] = useState({
		total_reminders: prevReminder.total_reminders || 1,
		remind_every: prevReminder.remind_every || 1,
	});
	const [form, setForm] = useState({
		email: inviteLink.email || '',
		cc: inviteLink.cc_email?.[0] || '',
		message: '',
	});
	const [error, setError] = useState({
		email: '',
		cc: '',
	});

	const update = () => {
		setActionLoading({
			...actionLoading,
			update: true,
		});
		const payload = {
			reminder: reminder,
			email: form?.email,
			cc_email: form?.cc?.length > 0 ? [form?.cc] : [],
			message: form?.message || '',
		};
		if (reminder) {
			payload.remind_every = reminderValues.remind_every;
			payload.total_reminders = reminderValues.total_reminders;
		}
		dispatch(Actions.updateClientDocumentRequestDetails(payload, inviteLink?.uuid))
			.then(() => {
				dispatch(Actions.setSnackBarIsOpen(true, true, 'Updated and link copied to clipboard'));
				navigator.clipboard.writeText(inviteLink?.link || '');
				setActionLoading({
					...actionLoading,
					update: false,
				});
			})
			.catch((error) => {
				dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
				setActionLoading({
					...actionLoading,
					update: false,
				});
			});
	};

	const reminderChangeHanlder = (event) => {
		const { value, name } = event.target;

		if (!numberRegex.test(value)) return;

		setReminderValues({
			...reminderValues,
			[name]: Number(value),
		});
	};

	const handleEmailChange = (event) => {
		const { name, value } = event.target;
		setForm({
			...form,
			[name]: value,
		});

		if (value == '') {
			setError({
				...error,
				[name]: '',
			});
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			setError({
				...error,
				[name]: 'Please enter a valid email',
			});
		} else {
			setError({
				...error,
				[name]: '',
			});
		}
	};

	const handleMessageChange = (message) => {
		setForm({
			...form,
			['message']: message,
		});
	};

	const isRequestValid = Boolean(
		(form?.email == '' && form?.cc == '') ||
			error?.email !== '' ||
			error.cc !== '' ||
			actionLoading.inviteReminder,
	);

	const onInvite = async () => {
		setActionLoading({
			...actionLoading,
			inviteReminder: true,
		});

		const payload = {
			reminder: reminder,
			email: form?.email,
			cc_email: form?.cc?.length > 0 ? [form?.cc] : [],
			message: form?.message,
		};

		if (reminder) {
			payload.remind_every = reminderValues.remind_every;
			payload.total_reminders = reminderValues.total_reminders;
		}

		const invitePayload = {
			requested_url: `${window.location.origin}/upload-document`,
			uuid: inviteLink?.uuid,
		};
		try {
			await dispatch(Actions.updateClientDocumentRequestDetails(payload, inviteLink?.uuid));
			await dispatch(Actions.sendDocumentUploadInvite(invitePayload));

			setActionLoading({
				...actionLoading,
				inviteReminder: false,
			});
			dispatch(Actions.setSnackBarIsOpen(true, true, 'Email Sent Successfully'));
		} catch (error) {
			dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
			setActionLoading({
				...actionLoading,
				inviteReminder: false,
			});
		}
	};

	useEffect(() => {
		setForm({
			...form,
			['message']: inviteLink?.message || '',
		});
		return () => {
			setForm({
				email: '',
				cc: '',
			});
			setError({
				email: '',
				cc: '',
			});
		};
	}, [inviteLink]);

	return (
		<div className='request-document-modal'>
			<div
				className='content align-items-start'
				style={{
					gap: '24px',
				}}
			>
				<div
					className='HeadlineOneBold dark-text form-title'
					style={{ lineHeight: '1.6', padding: '0px 24px 0px 24px' }}
				>
					Request Files from Clients
				</div>

				<div className='overflow-wrapper '>
					<div className='d-flex flex-column w-100 BodyTwoLight'>
						{`Please enter client's email and an optional message to send an email to
						upload the required document. The client has 30 days to upload the file.`}
					</div>
					<div className='improved-input-designs w-100'>
						<label>To</label>
						<input
							type='email'
							name='email'
							value={form?.email}
							onChange={handleEmailChange}
						/>
						<span className='error-text BodyThreeLight'>{error.email}</span>
					</div>
					<div className='improved-input-designs w-100'>
						<label>Cc</label>
						<input
							type='email'
							name='cc'
							value={form?.cc}
							onChange={handleEmailChange}
						/>
						<span className='error-text BodyThreeLight'>{error.cc}</span>
					</div>
					<div className='w-100 improved-input-designs'>
						<label>Message (Optional)</label>
						<InputTaskDetails
							richText
							type='text'
							name='description'
							onChange={handleMessageChange}
							value={form?.message}
							style={{
								height: '200px',
							}}
						/>
					</div>
					<div className='improved-input-designs w-100'>
						<div className='w-100 d-flex justify-content-between'>
							<label>Reminder</label>

							<ToggleButton
								checked={reminder}
								onChange={() => setReminder(!reminder)}
							/>
						</div>
					</div>

					{reminder && (
						<div className='w-100 d-flex justify-content-between'>
							<div className='improved-input-designs w-50'>
								<label>Remind Every</label>

								<span
									className='d-flex BodyTwoLight align-items-center'
									style={{ gap: '6px' }}
								>
									<input
										name='remind_every'
										value={reminderValues.remind_every}
										onChange={reminderChangeHanlder}
									/>
									days
								</span>
							</div>

							<div className='improved-input-designs w-50'>
								<label>Number of Reminders</label>
								<input
									name='total_reminders'
									value={reminderValues.total_reminders}
									onChange={reminderChangeHanlder}
								/>
							</div>
						</div>
					)}

					<span className='BodyTwoLight'>
						Or you can copy the given link and share it with the client.
					</span>
					<div
						className='d-flex align-items-end w-100 justify-content-between pb-3'
						style={{ gap: '12px' }}
					>
						<div className='improved-input-designs w-100'>
							<label>Link to Request Files</label>
							<input type='text' disabled placeholder={inviteLink?.link} />
						</div>
						<FormButtonOne
							onClick={update}
							className='rounded-button-2'
							loading={actionLoading.update}
							disabled={actionLoading.update}
						>
							Copy Link
						</FormButtonOne>
					</div>
				</div>
			</div>
			<div className='d-flex action-buttons w-100 f-flex'>
				<FormButtonOne
					onClick={closeHandler}
					type='button'
					className={'rounded-button-2 secondary'}
				>
					Close
				</FormButtonOne>

				<div className='d-flex' style={{ gap: '12px' }}>
					<FormButtonOne
						className='rounded-button-2'
						onClick={onInvite}
						type='button'
						disabled={isRequestValid}
						loading={actionLoading.inviteReminder}
					>
						Send Reminder now
					</FormButtonOne>

					<FormButtonOne
						className='rounded-button-2'
						onClick={update}
						type='button'
						loading={actionLoading.update}
					>
						Update
					</FormButtonOne>
				</div>
			</div>
		</div>
	);
};

export default RequestDocumentModal;
