/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';
import * as Actions from '../redux/actions/workflow-builder-actions';
import { useDispatch, useSelector } from 'react-redux';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import useBreadCrumb from '../hooks/useBreadCrumb';
import { routes } from '../utilities/routes';
import { useHistory } from 'react-router';
import Sidebar from '../components/sidebar/SideBar';

import ToggleButton from '../components/Inputs/ToggleButton';
import Filter from '../components/sidebar/Filter';
import { getUniqueObjectArray } from '../utilities/utilities';
import { CircularProgress, Input } from '@mui/material';
import { ReactComponent as FolderIcon } from '../assets/icons/Folder.svg';
import ArchivedWorkflowsList from '../components/WorkflowBuilder/ArchivedWorkflowsList';
import { setSnackBarIsOpen } from '../redux/actions/dashboard/workspaces-actions';

function ArchivedWorkflows() {
	const InputClass = {
		width: '100%',
		fontFamily: 'CentraLight',
		fontWeight: 'light',
		fontSize: '14px !important',
		lineHeight: '18px',
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.25)',
		padding: '8px 6px 2px 8px',
		borderRadius: '4px',
	};

	const dispatch = useDispatch();
	const history = useHistory();
	const { BreadCrumbs } = useBreadCrumb();
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [nameSearch, setNameSearch] = useState('');
	const [groupByCategory, setGroupByCategory] = useState(false);
	const [workflowsChecked, setWorkflowsChecked] = useState([]);
	const [unArchiveLoading, setUnarchiveLoading] = useState(false);
	const companyWorkflows = useSelector((state) => state.WorkflowBuilder.companyWorkflows);
	const profileData = useSelector((state) => state.profileReducer.profileData);

	let archivedWorkflows = [];

	if (companyWorkflows?.length > 0) {
		archivedWorkflows = companyWorkflows
			?.map((category) => {
				const filteredWorkflows = category?.workflows?.filter(
					(workflow) => workflow?.deleted && workflow?.status !== 'DRAFT',
				);
				return filteredWorkflows.length > 0
					? { ...category, workflows: filteredWorkflows }
					: null;
			})
			?.filter((category) => category !== null);
	}

	let categoriesFilter = companyWorkflows?.length > 0 ? [...companyWorkflows] : [];
	if (categoriesFilter?.length > 0) {
		categoriesFilter = categoriesFilter?.map((workflow) => {
			return {
				label: workflow.name,
				value: workflow.id,
				is_checked: selectedCategories?.includes(workflow?.id),
			};
		});
	}

	const toggleCategoryFilter = () => {
		setGroupByCategory(!groupByCategory);
	};

	const handleFilterCategories = (event) => {
		const { value } = event.target;
		if (selectedCategories?.includes(parseInt(value))) {
			setSelectedCategories(
				selectedCategories?.filter((category) => category !== parseInt(value)),
			);
		} else {
			setSelectedCategories([...selectedCategories, parseInt(value)]);
		}
	};

	const changeHandler = (event) => {
		setNameSearch(event.target.value);
	};

	const handleCheckBoxes = (workflow, stateUpdater, maxItems = 10) => {
		stateUpdater((prevChecked) => {
			if (prevChecked.some((item) => item.id === workflow.id)) {
				return prevChecked.filter((item) => item.id !== workflow.id);
			} else {
				if (prevChecked.length >= maxItems) {
					showMaxAlert();
					return prevChecked;
				}
				if (prevChecked.length >= 9) {
					showMaxAlert();
				}
				return [...prevChecked, workflow];
			}
		});
	};

	const showMaxAlert = () => {
		dispatch(
			setSnackBarIsOpen(
				false,
				true,
				'You can unarchive up to 10 workflows at a time. Unarchive the selected workflows now and unarchive more workflows later.',
			),
		);
	};

	const unArchiveSingleWorkflow = (workflowId) => {
		setUnarchiveLoading(true);
		dispatch(Actions.unArchiveWorkflow(workflowId))
			.then(() => {
				setUnarchiveLoading(false);
				dispatch(Actions.getCompanyWorkflows());
			})
			.catch(() => {
				dispatch(setSnackBarIsOpen(false, true, 'Workflow is not unarchived'));
				setUnarchiveLoading(false);
			});
	};

	const unArchiveMultipleWorkflows = () => {
		setUnarchiveLoading(true);
		const unArchivedPromise = [];
		workflowsChecked?.map((workflow) => {
			const data = dispatch(Actions.unArchiveWorkflow(workflow?.id));
			unArchivedPromise.push(data);
		});
		Promise.all(unArchivedPromise)
			.then(() => {
				setUnarchiveLoading(false);
				dispatch(
					setSnackBarIsOpen(
						true,
						true,
						workflowsChecked?.length == 1
							? 'Workflow is successfully unarchived'
							: 'All workflows are successfully unarchived',
					),
				);
				dispatch(Actions.getCompanyWorkflows());
				setWorkflowsChecked([]);
			})
			.catch(() => {
				setUnarchiveLoading(false);
				dispatch(
					setSnackBarIsOpen(
						false,
						true,
						workflowsChecked?.length == 1
							? 'Workflow is not unarchived'
							: 'Some of the workflows are not unarchived',
					),
				);
			});
	};

	useEffect(() => {
		companyWorkflows?.length < 1 && dispatch(Actions.getCompanyWorkflows());
	}, [dispatch]);

	return (
		<div className='AppBody'>
			<Sidebar title='Workflow Library'>
				<div className='new-sidebar-content-wrapper'>
					<div className='User-filters'>
						<div className='new-side-menu BodyTwoLight-bold m-0 d-flex flex-column align-items-start'>
							<div
								className='main-sidebar-item w-100 d-flex justify-content-center'
								style={{ padding: '12px 12px' }}
							>
								<Input
									sx={InputClass}
									disableUnderline={true}
									size='small'
									placeholder='Search Workflow'
									name='first'
									value={nameSearch}
									onChange={changeHandler}
								/>
							</div>
							<div className=' main-sidebar-item justify-content-center w-100'>
								<div
									className='main-sidebar-item SidebarListWrapper d-flex'
									style={{ padding: '16px', gap: '10px' }}
								>
									<h2 className='HeadlineThreeBold text-left text-white'>
										Filters
									</h2>
									<div className='w-100 d-flex align-items-center justify-content-between'>
										<label className='BodyTwoLight white-text text-left m-0'>
											Group by Category
										</label>
										<ToggleButton
											checked={groupByCategory}
											onChange={toggleCategoryFilter}
										/>
									</div>
								</div>
							</div>
							<div className='main-sidebar-item justify-content-center w-100'>
								<div
									className='main-sidebar-item SidebarListWrapper'
									style={{ padding: '16px' }}
								>
									<Filter
										title='Categories'
										options={getUniqueObjectArray(categoriesFilter)}
										onChange={(e) => handleFilterCategories(e)}
										onClear={() => setSelectedCategories([])}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Sidebar>
			<div className='content-container content-overflow'>
				<div
					className='workflow-browser w-100 d-flex flex-column'
					style={{
						paddingTop: '35px',
						backgroundColor: '#EEF2F3',
						padding: '24px 32px',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							cursor: 'pointer',
							color: '#FB7A03',
							marginBottom: '8px',
						}}
					>
						<ArrowLeftIcon />
						<BreadCrumbs>
							<h2
								onClick={() => history.push(routes['Workflow-Builder'])}
								className='HeadlineThreeBook text-left'
							>
								Company Library
							</h2>
						</BreadCrumbs>
					</div>
					<div className='workflow-browser-header'>
						<div className='d-flex align-items-center' style={{ gap: '12px' }}>
							<FolderIcon width={32} height={32} />
							<span className='HeadlineTwoBold pl-2 mt-1'>Archived Workflows</span>
						</div>
					</div>
					<div className='workflow-library-switcher'>
						<div>
							<span className='BodyOneLight'>
								<div className='d-flex align-items-center' style={{ gap: '5rem' }}>
									<span className='d-flex align-items-center'>
										<span
											className='d-flex align-items-center'
											style={{ gap: '16px' }}
											onClick={() => setWorkflowsChecked([])}
										>
											<span
												className={`BodyOneLight pt-1 primary-text pointer ${
													workflowsChecked.length == 0 ? 'opacity-50' : ''
												} `}
											>
												Clear All
											</span>
										</span>
										<span className='pt-1' style={{ paddingLeft: '16px' }}>
											{`${workflowsChecked.length}/10`}{' '}
											{workflowsChecked.length > 1 ? 'workflows' : 'workflow'}{' '}
											selected{' '}
										</span>
									</span>
									<button
										className='rounded-button d-flex align-items- justify-content-center'
										onClick={unArchiveMultipleWorkflows}
										disabled={workflowsChecked.length < 1 || unArchiveLoading}
									>
										<p className='BodyTwoBold m-0 p-0 pt-1'>Unarchive</p>
									</button>
								</div>
							</span>
						</div>
					</div>
					<div>
						<ArchivedWorkflowsList
							archivedWorkflows={archivedWorkflows
								?.filter(
									(workflow) =>
										selectedCategories?.length === 0 ||
										selectedCategories?.includes(workflow?.id),
								)
								?.map((category) => {
									const filteredWorkflows = category?.workflows?.filter(
										(workflow) =>
											workflow?.name
												?.toLowerCase()
												.includes(nameSearch?.toLowerCase()),
									);
									return filteredWorkflows?.length > 0
										? {
												...category,
												workflows: filteredWorkflows,
										  }
										: null;
								})}
							profileData={profileData}
							handleCheckBoxes={(workflowId) =>
								handleCheckBoxes(workflowId, setWorkflowsChecked)
							}
							isChecked={workflowsChecked}
							groupByCategory={groupByCategory}
							unArchiveSingleWorkflow={unArchiveSingleWorkflow}
						/>
					</div>
				</div>
				{unArchiveLoading && (
					<div
						style={{
							height: '100%',
							width: 'inherit',
							backgroundColor: 'rgba(0, 0, 0, 0.05)',
							position: 'fixed',
							top: 0,
							bottom: 0,
						}}
					>
						<CircularProgress
							style={{
								color: '#fa7b03',
								height: '50px',
								width: '50px',
								marginTop: '47vh',
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

export default ArchivedWorkflows;
