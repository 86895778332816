import * as Actions from '../../actions/integrations/accounting-actions';

const initialState = {
	integration: null,
	customers: [],
};

const AccountingReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case Actions.GET_INTEGRATION:
			return (state = {
				...state,
				integration: payload,
			});
		case Actions.GET_CUSTOMERS:
			return (state = {
				...state,
				customers: payload?.data,
			});
		case Actions.UPDATE_INTEGRATION:
			return {
				...state,
				integration: {
					...state.integration,
					data: state.integration.data.map((app) =>
						app.connections[0]?.id === payload ? { ...app, connections: [] } : app,
					),
				},
			};
		default:
			return state;
	}
};

export default AccountingReducer;
