import React, { useEffect, useState } from 'react';
import { Input, Modal } from '@mui/material';
import ButtonSquare from '../components/Buttons/ButtonSquare';
import Sidebar from '../components/sidebar/SideBar';
import Card from '../components/Others/Card';
import TableHeader from '../components/Others/TableHeader';
import TableHeaderItem from '../components/Others/TableHeaderItem';
import TableRowItem from '../components/Others/TableRowItem';
import ActionButton from '../components/Buttons/ActionButton';
import useModal from '../components/Modal/useModal';
import TableRowButton from '../components/Others/TableRowButton';
import DummyModal from '../components/Modal/DummyModal';
import IndustryPicker from '../components/sidebar/IndustryPicker';
import BusinessPicker from '../components/sidebar/BusinessPicker';
import State from '../components/sidebar/State';
import AddClient from '../components/ClientManagement/AddClient';
import * as clientActions from '../redux/actions/client-actions';
import * as builderActions from '../redux/actions/workflow-builder-actions.js';

import { TableItemWidth3, TableItemWidth4 } from '../components/TableSpacing/TableSpacingStyles';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverLay from '../components/Others/LoadingOverLay';
import { states, industries2, business_types2 as business_types } from '../components/DemoData';
import Restricted from '../components/Permissions/Restricted';

import { useHistory, useLocation } from 'react-router-dom';
import { Member, standardPermissions } from '../utilities/utilities';
import WorkflowModal from '../components/ClientManagement/Modals/WorkflowModal';
import TeamPickList from '../components/sidebar/TeamPickList';
import * as teamActions from '../redux/actions/team-management.actions';
import ArchiveModal from '../components/Modal/ArchiveModal';
import SectionHeader from '../components/sidebar/SectionHeader';
import { routes } from '../utilities/routes';
import AddTeam from '../components/TeamManagement/AddTeam';
import AlertBar from '../components/sub_components/AlertBar.jsx';

function ClientManagement() {
	const history = useHistory();
	const location = useLocation();

	const dispatch = useDispatch();

	const InitialForm = {
		company_name: '',
		dba: '',
		team_client_permissions: {
			team_id: 0,
		},
	};

	const InitialTeamForm = {
		name: '',
		description: '',
	};
	const [FormFields, setFormFields] = useState(InitialForm);
	const [search, setSearch] = useState('');
	// const [isCheckAll, setIsCheckAll] = useState(false);
	const [SelectedID, setSelectedID] = useState();
	const [modalType, setmodalType] = useState('');
	// const [multiDeleteIds, setMultiDeleteIds] = useState([]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [isWorkflowModal, setIsWorkflowModal] = useState(false);
	const [arhiveClientModal, setArchiveClientModal] = useState(false);
	const [clientDetails, setClientDetails] = useState();
	const [teamModal, setTeamModal] = useState(false);

	const open = Boolean(anchorEl);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const clients = useSelector((state) => state.clientReducer.clients);
	// const client = useSelector((state) => state.clientReducer.client);
	const user = useSelector((state) => state.authReducer.user);

	const loading = useSelector((state) => state.clientReducer.loading);
	const filters = useSelector((state) => state.clientReducer.filters);
	let teams = useSelector((state) => state.teamReducer.teams);
	const activeClientWorkflows = useSelector(
		(state) => state.WorkflowBuilder.activeClientWorkflows,
	);

	const activeWorkflowNames = activeClientWorkflows?.map((workflow) => workflow.workflow.name);

	if (teams?.length > 0) {
		teams = teams.map((team) => {
			return {
				value: team.id,
				label: team.name,
				is_checked: filters?.teams?.includes(team.id),
			};
		});
	}

	const filteredState = useSelector((state) => state.clientReducer.filteredClientManagement?.state);
	let filteredIndustry = useSelector(
		(state) => state.clientReducer.filteredClientManagement?.industry,
	);
	let filteredBusinessType = useSelector(
		(state) => state.clientReducer.filteredClientManagement?.business_type,
	);

	const stateOptionsArr = filteredState?.map((item) => ({
		label: item?.state,
		value: item?.state,
	}));

	// const businessOptionsArr = filteredBusinessType?.map((item) => ({
	// 	label: item?.business_type,
	// 	value: item?.business_type,
	// }));

	console.log(filteredBusinessType, 'filteredState');

	// if (filteredState) {
	// 	filteredState = states
	// 		.filter((obj) => filteredState.find((item) => item.state == obj.value))
	// 		?.map((obj) => {
	// 			return {
	// 				...obj,
	// 				is_checked: filters?.states?.includes(obj.value),
	// 			};
	// 		});
	// }

	if (filteredIndustry) {
		filteredIndustry = industries2
			.filter((obj) => filteredIndustry.find((item) => item.industry == obj.value))
			?.map((obj) => {
				return {
					...obj,
					is_checked: filters?.industry?.includes(obj.value),
				};
			});
	}

	if (filteredBusinessType) {
		filteredBusinessType = business_types
			.filter((obj) => filteredBusinessType.find((item) => item.business_type == obj.value))
			?.map((obj) => {
				return {
					...obj,
					is_checked: filters?.business?.includes(obj.value),
				};
			});
	}

	const InputClass = {
		width: '100%',
		fontFamily: 'CentraLight',
		fontWeight: 'light',
		fontSize: '14px !important',
		lineHeight: '18px',
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.25)',
		padding: '8px 6px 2px 8px',
		borderRadius: '3px',
		marginTop: '8px',
	};

	//   Name State
	const resetFirst = () => {
		setSearch('');
	};

	const changeHandler = (event) => {
		setSearch(event.target.value);
	};

	const handleTeamCheckbox = (e) => {
		dispatch(clientActions.handleTeamCheckbox(parseInt(e.target.value)));
	};

	const handleIndustryCheckbox = (e) => {
		dispatch(clientActions.handleIndustryCheckbox(e.target.value));
	};

	const handleBussinessCheckbox = (e) => {
		dispatch(clientActions.handleBussinessCheckbox(e.target.value));
	};

	const handleStateCheckbox = (e) => {
		dispatch(clientActions.handleStateCheckbox(e.target.value));
	};

	const handleArchiveModal = (client) => {
		if (client?.id) {
			dispatch(builderActions.getActiveClientWorkflows(client?.id));
		}
		setArchiveClientModal(!arhiveClientModal);
		setClientDetails(client);
	};

	const OnDateChange = (date) => {
		date &&
			setFormFields({
				...FormFields,
				date: date?.format(),
			});
	};

	const addNewTeam = () => {
		setTeamModal(true);
		toggleModal();
	};

	const FormChange = (e) => {
		if (e.target.value === 'callback') {
			addNewTeam();
		}
		if (e.target.name === 'ein') {
			delete FormFields.ssn;
			var einVal = e.target.value.replace(/\D/g, '');
			var newEinVal = '';

			if (einVal.length > 3) {
				e.target.value = einVal;
			}
			if (einVal.length > 2) {
				newEinVal += einVal.substr(0, 2) + '-';
				einVal = einVal.substr(2);
			}
			if (e.target.value.length > 9) {
				return;
			}

			newEinVal += einVal;
			e.target.value = newEinVal;
		} else if (e.target.name === 'ssn') {
			delete FormFields.ein;
			var val = e.target.value.replace(/\D/g, '');
			var newVal = '';

			if (val.length > 4) {
				e.target.value = val;
			}
			if (val.length > 3 && val.length < 6) {
				newVal += val.substr(0, 3) + '-';
				val = val.substr(3);
			}
			if (val.length > 5) {
				newVal += val.substr(0, 3) + '-';
				newVal += val.substr(3, 2) + '-';
				val = val.substr(5);
			}
			if (e.target.value.length > 9) {
				return;
			}
			newVal += val;
			e.target.value = newVal;
		}

		if (modalType === 'add') {
			if (e.target.name === 'team_id') {
				setFormFields({
					...FormFields,
					team_client_permissions: {
						[e.target.name]: parseInt(e.target.value),
					},
				});
			} else {
				setFormFields({
					...FormFields,
					[e.target.name]: e.target.value,
				});
			}
		} else {
			if (e.target.name === 'team_id') {
				FormFields.team_client_permissions[0].team_id = parseInt(e.target.value);
				delete FormFields.team_client_permissions[0].team;
				setFormFields({
					...FormFields,
				});
			} else {
				setFormFields({
					...FormFields,
					[e.target.name]: e.target.value,
				});
			}
		}
	};

	const AddUser = () => {
		dispatch(clientActions.setError(''));
		setFormFields(InitialForm);
		ModalType('Create Client');
		setmodalType('add');
		toggleModal();
	};
	// const EditUser = (client, id) => {
	//   dispatch(clientActions.setError(''));
	//   setFormFields(client);
	//   // setFormFields(client);
	//   handleClose();
	//   ModalType('Edit Client');
	//   setmodalType('edit');
	//   setSelectedID(id);
	//   toggleModal();
	// };

	//   Action Button

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const onSubmit = (type, id, form, setLoading) => {
		if (type === 'add') {
			dispatch(clientActions.addClient(form, toggleModal, setLoading, history, location));
		} else {
			dispatch(clientActions.updateClient(form, toggleModal, setLoading));
		}
	};

	const OnTeamSubmit = (type, id, inputs) => {
		dispatch(
			teamActions.addTeam(inputs, () => {
				setTeamModal(false);
				toggleModal();
			}),
		);
	};

	const onDelete = () => {
		dispatch(clientActions.deActivateWorklowsList(history, clientDetails?.id));
		setArchiveClientModal(false);

		// dispatch(clientActions.deleteClient(id));
	};

	// const handleMultiDeleteIds = () => {
	//   setIsCheckAll(!isCheckAll);
	//   setMultiDeleteIds(clients.map((li) => li.id));
	//   if (isCheckAll) {
	//     setMultiDeleteIds([]);
	//   }
	// };

	// const handleMultiDeleteId = (e) => {
	//   const { id, checked } = e.target;
	//   setMultiDeleteIds([...multiDeleteIds, parseInt(id)]);
	//   if (!checked) {
	//     setMultiDeleteIds(multiDeleteIds.filter((item) => item !== parseInt(id)));
	//   }
	// };

	// const multipleDelete = () => {
	//   dispatch(clientActions.deleteMultiple(multiDeleteIds));

	//   setMultiDeleteIds([]);
	//   setIsCheckAll(false);
	// };

	useEffect(() => {
		dispatch(clientActions.getClients(null, filters));
		dispatch(teamActions.getTeams(null));
	}, [dispatch, filters]);

	useEffect(() => {
		dispatch(clientActions.getFilteredClientManagement());
	}, [dispatch, clients]);

	const teamProfileNavigation = (teamId) => {
		const from = location.state || [];
		from.push({
			link: location.pathname,
			label: 'Clients',
		});
		if (from.length > 2) from.shift();
		user?.user_roles?.map((item) => item.role_id)?.includes(Member.externalCollaborator)
			? null
			: history.push(`${routes['team-profile']}${teamId}`, from);
	};

	return (
		<div className='AppBody'>
			<Sidebar title='Client Management'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						{/* <SearchBar
              type="filterSearchBar"
              style={{
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
              }}
            /> */}

						{/* {multiDeleteIds.length > 0 && (
              <DeleteSelected>
                <h5 onClick={multipleDelete} className='BodyThreeB'>
                  Delete Selected Clients
                </h5>
              </DeleteSelected>
            )} */}

						<div>
							<SectionHeader
								title='Search Client'
								label='Clear'
								onClear={resetFirst}
							/>
							<Input
								sx={InputClass}
								disableUnderline={true}
								size='small'
								placeholder='Enter Client Name'
								name='first'
								value={search}
								onChange={changeHandler}
							/>
						</div>

						<div>
							<div className='.SidebarListWrapper'>
								<TeamPickList
									options={teams}
									onChange={handleTeamCheckbox}
									onClear={() =>
										dispatch(clientActions.handleClearTeamCheckbox())
									}
									onClick={(teamId) => teamProfileNavigation(teamId)}
								/>
							</div>
						</div>

						<Restricted
							AllowedUsers={[...standardPermissions, Member.teamMemberId]}
							userTeamRole={user?.user_roles?.map((item) => item.role_id)}
						>
							<div>
								<div className='SidebarListWrapper'>
									<IndustryPicker
										onClear={() =>
											dispatch(clientActions.handleClearIndustryCheckbox())
										}
										options={filteredIndustry}
										onChange={handleIndustryCheckbox}
									/>
								</div>
							</div>
						</Restricted>

						<Restricted
							AllowedUsers={[...standardPermissions, Member.teamMemberId]}
							userTeamRole={user?.user_roles?.map((item) => item.role_id)}
						>
							<div>
								<div className='SidebarListWrapper'>
									<BusinessPicker
										onClear={() =>
											dispatch(clientActions.handleClearBusinessCheckbox())
										}
										options={filteredBusinessType}
										onChange={handleBussinessCheckbox}
									/>
								</div>
							</div>
						</Restricted>

						<Restricted
							AllowedUsers={[...standardPermissions, Member.teamMemberId]}
							userTeamRole={user?.user_roles?.map((item) => item.role_id)}
						>
							<div>
								<div className='SidebarListWrapper'>
									<State
										onClear={() =>
											dispatch(clientActions.handleClearStateCheckbox())
										}
										options={stateOptionsArr}
										onChange={handleStateCheckbox}
									/>
								</div>
							</div>
						</Restricted>
					</div>

					<Restricted
						AllowedUsers={standardPermissions}
						userTeamRole={user?.user_roles?.map((item) => item.role_id)}
					>
						<button
							data-cy='add-new-client'
							className='full-width rounded-button-1'
							onClick={AddUser}
						>
							Add New Client
						</button>
					</Restricted>
				</div>
			</Sidebar>
            <div className='content-container'>
            <AlertBar/>
			<div className='Listing-Main w-100' style={{ backgroundColor: '#EEF2F3' }}>
				<div className='Listing-Content'>
					<Card title='Clients'>
						<div className='card-body'>
							<TableHeader ClassName='TableHeader flex fixedHeader' border={false}>
								<TableHeaderItem
									ClassName='UManagement'
									label='Client'
									width={TableItemWidth4}
									margin='ml-5'
								>
									{/* <Restricted AllowedUsers={executivePermissions}>
                  <input
                    onChange={handleMultiDeleteIds}
                    checked={isCheckAll}
                    type='checkbox'
                    className='multi-select-checkbox'
                  />
                </Restricted> */}
								</TableHeaderItem>

								<TableHeaderItem
									ClassName='UManagement'
									label='Industry'
									width={TableItemWidth4}
								/>

								<TableHeaderItem
									ClassName='UManagement'
									label='Business Type'
									width={TableItemWidth3}
								/>

								<TableHeaderItem
									ClassName={'UManagement'}
									label='State/Province/Region'
									width={TableItemWidth3}
								/>
								<TableHeaderItem
									ClassName='UManagement text-center'
									label='Action'
									width={TableItemWidth3}
								/>
							</TableHeader>

							<div
								data-cy='all-clients-list'
								className='ListingLoading listing-scroll'
							>
								{loading ? (
									<LoadingOverLay active={loading} />
								) : clients.length < 1 ? (
									<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
										<h4 className='BodyTwoLight'>No Clients</h4>
									</div>
								) : (
									clients
										?.map((item) => {
											return {
												...item,
												displayName:
													item?.dba?.trim() == ''
														? item?.company_name
														: item?.dba,
											};
										})
										?.filter(
											(client) =>
												client?.displayName
													?.toLowerCase()
													.includes(search?.toLowerCase()) ||
												industries2
													?.find((i) => i.value == client.industry)
													?.value?.toLowerCase()
													.includes(search?.toLowerCase()) ||
												business_types
													?.find((i) => i.value == client.business_type)
													?.label?.toLowerCase()
													.includes(search?.toLowerCase()) ||
												states
													?.find((state) => state.value == client.state)
													?.value?.toLowerCase()
													.includes(search?.toLowerCase()),
										)
										?.sort((a, b) => a.displayName.localeCompare(b.displayName))
										?.map((client, i) => {
											return (
												<div
													className='TableRow d-flex align-items-center'
													key={i}
												>
													<TableRowButton
														ClassName='UManagement'
														label={client?.displayName}
														width={TableItemWidth4}
														margin='ml-5'
														clickHandler={() => {
															dispatch(
																clientActions.resetClientState(),
															);

															history.push(
																`client-profile/${client?.id}`,
															);
														}}
													>
														{/* <Restricted AllowedUsers={executivePermissions}>
                            <input
                              checked={multiDeleteIds.includes(client.id)}
                              id={client.id}
                              type='checkbox'
                              className='multi-select-checkbox'
                              onChange={handleMultiDeleteId}
                            />
                          </Restricted> */}
													</TableRowButton>

													<TableRowItem
														label={
															industries2?.find(
																(i) => i.value == client?.industry,
															)?.value
														}
														width={TableItemWidth4}
														ClassName='UManagement text-left'
													/>

													<TableRowItem
														label={
															business_types?.find(
																(i) =>
																	i.value == client?.business_type,
															)?.label
														}
														width={TableItemWidth3}
														ClassName='UManagement text-left'
													/>

													<TableRowItem
														label={client?.state}
														width={TableItemWidth3}
														ClassName='UManagement text-left'
													/>
													<TableRowButton
														ClassName='UManagement'
														text='text-left'
														width={TableItemWidth3}
													>
														<Restricted
															AllowedUsers={standardPermissions}
															userTeamRole={[
																user?.user_roles?.find((item) =>
																	item?.role_scopes
																		?.map(
																			(role) => role.team_id,
																		)
																		?.includes(
																			client
																				?.team_client_permissions[0]
																				?.team_id,
																		),
																)?.role_id,
															]}
															RestrictedComponent={
																<ActionButton
																	disable={true}
																	ClassName='opacity-50'
																	title={'Not Authorized'}
																/>
															}
														>
															<ActionButton
																isOpen={open}
																clickHandler={handleClick}
																AnchorEl={anchorEl}
																closeHandler={handleClose}
																HandleRemove={() =>
																	handleArchiveModal(client)
																}
																EditUser={() => {
																	dispatch(
																		clientActions.resetClientState(),
																	);

																	history.push(
																		`client-profile/${client.id}/?edit=true`,
																	);
																}}
															/>
														</Restricted>
													</TableRowButton>
												</div>
											);
										})
								)}
							</div>
						</div>
					</Card>
				</div>
			</div>
            </div>

			{isWorkflowModal && (
				<Modal
					onClose={() => setIsWorkflowModal(false)}
					open={isWorkflowModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<WorkflowModal onClose={() => setIsWorkflowModal(false)} />
				</Modal>
			)}
			{teamModal && (
				<Modal
					onClose={() => setTeamModal(false)}
					open={teamModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={'Create Team'} onClose={() => setTeamModal(false)}>
						<AddTeam
							onCancel={() => setTeamModal(false)}
							value={InitialTeamForm}
							submit={OnTeamSubmit}
							response={'Success'}
						/>
					</DummyModal>
				</Modal>
			)}
			{arhiveClientModal && (
				<Modal
					onClose={() => setArchiveClientModal(false)}
					open={arhiveClientModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title='Archive' onClose={handleArchiveModal} warningIcon>
						<ArchiveModal
							client={clientDetails?.displayName}
							onCancel={handleArchiveModal}
							list={activeWorkflowNames}
							onArchive={onDelete}
							loading={loading}
							title='Currently Active Workflows for Venturenox'
						/>
					</DummyModal>
				</Modal>
			)}
			{modalIsOpen ? (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={ModalName} onClose={toggleModal}>
						<AddClient
							onCancel={toggleModal}
							value={FormFields}
							submit={onSubmit}
							type={modalType}
							selectedID={SelectedID}
							OnChange={FormChange}
							OnDateChange={OnDateChange}
						/>
					</DummyModal>
				</Modal>
			) : (
				<></>
			)}
		</div>
	);
}

export default ClientManagement;
