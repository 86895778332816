import { useRef, useState } from 'react';
import { useProductFruitsApi } from 'react-product-fruits';
import { ReactComponent as WhatsNew } from '../../assets/icons/Megaphone.svg';

const NewsFeedLauncher = () => {
	const launcherRef = useRef(null);
	const [unreadCount, setUnreadCount] = useState(0);

	useProductFruitsApi((productFruitsApi) => {
		if (productFruitsApi && launcherRef.current) {
			// Event listener setup
			const handleUnreadCountChange = (data) => {
				setUnreadCount(data.count);
			};

			// Attach the event listener
			productFruitsApi.announcementsV2.listen(
				'newsfeed-unread-count-changed',
				handleUnreadCountChange,
			);

			// Attach the widget to the launcher element
			productFruitsApi.announcementsV2.attachNewsWidgetToElement(launcherRef.current);

			// Cleanup listener on component unmount
			return () => {
				productFruitsApi.announcementsV2.listen(
					'newsfeed-unread-count-changed',
					handleUnreadCountChange,
					true,
				);
			};
		}
	}, []);

	return (
		<div className='NewsFeedLauncher'>
			<div className='container' ref={launcherRef}>
				<span>
					<WhatsNew />
				</span>
				<span className='BodyTwoBold pt-1'>{`What's New?`}</span>
			</div>

			{unreadCount > 0 && <span className='badge'>{unreadCount}</span>}
		</div>
	);
};

export default NewsFeedLauncher;
