import { FileIcon, defaultStyles } from 'react-file-icon';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more_horiz-24px.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/EyeColor.svg';
import { formatBytes } from '../../../utilities/utilities';
import { memo, useEffect, useRef, useState } from 'react';
import useModal from '../../Modal/useModal';
import DeleteModal from '../../Modal/DeleteModal';
import RenameFolderModal from '../../Modal/RenameFodlerModal';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import PreviewModal from '../../Modal/PreviewModal.jsx';
import * as clientActions from '../../../redux/actions/client-actions';

function DocumentFile({
	depth,
	onDragEnd,
	onDragEnter,
	onSelectFolder,
	isSelected,
	onDragLeave,
	item,
	headers,
}) {
	const dropdownRef = useRef();
	const dispatch = useDispatch();
	const [isBoxOpen, setIsBoxOpen] = useState(false);
	const [preSignedUrl, setPreSignedUrl] = useState();
	const users = useSelector((state) => state.userReducer.users);

	const uploadedBy = users?.find((user) => user.user_id === item.created_by)?.user_name || '-';
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const handleMoreIcon = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setIsBoxOpen(!isBoxOpen);
	};

	const handleModalOpen = (type) => () => {
		ModalType(type);
		toggleModal();
	};

	const handleDownload = () => {
		const fileUrl = item.url;
		const fileName = item.name;

		const link = document.createElement('a');
		link.href = fileUrl;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);

		link.click();
		link.remove();
	};

	const onPreview = (e) => {
		e.stopPropagation();
		e.preventDefault();
		dispatch(clientActions.getPresignedUrl(item.id))
			.then((res) => {
				setPreSignedUrl(res?.pre_signed_url);
			})
			.catch((e) => {
				console.log(e, 'error');
			});
		ModalType('PREVIEW_MODAL');
		toggleModal();
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsBoxOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div
			style={{
				paddingLeft: `${20 * depth + 10}px`,
				backgroundColor: isSelected ? '#FED0A5' : '#F7F9FA',
			}}
			className='file-container'
			draggable
			onDragEnd={onDragEnd}
			onDragEnter={onDragEnter}
			onClick={onSelectFolder}
			id={item.id}
			onDragLeave={onDragLeave}
		>
			<div className='d-flex align-items-center'>
				<div style={{ width: '14px', paddingRight: '4px' }}>
					<FileIcon
						extension={item.type}
						{...defaultStyles[`${item.type}`]}
						size='14px'
					/>
				</div>
				<div className='truncated'>
					<Tooltip title={item?.name}>
						<span className='BodyTwoLight'>{item.name}</span>
					</Tooltip>
				</div>
				{['application/pdf', 'image/svg+xml', 'text/plain'].includes(item?.mime_type) ||
				item?.mime_type?.startsWith('image/') ? (
					<div className='pl-1' onClick={onPreview}>
						<EyeIcon />
					</div>
				) : null}
			</div>
			<div className='d-flex align-items-center justify-content-evenly'>
				{headers?.some((header) => header.key === 'lastModified') && (
					<div
						style={{
							width: headers.find((header) => header.key === 'lastModified')?.width,
						}}
						className='d-flex align-items-center'
					>
						<span className='BodyTwoLight'>
							{new Date(item.updated_at).toLocaleDateString('en-US')}
						</span>
					</div>
				)}
				{headers?.some((header) => header.key === 'fileSize') && (
					<div
						style={{
							width: headers.find((header) => header.key === 'fileSize')?.width,
						}}
						className='d-flex align-items-center'
					>
						<span className='BodyTwoLight'>{formatBytes(item.size)}</span>
					</div>
				)}

				{headers?.some((header) => header.key === 'uploadedBy') && (
					<div
						style={{
							width: headers.find((header) => header.key === 'uploadedBy')?.width,
						}}
						className='d-flex align-items-center'
					>
						<Tooltip title={uploadedBy}>
							<span className='BodyTwoLight'>
								{uploadedBy?.length > 12 ? uploadedBy.substring(0, 12) : uploadedBy}
							</span>
						</Tooltip>
					</div>
				)}
				{headers?.some((header) => header.key === 'action') && (
					<div
						style={{
							width: headers.find((header) => header.key === 'action')?.width,
						}}
						className='d-flex align-items-center'
					>
						<div
							className='pointer'
							onClick={handleMoreIcon}
							style={{ position: 'relative' }}
						>
							<MoreIcon />
							{isBoxOpen && (
								<>
									<div
										className='dropdown-box p-0'
										ref={dropdownRef}
										style={{ width: '100px' }}
									>
										<div
											style={{
												width: '100px',
												padding: '0px 10px',
											}}
											onClick={handleDownload}
										>
											<span
												style={{
													width: '107px',
													display: 'flex',
													flexDirection: 'start',
													textAlign: 'start',
													cursor: 'pointer',
												}}
												className='BodyTwoLight'
											>
												Download
											</span>
										</div>
										{item?.mime_type == 'application/pdf' ||
										item?.mime_type == 'image/svg+xml' ||
										item?.mime_type?.startsWith('image/') ||
										item?.mime_type == 'text/plain' ? (
											<div
												style={{
													width: '100px',
													padding: '0px 10px',
												}}
												onClick={onPreview}
											>
												<span
													style={{
														width: '107px',
														display: 'flex',
														flexDirection: 'start',
														textAlign: 'start',
														cursor: 'pointer',
													}}
													className='BodyTwoLight'
												>
													Preview
												</span>
											</div>
										) : (
											<></>
										)}
										<div
											style={{
												width: '100px',
												padding: '0px 10px',
											}}
											onClick={handleModalOpen('RENAME')}
										>
											<span
												style={{
													width: '107px',
													display: 'flex',
													flexDirection: 'start',
													textAlign: 'start',
													cursor: 'pointer',
												}}
												className='BodyTwoLight'
											>
												Rename
											</span>
										</div>
										<div
											style={{
												width: '100px',
												padding: '0px 10px',
											}}
											onClick={handleModalOpen('DELETE_FOLDER')}
										>
											<span
												style={{
													width: '107px',
													display: 'flex',
													flexDirection: 'start',
													textAlign: 'start',
													cursor: 'pointer',
												}}
												className='BodyTwoLight'
											>
												Delete
											</span>
										</div>
									</div>
								</>
							)}
							{ModalName === 'RENAME' && (
								<RenameFolderModal
									modalIsOpen={modalIsOpen}
									toggleModal={toggleModal}
									ModalName={ModalName}
									ModalType={ModalType}
									item={item}
									isFolder={false}
								/>
							)}

							{ModalName === 'DELETE_FOLDER' && (
								<DeleteModal
									modalIsOpen={modalIsOpen}
									toggleModal={toggleModal}
									ModalName={ModalName}
									isFileTrue
									item={item}
								/>
							)}
							{ModalName === 'PREVIEW_MODAL' && (
								<PreviewModal
									modalIsOpen={modalIsOpen}
									toggleModal={toggleModal}
									ModalName={ModalName}
									item={item}
									url={preSignedUrl}
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default memo(DocumentFile);
