import { useHistory, useLocation } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../redux/actions/auth-actions';
import * as profileActions from '../../redux/actions/company-profile';
import moment from 'moment-timezone';
import {
	validateEmailError,
	requiredError,
	passwordCheckError,
	matchPasswordError,
	capitalize,
} from '../../utilities/utilities';
import { Modal } from '@mui/material';
import Select from '../Inputs/Select';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Country } from 'country-state-city';
import TermsAndCondistionsModal from '../Modal/TermsAndCondistionsModal';
import PasswordInputIcon from '../Others/PasswordInputIcon';
import useTimeZone from '../../hooks/useTimeZone';

const SignUpForm = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const search = useLocation().search;

	const modalRef = useRef();

	const zapierClientId = new URLSearchParams(search).get('client_id');
	const zapierRedirectUri = new URLSearchParams(search).get('redirect_uri');
	const zapierState = new URLSearchParams(search).get('state');

	const form = useSelector((state) => state.authReducer.signUpForm);
	const errors = useSelector((state) => state.authReducer.signUpFormErrors);

	const loading = useSelector((state) => state.authReducer.signUpFormLoading);
	const countries = useSelector((state) => state.profileReducer.countries);

	const [isPrimaryPasswordShow, setIsPrimaryPasswordShow] = useState(false);
	const [isSecondaryPasswordShow, setIsSecondaryPasswordShow] = useState(false);
	const [areTermsAndConditionsAgreed, setAreTermsAndConditionsAgreed] = useState(false);
	const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] = useState(false);

	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const getCountryAbbr = moment.tz.zone(userTimeZone)?.countries();

	const toggleTermsAndConsitionsModal = () => {
		setIsTermsAndConditionsModalOpen(!isTermsAndConditionsModalOpen);
	};
	const togglePrimaryPasswordShow = () => {
		setIsPrimaryPasswordShow(!isPrimaryPasswordShow);
	};
	const toggleSecondaryPasswordShow = () => {
		setIsSecondaryPasswordShow(!isSecondaryPasswordShow);
	};

	const handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		if (name == 'country') {
			dispatch(authActions.signUpFormChange({ name, value }));
			dispatch(
				authActions.signUpFormChange({
					name: 'currency',
					value: Country.getCountryByCode(value)?.currency,
				}),
			);
		} else {
			dispatch(authActions.signUpFormChange({ name, value }));
		}
	};

	const currencies = Object.keys(getSymbolFromCurrency.currencySymbolMap);
	const CurrenciesISO2 = currencies.map((currency) => {
		return {
			label: currency,
			value: currency,
		};
	});

	useEffect(() => {
		dispatch(profileActions.getAllCountries(Country.getAllCountries()));

		const localeCountryCode = getCountryAbbr?.length > 0 ? getCountryAbbr[0] : 'US';
		dispatch(
			authActions.signUpFormChange({
				name: 'currency',
				value: Country.getCountryByCode(localeCountryCode).currency,
			}),
		);
		dispatch(authActions.signUpFormChange({ name: 'country', value: localeCountryCode }));
	}, []);

	const onSubmit = (event) => {
		event.preventDefault();

		if (areTermsAndConditionsAgreed) {
			onSignUp();
		} else {
			toggleTermsAndConsitionsModal();
		}
	};

	const onSignUp = async () => {
		setAreTermsAndConditionsAgreed(true);
		setIsTermsAndConditionsModalOpen(false);

		dispatch(
			authActions.setSignUpFormErrors({
				name: 'emailErrorText',
				value: validateEmailError(form.email),
			}),
		);

		dispatch(
			authActions.setSignUpFormErrors({
				name: 'countryErrorText',
				value: requiredError(form.country),
			}),
		);

		dispatch(
			authActions.setSignUpFormErrors({
				name: 'currencyErrorText',
				value: requiredError(form.currency),
			}),
		);

		dispatch(
			authActions.setSignUpFormErrors({
				name: 'fullNameErrorText',
				value: requiredError(form.full_name),
			}),
		);

		dispatch(
			authActions.setSignUpFormErrors({
				name: 'companyErrorText',
				value: requiredError(form.company),
			}),
		);

		dispatch(
			authActions.setSignUpFormErrors({
				name: 'passwordErrorText',
				value: requiredError(form.password),
			}),
		);

		dispatch(
			authActions.setSignUpFormErrors({
				name: 'confirmPasswordErrorText',
				value: requiredError(form.confirmPassword),
			}),
		);

		if (form.confirmPassword) {
			dispatch(
				authActions.setSignUpFormErrors({
					name: 'confirmPasswordErrorText',
					value: matchPasswordError(form.password, form.confirmPassword),
				}),
			);
		}

		if (form.password) {
			dispatch(
				authActions.setSignUpFormErrors({
					name: 'passwordErrorText',
					value: passwordCheckError(form.password),
				}),
			);
		}

		if (
			!requiredError(form.full_name) &&
			!requiredError(form.company) &&
			!requiredError(form.country) &&
			!requiredError(form.currency) &&
			!requiredError(form.password) &&
			!requiredError(form.confirmPassword) &&
			!validateEmailError(form.email) &&
			!passwordCheckError(form.password) &&
			!matchPasswordError(form.password, form.confirmPassword)
		) {
            const { formattedTimeZone } = useTimeZone();

			form.full_name = capitalize(form.full_name);
			form.company = capitalize(form.company);
            form.timezone = formattedTimeZone;

			dispatch(
				authActions.register(form, history, zapierClientId, zapierRedirectUri, zapierState),
			);
		}

		// const fullName = required(values.fullName, setFullNameError);
		// const companyName = required(values.companyName, setCompanyNameError);
		// const password = passwordcheck(
		// 	values.password,
		// 	setPasswordError,
		// 	values.confirmPassword,
		// 	setConfirmPasswordError,
		// );

		// const isEmail = validEmail(values.eMail, seteMailError);

		// if (fullName && companyName && password && isEmail) {
		// 	// if (values.password!=values.confirmPassword ){
		// 	//   setConfirmPasswordError('Password doesn\'t match above password');
		// 	// } else {
		// 	setLoading(true);
		// 	try {
		// 		// await AuthService.signup(values.fullName,values.companyName,values.eMail, values.password).then(
		// 		await noAuth('/v1/auth/register', 'post', {
		// 			full_name: values.fullName,
		// 			company: values.companyName,
		// 			email: values.eMail,
		// 			password: values.password,
		// 		}).then(
		// 			(res) => {
		// 				if (res.status == 201) {
		// 					const response = JSON.parse(JSON.stringify(res));
		// 					dispatch(storeUserData(values.eMail));
		// 					dispatch(storeUserId(res.data.user_id));
		// 					dispatch(
		// 						StoreToken(
		// 							res.data.access_token,
		// 							res.data.expires_at,
		// 							res.data.refresh_token,
		// 							Date.now() + 604800000,
		// 						),
		// 					);
		// 					setTimeout(() => {
		// 						history.push('/activation');
		// 					}, 1000);
		// 				} else if (res.response) {
		// 					setLoading(false);
		// 					setConfirmPasswordError(res.response.data.message);
		// 				} else {
		// 					setLoading(false);
		// 					alert(JSON.stringify(res.message));
		// 				}
		// 			},
		// 			(error) => {
		// 				console.log(`error: ${error}`);
		// 			},
		// 		);
		// 	} catch (err) {
		// 		// seteMailError('This user is already registered')
		// 		console.log(err);
		// 	}
		// 	// dispatch(updateSignUpStep('SignUpActivation', '', '', ''));

		// 	// }
		// } else {
		// 	//
		// }
	};

	return (
		<>
			<div className='signUpBox formText'>
				<div>
					<h1 className='titleBold'>Create Account</h1>
				</div>

				<form onSubmit={onSubmit}>
					<div>
						<label htmlFor='fullname'>
							<div className='d-flex align-items-end'>
								<div className=' d-flex align-items-end'>Full Name&nbsp; </div>
								<div style={{ color: '#F05544' }} className=''>
									*
								</div>
							</div>
						</label>

						<div>
							<input
								type='text'
								id='fullname'
								name='full_name'
								value={form.full_name}
								onChange={handleChange}
								// pattern='[A-Za-z ]+'
								title='Please enter text only'
							/>
						</div>
						<span className='error'>{errors.fullNameErrorText}</span>
					</div>

					<div>
						<label htmlFor='companyname'>
							<div className='d-flex align-items-end'>
								<div className=' d-flex align-items-end'>Company Name &nbsp; </div>
								<div style={{ color: '#F05544' }} className=''>
									*
								</div>
							</div>
						</label>
						<div>
							<input
								type='text'
								id='companyname'
								name='company'
								value={form.company}
								onChange={handleChange}
							/>
						</div>
						<span className='error'>{errors.companyErrorText}</span>
					</div>

					<div>
						<label htmlFor='email'>
							<div className='d-flex align-items-end'>
								<div className=' d-flex align-items-end'>Email &nbsp; </div>
								<div style={{ color: '#F05544' }} className=''>
									*
								</div>
							</div>
						</label>
						<div>
							<input
								type='text'
								id='email'
								name='email'
								value={form.email}
								onChange={handleChange}
							/>
						</div>
						<span className='error'>{errors.emailErrorText}</span>
					</div>

					<div>
						<label htmlFor='email'>
							<div className='d-flex align-items-end'>
								<div className=' d-flex align-items-end'>Country &nbsp; </div>
								<div style={{ color: '#F05544' }}>*</div>
							</div>
						</label>
						<div>
							<Select
								value={{ value: form?.country }}
								options={countries}
								id='country'
								name='country'
								style={{
									height: '28px',
									borderRadius: '3px 3px 0px 0px',
									border: '0',
									borderBottom: '1.5px solid black',
									width: '100%',
								}}
								onChange={handleChange}
							/>
						</div>
						<span className='error'>{errors.countryErrorText}</span>
					</div>

					<div>
						<label htmlFor='email'>
							<div className='d-flex align-items-end'>
								<div className=' d-flex align-items-end'>Currency &nbsp; </div>
								<div style={{ color: '#F05544' }}>*</div>
							</div>
						</label>
						<div>
							<Select
								value={{ value: form?.currency }}
								options={CurrenciesISO2}
								id='currency'
								name='currency'
								style={{
									height: '28px',
									borderRadius: '3px 3px 0px 0px',
									border: '0',
									borderBottom: '1.5px solid black',
									width: '100%',
								}}
								onChange={handleChange}
							/>
						</div>
						<span className='error'>{errors.currencyErrorText}</span>
					</div>

					<div>
						<label htmlFor='password'>
							<div className='d-flex align-items-end'>
								<div className=' d-flex align-items-end'>Password&nbsp; </div>
								<div style={{ color: '#F05544' }} className=''>
									*
								</div>
							</div>
						</label>
						<div className='w-100 position-relative'>
							<input
								type={isPrimaryPasswordShow ? 'text' : 'password'}
								id='password'
								name='password'
								value={form.password}
								onChange={handleChange}
							/>

							<PasswordInputIcon
								showPasswordState={isPrimaryPasswordShow}
								togglePasswordShowState={togglePrimaryPasswordShow}
							/>
						</div>
					</div>
					<span className='error'>{errors.passwordErrorText}</span>
					<div>
						<label htmlFor='confirmPassword'>
							<div className='d-flex align-items-end'>
								<div className=' d-flex align-items-end'>
									Confirm Password &nbsp;{' '}
								</div>
								<div style={{ color: '#F05544' }} className=''>
									*
								</div>
							</div>
						</label>
						<div className='w-100 position-relative'>
							<input
								type={isSecondaryPasswordShow ? 'text' : 'password'}
								id='confirmPassword'
								name='confirmPassword'
								value={form.confirmPassword}
								onChange={handleChange}
							/>
							<PasswordInputIcon
								showPasswordState={isSecondaryPasswordShow}
								togglePasswordShowState={toggleSecondaryPasswordShow}
							/>
						</div>
						<span className='error'>{errors.confirmPasswordErrorText}</span>
					</div>

					<br />

					<div className='text-left  mt-2'>
						<span style={{ color: 'red', fontSize: '0.9em' }}>{errors.errorText}</span>
					</div>

					<div>
						<div style={{ display: 'grid' }}>
							<button
								type='submit'
								value='Submit'
								style={{
									background: '#FB7A03',
									marginTop: '12px',
									opacity: loading ? 0.5 : 1,
								}}
								disabled={loading}
							>
								{loading ? (
									<div
										className='spinner-border spinner-border-sm text-light'
										role='status'
									></div>
								) : (
									`Sign up`
								)}
							</button>
						</div>
					</div>
				</form>
				<div>
					<div style={{ textAlign: 'center', marginTop: '16px' }}>
						<Link
							to='/login'
							style={{ color: '#FB7A03', fontSize: '14px', lineHeight: '16px' }}
						>
							Already have an account? Log in
						</Link>
					</div>
				</div>
			</div>

			<Modal
				className='flex justify-content-center align-items-center'
				open={isTermsAndConditionsModalOpen}
				onClose={toggleTermsAndConsitionsModal}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<TermsAndCondistionsModal
					onClose={toggleTermsAndConsitionsModal}
					onSubmit={onSignUp}
					ref={modalRef}
				/>
			</Modal>
		</>
	);
};
export default SignUpForm;
