/* eslint-disable no-mixed-spaces-and-tabs */
import moment from 'moment';
import { memo, useState, useEffect, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ClickAwayListener, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as TimeReportsActions from '../../../redux/actions/time-reports-actions-v2';
import * as userActions from '../../../redux/actions/user-management.actions';
import { convertDecimalToTime } from '../../../utilities/utilities';
import SkeletonLoading from '../../Others/SkeletonLoading';
import { ReactComponent as Indicator } from '../../../assets/icons/ArrowRight.svg';

const capacityFilterOption = {
    ASC: 'ASC',
    DESC: 'DESC',
};

function TeamMembersAvailability({ hoverOverDate, onClose }) {
    const dispatch = useDispatch();
    const [activebtn, setActivebtn] = useState('Daily');
    const [capacityFilter, setCapacityFilter] = useState(capacityFilterOption.ASC);

    const weekDaysOff = useSelector((state) => state.workSpaceReducer.weekDaysOff);
    const weekStartDate = moment(hoverOverDate).startOf('isoWeek');
    let weekEndDate = moment(hoverOverDate).endOf('isoWeek');
    if (!weekDaysOff) weekEndDate = weekEndDate.subtract(2, 'days');

    const usersLoading = useSelector((state) => state.TimeReportsV2.loading);
    const userBillingListLoading = useSelector((state) => state.userReducer.loading);
    const loading = Boolean(usersLoading || userBillingListLoading);
    const filters = useSelector((state) => state.workSpaceReducer.filters);

    const users = useSelector((state) => state.TimeReportsV2.users);
    const userBillingList = useSelector((state) => state.userReducer.userBillingList).reduce(
        (map, obj) => {
            map[obj.user_id] = obj;
            return map;
        },
        {},
    );

    const toggleActivebtn = (type) => () => {
        setActivebtn(type);
    };

    const toggleCapacityFilter = () => {
        setCapacityFilter(
            capacityFilterOption.ASC == capacityFilter
                ? capacityFilterOption.DESC
                : capacityFilterOption.ASC,
        );
    };

    const usersData = useMemo(() => {
        return users
            .map(({ full_name, user_id, budgeted_hours }) => {
                const availableHours =
                    activebtn == 'Daily'
                        ? (userBillingList[user_id]?.available_hours || 0) / 5
                        : userBillingList[user_id]?.available_hours || 0;
                const capacity = availableHours - budgeted_hours;
                return {
                    full_name: full_name,
                    user_id: user_id,
                    budgeted_hours: budgeted_hours,
                    availableHours: availableHours,
                    capacity: capacity,
                };
            })
            .slice()
            .sort((a, b) => {
                const capacityA = a.capacity;
                const capacityB = b.capacity;
                return capacityFilter == capacityFilterOption.ASC
                    ? capacityA - capacityB
                    : capacityB - capacityA;
            });
    }, [capacityFilter, users, userBillingList]);

    useEffect(() => {
        activebtn == 'Daily' &&
            hoverOverDate &&
            dispatch(
                TimeReportsActions.getUserStats(
                    hoverOverDate?.format('MM/DD/YYYY'),
                    hoverOverDate?.format('MM/DD/YYYY'),
                    filters,
                ),
            );
        activebtn == 'Weekly' &&
            weekEndDate.isValid() &&
            weekStartDate.isValid() &&
            dispatch(
                TimeReportsActions.getUserStats(
                    weekStartDate?.format('MM/DD/YYYY'),
                    weekEndDate?.format('MM/DD/YYYY'),
                    filters,
                ),
            );
    }, [dispatch, activebtn, filters]);

    useEffect(() => {
        dispatch(userActions.getUsersBilling());
    }, []);

    return (
        <ClickAwayListener onClickAway={onClose}>
            <div className='TeamMembersAvailability'>
                <div className='d-flex justify-content-between'>
                    <h4 className='HeadlineThreeBold pt-3'>
                        {activebtn == 'Daily' && moment(hoverOverDate).format('dddd, MMMM DD')}
                        {activebtn == 'Weekly' &&
                            `
                    ${moment(weekStartDate).format('dddd, MMMM DD')} - ${moment(weekEndDate).format(
                                'dddd, MMMM DD',
                            )}
                    `}
                    </h4>

                    <IconButton className='CloseButton transparent' onClick={onClose}>
                        <CloseIcon sx={{ color: '#333' }} />
                    </IconButton>
                </div>
                <div className='weekly-daily-bar d-flex' style={{ gap: '12px' }}>
                    <button
                        onClick={toggleActivebtn('Daily')}
                        className={
                            activebtn == 'Daily' ? 'primary-btn' : 'secondary-btn bg-transparent'
                        }
                    >
                        Daily
                    </button>

                    <button
                        onClick={toggleActivebtn('Weekly')}
                        className={`${activebtn == 'Weekly' ? 'primary-btn' : 'secondary-btn bg-transparent'
                            }`}
                    >
                        Weekly
                    </button>
                </div>
                <div
                    style={{ boxSizing: 'border-box', overflow: 'auto' }}
                    className='d-flex flex-grow-1'
                >
                    <table>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Available Hours</th>
                                <th>Budgeted Hours</th>
                                <th onClick={toggleCapacityFilter} className='pointer'>
                                    <Indicator
                                        className='primary-text'
                                        style={{
                                            transform:
                                                capacityFilterOption.ASC == capacityFilter
                                                    ? 'rotate(90deg)'
                                                    : 'rotate(-90deg)',
                                        }}
                                    />
                                    Capacity
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {loading ? (
                                <>
                                    <tr>
                                        <td colSpan={4}>
                                            <SkeletonLoading
                                                loading={true}
                                                variant='rectangular'
                                                height={25}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <SkeletonLoading
                                                loading={true}
                                                variant='rectangular'
                                                height={25}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <SkeletonLoading
                                                loading={true}
                                                variant='rectangular'
                                                height={25}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <SkeletonLoading
                                                loading={true}
                                                variant='rectangular'
                                                height={25}
                                            />
                                        </td>
                                    </tr>
                                </>
                            ) : usersData?.length > 0 ? (
                                usersData.map(
                                    ({
                                        full_name,
                                        user_id,
                                        budgeted_hours,
                                        availableHours,
                                        capacity,
                                    }) => {
                                        return (
                                            <tr key={user_id}>
                                                <td>{full_name}</td>
                                                <td>{convertDecimalToTime(availableHours)}</td>
                                                <td>{convertDecimalToTime(budgeted_hours)}</td>
                                                <td className={`${capacity < 0 && 'red-text'}`}>
                                                    {convertDecimalToTime(capacity || 0)}
                                                </td>
                                            </tr>
                                        );
                                    },
                                )
                            ) : (
                                <>
                                    <tr>
                                        <td className='text-center' colSpan={4}>
                                            No User
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </ClickAwayListener>
    );
}

export default memo(TeamMembersAvailability);
