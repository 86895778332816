import { CircularProgress, Modal } from '@mui/material';
import { useState } from 'react';
import DummyModal from '../Modal/DummyModal';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import { Input } from '../Inputs/Input';
import { useDispatch, useSelector } from 'react-redux';
import * as invoiceActions from '../../redux/actions/invoices-actions';
import moment from 'moment';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';
import { useHistory } from 'react-router';
import RichTextEditor from '../Inputs/RichTextEditor';
import getSymbolFromCurrency from 'currency-symbol-map';

function InvoicePreview({ modalIsOpen, toggleModal }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const clientBilling = useSelector((state) => state.clientBillingReducer.clientBilling);
	const activeClientbilling = clientBilling?.find((item) => item?.is_active === true);
	const invoice = useSelector((state) => state.invoicesReducer.invoice);
	const companyProfileData = useSelector((state) => state.profileReducer.profileData);
	const client = useSelector((state) => state.clientReducer.client);
	const currencySymbol = invoice ? getSymbolFromCurrency(invoice?.currency) : '';

	const initialForm = {
		subject: 'Your Invoice is ready!',
		message: `Hi ${
			client?.dba || client?.company_name
		}, <br>Your invoice is now available for review. <br>Let me know if you have any questions. <br>Best, <br>${
			companyProfileData?.name
		}`,
		cc: '',
		email_to: activeClientbilling?.billing_contact?.email_to || '',
	};
	const [form, setForm] = useState(initialForm);
	// const [isChecked, setIsChecked] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (event) => {
		const { name, value } = event.target;

		setForm({
			...form,
			[name]: value,
		});
	};

	const quillChange = (value, delta, source) => {
		if (source == 'user') {
			setForm({
				...form,
				message: value,
			});
		}
	};

	const handleSubmitInvoice = () => {
		if (!form?.email_to?.trim()) {
			dispatch(setSnackBarIsOpen(false, true, 'Please specify a recipient'));
			return;
		}
		setLoading(true);
		dispatch(
			invoiceActions.submitInvoice(invoice?._id, {
				...form,
				due_date: invoice?.due_date,
				total_amount: invoice?.total_amount,
                amount_due: invoice?.amount_due,
				image_url: companyProfileData?.image_url,
				hosted_url: `https://${window.location.hostname}:${window.location.port}/invoice-preview/${invoice?._id}`,
				// is_copy: isChecked,
			}),
		)
			.then(() => {
				dispatch(setSnackBarIsOpen(true, true, 'Invoice successfully sent!'));
				history.push('/invoices');
			})
			.catch(() => {
				dispatch(setSnackBarIsOpen(false, true, 'Failed to sent invoice'));
			})
			.finally(() => {
				setLoading(false);
				toggleModal();
			});
	};

	return (
		<div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						<DummyModal
							onClose={toggleModal}
							sectionhead={true}
							hideCrossIcon
							style={{ width: '945px' }}
						>
							<div
								className='d-flex justify-content-between'
								style={{ padding: '24px' }}
							>
								<div
									style={{
										height: '510px',
										display: 'flex',
										width: '400px',
										flexDirection: 'column',
										justifyContent: 'space-evenly',
									}}
								>
									<span className='HeadlineTwoBold'>Invoice Preview</span>

									<div
										className='improved-input-designs'
										style={{ width: '400px' }}
									>
										<Input
											name='email_to'
											value={form?.email_to}
											onChange={handleChange}
											label='To'
											required
										/>
									</div>
									{/* <div className='d-flex align-items-center'>
										<input
											type='checkbox'
											className='multi-select-checkbox'
											style={{ width: '16px', height: '16px' }}
											name='is_copy'
											checked={isChecked}
											onChange={handleChange}
										/>
										<label
											className='text-left ml-2 BodyTwoLight'
											style={{ color: '#696F6C', marginTop: '10px' }}
										>
											Send me a copy
										</label>
									</div> */}
									{/* {isChecked && ( */}
									<div
										className='improved-input-designs'
										style={{ width: '400px' }}
									>
										<Input
											value={form?.cc}
											onChange={handleChange}
											name='cc'
											label='Cc'
											// required
										/>
									</div>
									{/* )} */}
									<div
										className='improved-input-designs '
										style={{ width: '400px' }}
									>
										<Input
											name='subject'
											value={form?.subject}
											onChange={handleChange}
											label='Subject'
										/>
									</div>
									<div
										className='improved-input-designs '
										style={{ width: '400px' }}
									>
										<label className='text-left'>Message</label>
										<RichTextEditor
											className='primary-text pointer '
											value={form?.message}
											type='text'
											Edit={true}
											rows={1}
											style={{
												height: '170px',
												width: '100%',
												lineHeight: '30px',
												marginBottom: '3.4rem',
											}}
											name='message'
											onChange={quillChange}
										/>
										{/* <textarea
											id='message'
											rows='5'
											cols='50'
											style={{
												height: '222px',
												lineHeight: '30px',
												resize: 'none',
											}}
											value={form?.message.replace(/<br>/g, '\n')}
											onChange={handleChange}
											className='invoice-email-template'
											name='message'
										/> */}
									</div>
								</div>
								<div
									style={{
										height: '510px',
										width: '430px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
										backgroundColor: 'white',
									}}
								>
									<div
										dangerouslySetInnerHTML={{
											__html: `<div style="height:510px;display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; ">
                                            <div style="width:85px;height:85px; background: ${
												companyProfileData?.image_url ? '' : '#ccc'
											}; display: flex; align-items: center; justify-content: center; border-radius: 50%;">${
												companyProfileData?.image_url
													? `<img src="${companyProfileData.image_url}" alt="" style="width:85px;height:85px;">`
													: `<span style="font-size: 24px; color: white;">${
															companyProfileData?.name
																?.substring(0, 2)
																.toUpperCase() || ''
													}</span>`
											}</div>
                                            <div style="width:430px;height:auto;min-height:100px;background:#FED0A5;display:flex; flex-direction: column; align-items: center; justify-content: space-evenly;">
                                            <span class='HeadlineThreeBold' > ${form?.subject?.replace(
												/\n/g,
												'<br>',
											)}</span>
                                            <span class='BodyTwoLight'>Total: ${currencySymbol}${
												invoice?.amount_due || 0
											} </span>
                                            <span class='BodyTwoLight'>Due ${moment(
												invoice?.due_date,
											).format('MMMM DD, YYYY')}</span>
                                            </div>
                                            <div>
                                       
                                            <div
                                            class='BodyTwoLight invoice-email-template text-center'
                                            contenteditable
                                            style="width:310px;height:120px;border:none;resize: none; white-space: pre-line;outline:none;overflow:auto"
                                          >
                                            ${form?.message?.replace(/\n/g, '<br>')}
                                          </div>
                                          </div>
                                            <button class='rounded-button d-flex' id='review-button' onclick="window.open('https://${window.location.hostname}:${window.location.port}/invoice-preview/${invoice?._id}', '_blank')">
											<span class='BodyTwoBold p-1'>Review</span>
										</button>
                                            
                                            </div>`,
										}}
									></div>
								</div>
							</div>
							<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
								<SubmitButtonsField child={true}>
									<button
										id='Element'
										type='button'
										className='secondary'
										style={{ borderRadius: '50px', width: '125px' }}
										onClick={toggleModal}
									>
										Cancel
									</button>

									<button
										type='submit'
										className='d-flex align-items-center'
										style={{ borderRadius: '50px', width: '125px' }}
										id='Element'
										onClick={handleSubmitInvoice}
										disabled={loading}
									>
										{loading ? (
											<CircularProgress
												size={22}
												sx={{ color: 'white' }}
												disableShrink
											/>
										) : (
											'Send Invoice'
										)}
									</button>
								</SubmitButtonsField>
							</div>
						</DummyModal>
					</>
				</Modal>
			)}
		</div>
	);
}

export default InvoicePreview;
