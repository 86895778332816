/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';
import { ReactComponent as Info } from '../../assets/icons/Info.svg';
import RequireToolTip from '../Others/RequireToolTip';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import {
	TableItemWidth1,
	TableItemWidth3,
} from '../TableSpacing/TableSpacingStyles';
import TableRowItem from '../Others/TableRowItem';
import { EditableDropdown } from '../Inputs/EditableDropDown';
import { ReactComponent as Down } from '../../assets/icons/next.svg';
import { ReactComponent as Up } from '../../assets/icons/client copy.svg';
import { Checkbox, CircularProgress, IconButton, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as builderActions from '../../redux/actions/workflow-builder-actions';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import {
	inputDateFormate,
	Member,
	ordinal_suffix_of,
	removeHttp,
	REPEAT_TYPE,
	sortTasks,
	weekOfMonth,
} from '../../utilities/utilities';
import moment from 'moment';
import { getWeeksInMonth } from 'date-fns';
import DummyModal from '../Modal/DummyModal';
import RecurrenceModal from '../ClientManagement/Modals/RecurrenceModal';
import useModal from '../Modal/useModal';
// import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import Board from '../ClientManagement/Workflow-Builder/Board';
import { states2 } from '../DemoData';
import Card from '../ClientManagement/Workflow-Builder/Card';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { getValidComponent } from '../../utilities/BuilderUtils';
import EditableText from '../Inputs/EditableText';
import TableRowButton from '../Others/TableRowButton';
import ConfirmModal from '../Modal/ConfirmModal';
import DynamicModal from '../ClientManagement/Modals/DynamicModal';
import WorkflowTaskModal from '../ClientManagement/Modals/WorkflowTaskModal';
import EditableTextArea from '../Inputs/EditableTextArea';
import { useHistory, useParams } from 'react-router';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import { motion } from 'framer-motion';

export default function ExpandableWorkflowBox({
	name,
	tasks,
	workflow,
	clientId,
	onClose,
	isDraggable,
	edit,
	hideCheckbox,
}) {
	const dispatch = useDispatch();
	const { id } = useParams();
	const history = useHistory();

	const [FormProp, setFormProp] = useState({});
	const [LoadingItems, setLoadingItems] = useState([]);
	const [selectedSection, setSelectedSection] = useState([]);
	const [toDeleteTask, setToDeleteTask] = useState({});
	const [draggedObj,setDraggedObj]=useState({from:null,to:null});
	const selectedWorkflowsId = useSelector(
		(state) => state.WorkflowBuilder.selectedWorkflowsId,
	);
	const [advanceScheduling, setAdvanceScheduling] = useState(false);
	let teamMembers = useSelector((state) => state.teamReducer.teamMembers);
	const loading = useSelector((state) => state.WorkflowBuilder.loading);
	const urls = useSelector((state) => state.clientAccountsReducer.allUrls);
	const workflowTasks = sortTasks(tasks);

	const clientAccounts = useSelector(
		(state) => state.clientAccountsReducer.clientAccounts,
	)?.map((item) => {
		return {
			...item,
			softwares: item?.softwares?.map((soft) => {
				return {
					...soft,
					institution: urls?.find((url) => url.id == soft.institution_url_id)?.institution || soft?.name,
					login: urls?.find((url) => url.id == soft.institution_url_id)?.url || soft?.custom_url
		};
			})
		};
	});

	const client = useSelector((state) => state.clientReducer.client);

	const [selected, setSelected] = useState(false);

	const { modalIsOpen, toggleModal, ModalType, ModalName } = useModal();

	if (teamMembers?.length > 0) {
		teamMembers = teamMembers.map((assignee) => {
			return {
				...assignee,
				value: assignee.user_id,
				label: assignee.name,
			};
		});
	}

	const setSelectedComponent = (item, e) => {
		const elementsOne = document.getElementsByClassName('deleteComponent');
		for (let i = 0; i < elementsOne.length; i++) {
			elementsOne[i].style.setProperty('display', 'none', 'important');
		}

		document
			.getElementById(item.id + ' delete-component')
			?.style.setProperty('display', 'block', 'important');

		const elementsTwo = document.getElementsByClassName('selected');
		for (let i = 0; i < elementsTwo.length; i++) {
			elementsTwo[i].style.border = 'none';
		}

		document
			.getElementById(item.id)
			?.style.setProperty('border', '1px dotted #fb7a03', 'important');

		dispatch(builderActions.setComponent(item));
	};

	const deleteItem = (item) => {
		setTimeout(() => {
			dispatch(builderActions.deleteComponent(item.id));
		}, 500);
	};

	const deleteTask = (index, taskId) => {
		dispatch(builderActions.deleteWorkflowTaskRow(index, taskId, workflow?.id));
		setTimeout(() => setLoadingItems([]), 1000);
	};

	const addNewTaskRow = (workflow) => {
		ModalType('WORKFLOW_TASK');
		dispatch(builderActions.clearWorkflowTaskValues());
		setFormProp({
			workflow_id: workflow?.id,
			client_id: Number(clientId),
		});
		toggleModal();
	};

	const deleteTablerow = (workflowName, sectionIndex, rowIndex) => {
		dispatch(
			builderActions.deleteWorkflowTableRow(
				workflowName,
				sectionIndex,
				rowIndex,
			),
		);
	};

	const budgetedHoursChangeHandler = (event, taskId, index) => {
		const name = event.target.name;
		const value = event.target.value;
		const pattern=/^\d*\.?\d*$/;
			if(!pattern.test(value)){
				return ;
		}
		// if (isNaN(value) || parseInt(value) < 0) {
		// 	//
		// } else {

		// 	// value = parseFloat(value);
		// }

		dispatch(
			builderActions.handleTaskDetailsChange({
				name,
				value,
				workflow,
				taskId,
				index,
			}),
		);
	};

	const advanceSchedulingHandler = (event) => {
		const { checked } = event.target;
		setAdvanceScheduling(checked);
	};

	const handleChange = (e, taskId, index) => {
		let { name, value } = e.target;
		if (
			name === 'assignee_user_id' ||
			name === 'budgeted_hours' ||
			name === 'dependent_task_id' ||
			name === 'approval_from' ||
			name === 'required_days'
		) {
			value = parseInt(value);
			if (isNaN(value)) value = null;
		}


		if (name === 'required_days' && value < 0) {
			value = 0;
		}
		
		if (name === 'required_days' && value > workflow?.required_days) {
			value = workflow?.required_days;
		}

		console.info(value , workflow.required_days);

		if (name === 'budgeted_hours' && value < 1) {
			value = 0.1;
		}

		if (name === 'assignee_user_id' && value === null) {
			dispatch(
				builderActions.handleTaskDetailsChange({
					name: 'approval_from',
					value: null,
					workflow,
					taskId,
					index,
				}),
			);
		}

		dispatch(
			builderActions.handleTaskDetailsChange({
				name,
				value,
				workflow,
				taskId,
				index,
			}),
		);
	};

	const workflowTaskArray = [];
	tasks?.sort(function (a, b) {
		return a.order - b.order;
	});

	tasks?.map((task) => {
		let nextTask = task;

		tasks?.map((wt) => {
			if (nextTask.id === wt.dependent_task_id) {
				nextTask = wt;
			} else {
				workflowTaskArray.push({
					...wt,
					parentId: task.id,
				});
			}
		});
	});

	const inputChangeHandler = (item, workflowName) => (e) => {
		let valueStructure = '';
		switch (item?.componentType) {
			case 'dropdown':
				valueStructure = {
					value: e.target.value,
					label: e.target.value,
				};
				break;
			case 'input':
				valueStructure = e.target.value;
				break;
			case 'datepicker':
				valueStructure = e.target.value;
				break;
			case 'textarea':
				valueStructure = e;
				break;
			case 'radio':
				valueStructure = e.target.value;
				break;
			case 'select-group':
				valueStructure = e;
				break;
			case 'pick-list':
				valueStructure = e;
				break;
			case 'software-pick-list':
				valueStructure = e;
				break;
			case 'contact-pick-list':
				valueStructure = e;
				break;
			case 'state-pick-list':
				valueStructure = e;
				break;
			case 'location-pick-list':
				valueStructure = e;
				break;
			case 'links-pick-list':
				valueStructure = e;
				break;
			case 'merchant-pick-list':
				valueStructure = e;
				break;
			case 'bank-pick-list':
				valueStructure = e;
				break;
			case 'account-pick-list':
				valueStructure = e;
				break;
			default:
				break;
		}
		dispatch(
			builderActions.setWorkflowComponentValue({
				...item,
				value: valueStructure,
				workflowName: workflowName,
			}),
		);
	};

	const changeRowValues = (
		workflowName,
		sectionIndex,
		rowIndex,
		withinRowIndex,
		e,
	) => {
		dispatch(
			builderActions.changeWorkflowTableValue(
				e.target.value,
				workflowName,
				sectionIndex,
				rowIndex,
				withinRowIndex,
			),
		);
	};

	const openDynamicForm = (formFields, workflow, sectionIndex) => {
		ModalType('DYNAMIC_MODAL');
		const obj = {};
		formFields?.map((item) => {
			const fieldName = item.name.split(' ').join('_');
			obj[fieldName] = '';
			return item;
		});
		setFormProp({
			formLabel: formFields,
			formName: obj,
			workflowName: workflow?.workflow_builder?.data[0]?.componentName,
			sectionIndex: sectionIndex,
		});
		toggleModal();
	};

	const handleWorkflowsCheckbox = (e) => {
		const { id } = e.target;

		let newArr = [];

		if (!selectedWorkflowsId.includes(parseInt(id))) {
			newArr = [...selectedWorkflowsId, parseInt(id)];
		} else {
			newArr = selectedWorkflowsId.filter((a) => a !== parseInt(id));
		}
		dispatch(builderActions.setSelectedWorkflows(newArr));
	};

	const recurrenceFormSubmit = (CurrentRecurrence, onSubmitComplete) => {
		const recurrenceObj = {
			id: CurrentRecurrence.id,
			start_date: CurrentRecurrence.start_date,
			repeat_interval: CurrentRecurrence.repeat_interval,
			repeat_type: CurrentRecurrence.repeat_type,
			is_monthly_on_day: CurrentRecurrence.is_monthly_on_day,
			recur_preference: CurrentRecurrence.recur_preference,
			end_on: CurrentRecurrence.end_on,
			required_days: CurrentRecurrence.required_days,
		};
		if (CurrentRecurrence.repeat_type !== REPEAT_TYPE.MONTHLY) {
			delete recurrenceObj.is_monthly_on_day;
			recurrenceObj.recur_preference = null;
		} else {
			if (recurrenceObj.recur_preference === null) {
				const currentDayMonth = weekOfMonth(moment(recurrenceObj.start_date));
				const nextWeekDayMonth = weekOfMonth(
					moment(recurrenceObj.start_date).add(7, 'months'),
				);
				if (currentDayMonth === 4 && currentDayMonth === nextWeekDayMonth)
					recurrenceObj.recur_preference = 1;
				if (
					currentDayMonth === 5 ||
					(currentDayMonth === 4 && currentDayMonth < nextWeekDayMonth)
				)
					recurrenceObj.recur_preference = 2;
			}
		}
		dispatch(
			builderActions.setWorkflowData({
				...recurrenceObj,
			}),
		);
		onSubmitComplete();
	};

	const onActivate = (workflow) => {
		if (
			workflow.start_date === null ||
			workflow.repeat_interval === null ||
			workflow.repeat_type === null
		) {
			alert('Set Recurrence');
			return 0;
		}
		const workflowObj = {
			...workflow,
			status:'active',
			is_active: true,
			recur_preference: workflow.recur_preference || null,
		};
		workflowObj.task_templates = workflowObj?.task_templates?.map((item) => {
			return { ...item, budgeted_hours: parseFloat(item.budgeted_hours) || 0.1, required_days: parseInt(item?.required_days) || 0 };
		});

		if (
			(workflowObj.recur_preference == null ||
				workflowObj.recur_preference == undefined) &&
			workflowObj.repeat_type === REPEAT_TYPE.MONTHLY
		) {
			if (
				weekOfMonth(moment(workflowObj.start_date)) === 4 &&
				moment(workflowObj.start_date).add(7, 'days').month() >
				moment(workflowObj.start_date).month() &&
				!workflowObj?.is_monthly_on_day
			) {
				alert(`Recur Preference for ${workflow.name} is required`);
			} else {
				const currentDayMonth = weekOfMonth(moment(workflowObj.start_date));
				const nextWeekDayMonth = weekOfMonth(
					moment(workflowObj.start_date).add(7, 'months'),
				);
				if (currentDayMonth === 4 && currentDayMonth === nextWeekDayMonth)
					workflowObj.recur_preference = 1;
				if (
					currentDayMonth === 5 ||
					(currentDayMonth === 4 && currentDayMonth < nextWeekDayMonth)
				)
					workflowObj.recur_preference = 2;

				dispatch(builderActions.activateWorkflow(id, workflowObj, setSelected));
			}
		} else {
			dispatch(builderActions.activateWorkflow(id, workflowObj, setSelected));
		}
	};
	const onDrag=()=>{
		if(draggedObj.from===draggedObj.to) return ;
		dispatch(builderActions.swapWorkflowTasks({from:tasks[draggedObj.from],to:tasks[draggedObj.to],tasks}));
	};

	useEffect(() => {
		if (tasks?.length > 0) {
			if (tasks?.every((task) => task.required_days == workflow?.required_days)) {
				setAdvanceScheduling(false);
			} else {
				setAdvanceScheduling(true);
			}
		}
	}, [modalIsOpen]);

	return (
		<div style={{ paddingTop: '5px' }}>
			<div className='workflow-list-item'>
				<div className='d-flex align-items-center'>
					<span className='body-1'>{name}</span>
				</div>
				<div className='d-flex align-items-center'>
					<div
						style={{ paddingLeft: '17px', cursor: 'pointer' }}
						onClick={() => setSelected(!selected)}
					>
						{selected ? <Up /> : <Down />}
					</div>
				</div>
			</div>
			{selected && (
				<div className='d-flex flex-column ' style={{ padding: '12px 22px', gap: '16px' }}>
					{workflow?.workflow_builder?.data[0]?.props?.sections?.map(
						(section, j) => {
							return (
								<>
									{section?.componentType == 'COLUMN' && (
										<div
											key={j}
											className='d-flex flex-column w-100 align-items-center ProfileSection'
										>
											<h2
												className={
													'HeadlineTwoEmphasizedCentra text-left w-100 pb-2'
												}
											>
												{section.componentName}
											</h2>

											<div
												className={
													section?.sectionName === 'LINKS' ||
														section?.sectionName === 'NOTES'
														? 'd-flex row w-100  justify-content-between'
														: 'd-flex row w-100 pt-2'
												}
												style={{
													borderTop: '1px solid  #EAEAEA ',
													gap: '1.2em',
												}}
											>
												{section.columns?.map((column, i) => {
													return (
														<div
															key={i}
															className='d-flex flex-column'
															style={{
																width: `calc(90% / ${section?.columns?.length})`,
																minHeight: isDraggable && '200px',
																minWidth: '150px',
																maxWidth: '200px',
																gap: '0.8rem',
															}}
														>
															<h4 className='BodyTwoEmphasized w-100 text-left'>
																{column.columnName}
															</h4>
															<Board
																className={'Board d-flex flex-column pb-3'}
																index={{ col: i, sec: j }}
																id={i}
																Style={{
																	gap: '0.7em',
																	height: '100%',
																}}
															>
																{column.columnComponents?.map((item, i) => {
																	let options = [];

																	switch (item?.componentType) {
																		case 'software-pick-list':
																			options = clientAccounts
																				?.find(
																					(item) => item.type === 'SOFTWARE',
																				)
																				?.softwares?.map((software) => {
																					return {
																						value: software?.id,
																						label: software?.institution,
																					};
																				});

																			break;
																		case 'contact-pick-list':
																			options =
																				client?.client_contact_list?.map(
																					(client) => {
																						return {
																							value: client.id,
																							label: `${client.first_name} ${client.last_name}`,
																						};
																					},
																				);
																			break;

																		case 'links-pick-list':
																			options = client?.links?.map((link) => {
																				return {
																					value: link.id,
																					label: `${link.name} `,
																				};
																			});
																			break;

																		case 'location-pick-list':
																			options =
																				client?.client_office_locations?.map(
																					(loc) => {
																						const state = states2.find(
																							(item) =>
																								item.value ===
																								Number(loc.state),
																						);
																						return {
																							value: loc.id,
																							label: state?.label || loc?.city,
																						};
																					},
																				);
																			break;

																		case 'state-pick-list':
																			options = [...states2];
																			break;

																		case 'account-pick-list':
																			clientAccounts
																				?.filter(
																					(acc) => acc.type !== 'SOFTWARE',
																				)
																				?.map((acc) => {
																					acc?.softwares?.map((software) => {
																						options.push({
																							value: software?.id,
																							label: software?.institution,
																						});
																						return software;
																					});
																					return acc;
																				});

																			break;
																		default:
																			options = item?.options;
																	}

																	let value = '';
																	switch (item.name) {
																		case 'notes':
																			value = client?.notes;
																			break;
																		case 'close_week':
																			value = client?.close_week;
																			break;
																		case 'LINKNAME':
																			value = client?.link?.name;
																			break;
																		case 'LINKSTORAGE':
																			value =
																				client?.link?.account_document_storage;
																			break;
																		case 'LINKLOGIN':
																			value = client?.link?.login;
																			break;
																		case 'LINKNOTES':
																			value = client?.link?.notes;
																			break;
																		default:
																			value = item.value;
																			break;
																	}

																	return (
																		<Card
																			style={{
																				marginTop: isDraggable && '1rem',
																				marginBottom: isDraggable && '1rem',
																			}}
																			key={i}
																			withinColumnIndex={i}
																			id={item.id}
																			className='card selected position-relative'
																			draggable={isDraggable && isDraggable}
																			clickHanlder={(e) => {
																				isDraggable &&
																					setSelectedComponent(item, e);
																			}}
																		>
																			{isDraggable && (
																				<span
																					className='deleteComponent pointer d-flex justify-content-end'
																					id={item.id + ' delete-component'}
																					style={{
																						position: 'absolute',
																						bottom: '100%',
																						right: '0',
																						width: 'fit-content',
																						display: 'none',
																						zIndex: 5,
																					}}
																					onClick={() => {
																						deleteItem(item);
																					}}
																				>
																					<DeleteIcon
																						fontSize='15px'
																						sx={{ color: '#fb7a03' }}
																					/>
																				</span>
																			)}
																			{item &&
																				React.createElement(
																					getValidComponent(
																						item?.componentType,
																					),
																					{
																						onChange: isDraggable
																							? () => { }
																							: inputChangeHandler(
																								item,
																								workflow?.workflow_builder
																									?.data[0]?.componentName,
																								// eslint-disable-next-line no-mixed-spaces-and-tabs
																							),
																						Edit: isDraggable
																							? edit
																							: item.name == 'close_week' ||
																								item.name === 'notes' ||
																								section?.sectionName === 'LINKS'
																								? false
																								: edit,
																						edit: isDraggable
																							? edit
																							: item.name == 'close_week' ||
																								item.name === 'notes'
																								? false
																								: edit,
																						...item,
																						value: value,
																						link: value,
																						options: options || [],
																						withSearch: true,
																						placeholder: `${item?.componentType ==
																							'state-pick-list'
																							? 'Search states'
																							: ''
																							}`,
																						style:
																							item?.componentType === 'textarea'
																								? {
																									height: 'fit-content',
																									maxHeight: '80px',
																									minWidth: '500px',
																									paddingTop: '1px',
																									paddingBottom: '1px',
																									textAlign: 'left',
																									marginBottom:'2.5rem'
																								}
																								: {},
																					},
																				)}
																		</Card>
																	);
																})}
															</Board>
														</div>
													);
												})}
											</div>
										</div>
									)}
									{section?.componentType == 'TABLE' && (
										<div className='d-flex flex-column w-100 align-items-center ProfileSection'>
											<h2 className='HeadlineTwoEmphasizedCentra text-left w-100 pb-2'>
												{section?.componentName}
											</h2>
											<div className='w-100 d-flex flex-column'>
												<div className='Teams-Table'>
													<TableHeader
														ClassName='TableHeader flex'
														border={false}
													>
														{section?.tableHeaders?.map((header, i) => {
															return (
																<TableHeaderItem
																	ClassName={'text-nowrap'}
																	key={i}
																	label={header?.name}
																	width={TableItemWidth3}
																/>
															);
														})}

														{edit && (
															<TableHeaderItem
																label={'Remove'}
																width={TableItemWidth1}
															/>
														)}
													</TableHeader>
													{section?.tableRows?.length < 1 ? (
														<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
															<h4 className='BodyTwoLight placeholder-text'>
																Empty
															</h4>
														</div>
													) : (
														section?.tableRows?.map((item, i) => {
															return (
																<div
																	key={i}
																	className='TableRow d-flex align-items-center'
																>
																	{item?.values.map((rowItem, itemIndex) => {
																		return (
																			<TableRowItem
																				key={itemIndex}
																				ClassName='text-left'
																				label={rowItem}
																				width={TableItemWidth3}
																				input={
																					rowItem?.includes('http')
																						? true
																						: edit
																				}
																			>
																				{edit ? (
																					<EditableText
																						value={rowItem}
																						Edit={edit}
																						onChange={(e) => {
																							changeRowValues(
																								workflow?.workflow_builder
																									?.data[0]?.componentName,
																								j,
																								i,
																								itemIndex,
																								e,
																							);
																						}}
																					/>
																				) : (
																					<a
																						className='BodyTwoLight primary-text extension-call '
																						target='_blank'
																						rel='noreferrer'
																						href={rowItem}
																						style={{
																							display: 'inline-block',
																							maxWidth: '120px',
																							whiteSpace: 'nowrap',
																							overflow: 'hidden',
																							textOverflow: 'ellipsis',
																						}}
																					>
																						{rowItem}
																					</a>
																				)}
																			</TableRowItem>
																		);
																	})}

																	{edit && (
																		<TableRowButton
																			ClassName='d-flex align-items-center justify-content-center'
																			width={TableItemWidth1}
																		>
																			<>
																				<RemoveCircleOutlineIcon
																					fontSize='10px'
																					sx={{
																						color: '#fb7a03',
																					}}
																					onClick={() => {
																						deleteTablerow(
																							workflow?.workflow_builder
																								?.data[0]?.componentName,
																							j,
																							i,
																						);
																					}}
																					className='pointer'
																				/>
																			</>
																		</TableRowButton>
																	)}
																</div>
															);
														})
													)}
													{edit && (
														<div className='w-100 d-flex justify-content-center'>
															<div
																className='pointer'
																style={{
																	height: '22px',
																	width: '22px',
																	borderRadius: '3px',
																	margin: '1rem',
																	padding: 0,
																	position: 'relative',
																	backgroundColor: '#fb7a03',
																}}
																onClick={() => {
																	setSelectedSection(section?.componentName);
																	openDynamicForm(
																		section?.tableHeaders,
																		workflow,
																		j,
																	);
																}}
															>
																<AddIcon
																	fontSize='small'
																	sx={{
																		color: 'white',
																		fontSize: 21,
																		position: 'absolute',
																		margin: 0,
																		padding: 0,
																		top: 0,
																		left: 0,
																	}}
																/>
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									)}
									{section.componentType == 'FILLED_TABLE' && (
										<div className='d-flex flex-column w-100 align-items-center Filled-Table'>
											<div className='w-100 d-flex flex-row justify-content-between align-items-center'>
												<h2 className='HeadlineTwoEmphasizedCentra text-left w-100 pb-2'>
													{section.componentName}
												</h2>
												<div
													className='d-flex flex-row align-items-end pb-1'
													style={{
														gap: '0.5em',
														width: 'max-content',
													}}
												>
													<h4
														style={{
															width: 'max-content',
														}}
														className=' BodyTwoEmphasized text-left'
													>
														Recurrence:
													</h4>
													<h4
														style={{
															width: 'max-content',
														}}
														className=' BodyTwoEmphasized text-left light-dark'
													>
														{/* For Daily Recurrence */}
														{workflow?.repeat_type === REPEAT_TYPE.DAILY &&
															`On every ${workflow?.repeat_interval != 1
																? ordinal_suffix_of(workflow?.repeat_interval)
																: ''
															} day`}

														{/* For Weekly Recurrence */}
														{workflow?.repeat_type === REPEAT_TYPE.WEEKLY &&
															`On ${moment(workflow?.start_date).format(
																'dddd',
															)} of every ${workflow?.repeat_interval != 1
																? ordinal_suffix_of(workflow?.repeat_interval)
																: ''
															} week `}

														{/* For Monthly Recurrence */}
														{workflow?.repeat_type === REPEAT_TYPE.MONTHLY && (
															<>
																{workflow?.is_monthly_on_day
																	? `On ${ordinal_suffix_of(
																		moment
																			.utc(workflow?.start_date)
																			.format('D'),
																	)} of every ${workflow?.repeat_interval != 1
																		? ordinal_suffix_of(
																			workflow?.repeat_interval,
																		)
																		: ''
																	} month`
																	: workflow?.recur_preference
																		? `On
									${workflow?.recur_preference === 1 ? '4th' : 'last'}
									${moment(workflow?.start_date).format('dddd')} of every ${workflow?.repeat_interval != 1
																			? ordinal_suffix_of(
																				workflow?.repeat_interval,
																			)
																			: ''
																		} month`
																		: `On ${getWeeksInMonth(
																			workflow?.repeat_interval,
																		) ==
																			weekOfMonth(moment(workflow?.start_date))
																			? 'last'
																			: ordinal_suffix_of(
																				weekOfMonth(
																					moment(workflow?.start_date),
																				),
																			)
																		} ${moment(workflow?.start_date).format(
																			'dddd',
																		)} of every ${workflow?.repeat_interval != 1
																			? ordinal_suffix_of(
																				workflow?.repeat_interval,
																			)
																			: ''
																		} month`}
															</>
														)}

														{/* For Yearly Recurrence */}
														{workflow?.repeat_type === REPEAT_TYPE.YEARLY &&
															`On ${new Date(
																inputDateFormate(workflow?.start_date),
															).toLocaleDateString('en-US', {
																month: 'long',
																day: 'numeric',
															})} of every ${workflow?.repeat_interval != 1
																? ordinal_suffix_of(workflow?.repeat_interval)
																: ''
															}  year`}
													</h4>
													{(workflow?.repeat_type === null ||
														workflow?.start_date === null ||
														workflow?.repeat_interval === null) && (
															<div
																onClick={() => {
																	ModalType('RECURRENCE_MODAL');
																	toggleModal();
																}}
																className='primary-text Body-TwoEmphasized pointer'
																style={{ width: 'max-content' }}
															>
																Set Recurrence
															</div>
														)}

													{edit && (
														<IconButton
															onClick={() => {
																ModalType('RECURRENCE_MODAL');
																toggleModal();
															}}
															style={{
																margin: 0,
																padding: 0,
															}}
														>
															<BorderColorIcon
																fontSize='inherit'
																style={{
																	fontSize: '16px',
																	color: '#fb7a03',
																}}
															/>
														</IconButton>
													)}
												</div>
											</div>
											<div className='w-100 d-flex flex-column'>
												<div
													className='w-100 d-flex justify-content-start align-items-center'
													style={{
														padding: '10px 0 10px 0',
														borderTop: '1px solid  #EAEAEA ',
														gap: '5px'
													}}
												>
													<span>
														<Checkbox
															checked={advanceScheduling}
															sx={{
																color: '#EAEAEA !important',
																'&.Mui-checked': {
																	color: '#FB7A03 !important',
																},
																'&.MuiCheckbox-root': {
																	'&:hover': {
																		boxShadow: 'none',
																		backgroundColor: 'transparent !important',
																	},
																},
															}}
															style={{
																padding: 0,
															}}
															name={'is_monthly_on_day'}
															onChange={advanceSchedulingHandler}
														/>
													</span>{' '}
													<span className='BodyOneSherif'>
														Use advanced scheduling options
													</span>
												</div>
												<div className='Teams-Table'>
													<TableHeader
														ClassName='TableHeader flex'
														border={false}
													>
														{section?.tableHeaders?.map((header, i) => {
															if (
																!(
																	i == 5 &&
																	!advanceScheduling
																)
															) {
																return (
																	<TableHeaderItem
																		key={i}
																		label={
																			header?.name == 'Budget'
																				? header?.name + 'ed Hours'
																				: header?.name == 'Description' ? 'Instructions' : header?.name
																		}
																		width={TableItemWidth3}
																	/>
																);
															}
														})}

														<TableHeaderItem width={TableItemWidth3}>
															<div
																className={'d-flex fle-row align-items-center'}
																style={{ gap: '5px' }}
															>
																<h3 className=' BodyOneSherif'>
																	Require Approval
																</h3>
																<RequireToolTip
																	title='Choose an option if this task needs approval from admin or team manager'
																	placement='bottom'
																>
																	<Info />
																</RequireToolTip>
															</div>
														</TableHeaderItem>

														{/* {edit && (
													<TableHeaderItem
														label={'Remove'}
														width={TableItemWidth1}
													/>
												)} */}
													</TableHeader>
													{workflowTasks?.length < 1 ? (
														<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
															<h4 className='BodyTwoLight placeholder-text'>
																Empty
															</h4>
														</div>
													) : (
														workflowTasks?.map((item, i) => {
															const dependentOfdependent = workflowTasks
															?.filter((t) => {
																return t.dependent_task_id == item.id;
															})
															?.map((t) => t.id);

															workflowTasks?.map((t) => {
															if (
																dependentOfdependent?.some(
																	(item) => item == t.dependent_task_id,
																)
															) {
																dependentOfdependent.push(t.id);
															}
														});
															return (
																<motion.div
																layout
																key={item.id}
																className={`TableRow d-flex align-items-center`}
															>
																	<TableRowItem
																		ClassName='text-left'
																		label={item?.title}
																		width={TableItemWidth3}
																		input={edit}
																	>
																		<EditableTextArea
																			value={item?.title}
																			type={'text'}
																			Edit={edit}
																			rows={1}
																			style={{
																				overflowY: 'hidden',
																				lineHeight: '1rem',
																			}}
																			name={'title'}
																			onChange={(e) =>
																				handleChange(e, item.id, i)
																			}
																		/>
																	</TableRowItem>

																	<TableRowItem
																		ClassName='text-left'
																		label={item?.description}
																		width={TableItemWidth3}
																		input={edit}
																	>
																		<EditableTextArea
																			value={item?.description}
																			type='text'
																			Edit={edit}
																			rows={1}
																			style={{
																				overflowY: 'hidden',
																				lineHeight: '1rem',
																			}}
																			name={'description'}
																			onChange={(e) =>
																				handleChange(e, item.id, i)
																			}
																		/>
																	</TableRowItem>

																	<TableRowItem
																		ClassName='text-left'
																		label={item?.budgeted_hours}
																		width={TableItemWidth3}
																		input={edit}
																	>
																		<EditableText
																			type='text'
																			value={item?.budgeted_hours}
																			Edit={edit}
																			name={'budgeted_hours'}
																			onChange={(e) =>
																				budgetedHoursChangeHandler(
																					e,
																					item.id,
																					i,
																				)
																			}
																		/>
																	</TableRowItem>
																	<TableRowItem
																		ClassName='text-left'
																		label={
																			teamMembers.find(
																				(state) =>
																					state.value == item.assignee_user_id,
																			)?.label
																		}
																		width={TableItemWidth3}
																		input={edit}
																	>
																		<EditableDropdown
																			value={{
																				label: teamMembers?.find(
																					(state) =>
																						state.value ==
																						item.assignee_user_id,
																				)?.label,
																				value: teamMembers?.find(
																					(state) =>
																						state.value ==
																						item.assignee_user_id,
																				)?.value,
																			}}
																			options={teamMembers?.map((item) => {
																				return {
																					label: item.label,
																					value: item.value,
																					is_active: item.is_active
																				};
																			})}

																			Edit={edit}
																			name='assignee_user_id'
																			onChange={(e) =>
																				handleChange(e, item.id, i)
																			}
																		/>
																	</TableRowItem>
																	<TableRowItem
																		ClassName='text-left'
																		label={
																			tasks?.find(
																				(state) =>
																					state.id == item.dependent_task_id,
																			)?.title
																		}
																		width={TableItemWidth3}
																		input={edit}
																	>
																		<EditableDropdown
																			placeholder='Select Dependency'
																			value={{
																				value: workflowTasks?.find(
																					(state) =>
																						state.id == item.dependent_task_id,
																				)?.id,
																			}}
																			options={workflowTasks
																				?.filter((task) => {
																					return (
																						task.id != item.id &&
																						!dependentOfdependent?.some(
																							(item) => item == task.id,
																						)
																					);
																				})
																				.map((item) => {
																					return {
																						label: item.title,
																						value: item.id,
																					};
																				})}
																			Edit={edit}
																			name='dependent_task_id'
																			onChange={(e) =>
																				handleChange(e, item.id, i)
																			}
																		/>
																	</TableRowItem>

																	{advanceScheduling && (
																		<TableRowItem
																			ClassName='text-left'
																			label={item?.required_days}
																			width={TableItemWidth3}
																			input={edit}
																		>
																			<EditableText
																				type='number'
																				value={item?.required_days}
																				Edit={edit}
																				name={'required_days'}
																				onChange={(e) =>
																					handleChange(e, item.id, i)
																				}

																			/>
																		</TableRowItem>
																	)}

																	<TableRowItem
																		ClassName='text-left'
																		label={
																			teamMembers.find(
																				(state) =>
																					state.value == item.assignee_user_id,
																			)?.label
																		}
																		width={TableItemWidth3}
																		input={edit}
																	>
																		<EditableDropdown
																			tooltipTitle={
																				item.assignee_user_id
																					? ''
																					: 'Please choose an assignee first'
																			}
																			selectDisabled={
																				item.assignee_user_id ? false : true
																			}
																			value={{
																				label: teamMembers?.find(
																					(state) =>
																						state.value == item.approval_from,
																				)?.label,
																				value: teamMembers?.find(
																					(state) =>
																						state.value == item.approval_from,
																				)?.value,
																			}}
																			options={teamMembers
																				?.filter(
																					(user) =>
																						user.user_id !==
																						item.assignee_user_id &&
																						user.role_id !==
																						Member.teamMemberId,
																				)
																				?.map((item) => {
																					return {
																						label: item.label,
																						value: item.value,
																						is_active: item.is_active
																					};
																				})}
																			Edit={edit}
																			name='approval_from'
																			onChange={(e) =>
																				handleChange(e, item.id, i)
																			}
																		/>
																	</TableRowItem>

																	{/* {edit && (
																<TableRowButton
																	ClassName='d-flex align-items-center justify-content-center'
																	width={TableItemWidth1}
																>
																	<>
																		{LoadingItems.includes(item?.id) ? (
																			<IconButton id='basic-button'>
																				<CircularProgress
																					size={20}
																					sx={{ color: '#fb7a03' }}
																					disableShrink
																				/>
																			</IconButton>
																		) : (
																			<RemoveCircleOutlineIcon
																				fontSize='10px'
																				sx={{
																					color: '#fb7a03',
																				}}
																				onClick={() => {
																					setToDeleteTask({
																						index: i,
																						taskId: item?.id,
																					});
																					ModalType('DELETE_TASK_ALERT');
																					toggleModal();
																				}}
																				className='pointer'
																			/>
																		)}
																	</>
																</TableRowButton>
															)} */}
																</motion.div>
															);
														})
													)}
													{/* {edit && (
												<div className='w-100 d-flex justify-content-center'>
													<div
														className='pointer'
														style={{
															height: '22px',
															width: '22px',
															borderRadius: '3px',
															margin: '1rem',
															padding: 0,
															position: 'relative',
															backgroundColor: '#fb7a03',
														}}
														onClick={() => {
															addNewTaskRow(workflow);
														}}
													>
														<AddIcon
															fontSize='small'
															sx={{
																color: 'white',
																fontSize: 21,
																position: 'absolute',
																margin: 0,
																padding: 0,
																top: 0,
																left: 0,
															}}
														/>
													</div>
												</div>
											)} */}
												</div>
											</div>
										</div>
									)}

									{section.componentType == 'COLUMN_TWO' && (
										<div
											className='d-flex flex-row w-100  ProfileSection'
											style={{
												gap: '1.5rem',
												minHeight: 'fit-content',
											}}
										>
											{section.columns?.map((col, i) => {
												return (
													<div key={i} className='w-50'>
														<h2 className='HeadlineTwoEmphasizedCentra text-left w-100 pb-2'>
															{col.columnName}
														</h2>
														<div
															className='d-flex flex-column justify-content-between pt-2'
															style={{ borderTop: '1px solid  #EAEAEA ' }}
														>
															<Board
																className={'Board d-flex flex-column pb-3'}
																index={{ col: i, sec: j }}
																id={i}
																Style={{
																	paddingTop: isDraggable && '1.2rem',
																	paddingBottom: isDraggable && '1.2rem',
																	gap: '0.7em',
																	height: '100%',
																}}
															>
																{col?.columnComponents?.map((item, i) => {
																	return (
																		<Card
																			key={i}
																			withinColumnIndex={i}
																			id={item.id}
																			className='card selected position-relative'
																			draggable={isDraggable && isDraggable}
																			clickHanlder={(e) => {
																				isDraggable &&
																					setSelectedComponent(item, e);
																			}}
																		>
																			{isDraggable && (
																				<span
																					className='deleteComponent pointer d-flex justify-content-end'
																					id={item.id + ' delete-component'}
																					style={{
																						position: 'absolute',
																						bottom: '100%',
																						right: '0',
																						width: 'fit-content',
																						display: 'none',
																						zIndex: 5,
																					}}
																				>
																					<h3
																						onClick={() => {
																							deleteItem(item);
																						}}
																						className='BodyTwoLight primary-text d-flex justify-content-end'
																					>
																						Delete
																					</h3>
																				</span>
																			)}
																			{!edit && col?.columnName == 'Links'
																				? item.value
																					.split(' ')
																					.map((link, index) => {
																						return (
																							<a
																								key={index}
																								className='BodyTwoLight primary-text text-left pointer'
																								href={link}
																								target='_blank'
																								rel='noreferrer'
																							>
																								{removeHttp(link)}
																							</a>
																						);
																					})
																				: React.createElement(
																					getValidComponent(
																						item.componentType,
																					),
																					{
																						onChange: isDraggable
																							? () => { }
																							: inputChangeHandler(
																								item,
																								workflow?.workflow_builder
																									?.data[0]?.componentName,
																								// eslint-disable-next-line no-mixed-spaces-and-tabs
																							),
																						Edit: isDraggable ? edit : edit,
																						edit: isDraggable ? edit : edit,
																						...item,
																					},
																				)}
																		</Card>
																	);
																})}
															</Board>
														</div>
													</div>
												);
											})}
										</div>
									)}
								</>
							);
						},
					)}
					{edit && (
						<div
							style={{ padding: '24px 20px 15px 0px' }}
							className='d-flex justify-content-end'
						>
							<div className='VerticalSpacer'>
								<SubmitButtonsField child={true}>
									<button
										type='submit'
										className='d-flex align-items-center'
										id='Element'
										disabled={loading}
										onClick={() => onActivate(workflow)}
									>
										{loading ? (
											<CircularProgress
												size={24}
												sx={{ color: 'white' }}
												disableShrink
											/>
										) : (
											'Activate'
										)}
									</button>
								</SubmitButtonsField>
							</div>
						</div>
					)}
				</div>
			)}
			<div className='' onClick={onClose} />
			<div>
				{modalIsOpen && (
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<>
							{ModalName == 'WORKFLOW_TASK' && (
								<DummyModal title={'Add Task'} onClose={toggleModal}>
									<WorkflowTaskModal
										workflow={FormProp}
										onCancel={() => {
											toggleModal();
										}}
										allWorkflowTasks={tasks}
										workflowID={workflow?.id}
										isWorkflowBrowser
									/>
								</DummyModal>
							)}
							{ModalName == 'DYNAMIC_MODAL' && (
								<DummyModal
									title={`Add ${selectedSection}`}
									onClose={toggleModal}
								>
									<DynamicModal
										workflow={FormProp}
										onCancel={() => {
											toggleModal();
										}}
										onSubmit={(
											workflowName,
											workflowSectionIndex,
											taskForm,
										) => {
											dispatch(
												builderActions.addNewTableWorkflowRow(
													workflowName,
													workflowSectionIndex,
													taskForm,
												),
											);
											toggleModal();
										}}
									/>
								</DummyModal>
							)}
							{ModalName == 'RECURRENCE_MODAL' && (
								<DummyModal
									title={'Update workflow recurrence'}
									onClose={toggleModal}
								>
									<RecurrenceModal
										isWorkflowBrowser
										onCancel={() => {
											toggleModal();
										}}
										recurrence={{
											id: workflow?.id,
											repeat_type: workflow?.repeat_type,
											repeat_interval: workflow?.repeat_interval,
											start_date: workflow?.start_date,
											is_monthly_on_day: workflow?.is_monthly_on_day || false,
											recur_preference: workflow?.recur_preference || null,
											end_on: workflow?.end_on,
											required_days: workflow?.required_days,
										}}
										onSubmit={recurrenceFormSubmit}
									/>
								</DummyModal>
							)}
							{ModalName == 'DELETE_TASK_ALERT' && (
								<DummyModal title={'Alert'} onClose={toggleModal}>
									<ConfirmModal
										description={
											'Deleting the task would delete its all dependenpt tasks as well. Are you sure you want to continue?'
										}
										OnCancel={() => {
											setToDeleteTask({});
											toggleModal();
										}}
										Onsubmit={() => {
											toggleModal();

											const array = [toDeleteTask?.taskId];
											setLoadingItems(array);
											deleteTask(toDeleteTask?.index, toDeleteTask?.taskId);
										}}
										descriptionClassName='text-left'
									/>
								</DummyModal>
							)}
						</>
					</Modal>
				)}
			</div>
		</div>
	);
}
