import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Member } from '../../../utilities/utilities';
import DropDown from '../../Inputs/DropDown';
import FormButtonOne from '../../Buttons/FormButtonOne';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function AddTeamMember({ newTeamMebers, onCancel, onSubmit, loading }) {
	const [assignee, setAssignee] = useState(null);
	const roles = useSelector((state) => state.userReducer.roles);

	const handleTeamMember = (event) => {
		const { value } = event.target;
		const newUser = newTeamMebers?.find((user) => user?.value == value);
		if (newUser?.role_id === Member.teamMemberId) {
			const newUserRoleId = newUser?.user_roles?.some(
				(role) => role?.role_id === Member.teamManagerId,
			)
				? Member?.teamManagerId
				: newUser?.role_id;
			setAssignee({
				...newUser,
				role_id: newUserRoleId,
				role_name: roles?.find((role) => role?.id == newUserRoleId)?.role_name,
			});
		} else {
			setAssignee({
				...newUser,
				role_name: roles?.find((role) => role?.id == newUser?.role_id)?.role_name,
			});
		}
	};

	return (
		<div className='new-modal' style={{ width: '450px' }}>
			<div className='new-modal-content p-3'>
				<div className='d-flex justify-content-between'>
					<span className='HeadlineTwoBold dark-text'>Add User</span>
					<IconButton className='CloseButton transparent' onClick={onCancel}>
						<CloseIcon sx={{ color: '#333' }} />
					</IconButton>
				</div>
				<div className='improved-input-designs'>
					<label className='require'>Name</label>
					<DropDown
						name={'user'}
						value={{ value: assignee?.value }}
						options={newTeamMebers}
						onChange={handleTeamMember}
					/>
				</div>
				<div className='improved-input-designs'>
					<label className=''>Email</label>
					<input name='email' type='email' value={assignee?.email} disabled />
				</div>
				<div className='improved-input-designs'>
					<label className=''>Role</label>
					<input name='role' type='text' value={assignee?.role_name} disabled />
				</div>
			</div>
			<div className='new-modal-footer rounded-bottom'>
				<FormButtonOne onClick={onCancel} type='button' className='secondary-btn-light'>
					Cancel
				</FormButtonOne>
				<FormButtonOne onClick={onSubmit(assignee)} type='button' loading={loading}>
					Add
				</FormButtonOne>
			</div>
		</div>
	);
}

export default AddTeamMember;
