import { secure_instance } from '../../../axios/axios-config';

export const GET_INTEGRATION = '[ACCOUNTING] GET_INTEGRATION';
export const GET_CUSTOMERS = '[ACCOUNTING] GET_CUSTOMERS';
export const CREATE_JOYBOI_INVOICE = '[ACCOUNTING] CREATE_JOYBOI_INVOICE';
export const DISCONNECT_INTEGRATION = '[ACCOUNTING] DISCONNECT_INTEGRATION';
export const UPDATE_INTEGRATION = '[ACCOUNTING] UPDATE_INTEGRATION';
export const CONNECT_INTEGRATION = '[ACCOUNTING] CONNECT_INTEGRATION';

// export const CREATE_CODAT_COMPANY = '[ACCOUNTING] CREATE_CODAT_COMPANY';
// export const GET_CODAT_COMPANY = '[ACCOUNTING] GET_CODAT_COMPANY';
// export const SYNC_CODAT = '[ACCOUNTING] SYNC_CODAT';



export const getIntegration = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/integrations/?category=Accounting&expand=connections',
				method: 'GET',
			});

			dispatch({
				type: GET_INTEGRATION,
				payload: request?.data,
			});

			console.log(request, 'request');
			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};


export const connectIntegration = (integrationId, redirectURL) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/connections/oauth?integration_id=${integrationId}&callback_url=${redirectURL}`,
				method: 'GET',
			});

			// dispatch({
			// 	type: CONNECT_INTEGRATION,
			// 	payload: request?.data,
			// });

			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const getCustomers = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/joyboi/v1/customers/`,
				method: 'GET',
			});

			dispatch({
				type: GET_CUSTOMERS,
				payload: request?.data,
			});

			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const createJoyboiInvoice = (customerId, invoiceId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/joyboi/v1/invoices?invoice_id=${invoiceId}&customer_id=${customerId}`,
				method: 'POST',
			});

			dispatch({
				type: CREATE_JOYBOI_INVOICE,
				payload: request?.data,
			});

			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};


export const disconnectIntegration = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/connections/${id}/revoke`,
				method: 'POST',
			});

			dispatch({
				type: DISCONNECT_INTEGRATION,
				payload: request?.data,
			});

			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const updateIntegration = (id) => {
    return ({
        type: UPDATE_INTEGRATION,
        payload: id
    });
};


// export const createCodatCompany = (data) => {
// 	return async (dispatch) => {
// 		try {
// 			const request = await secure_instance.request({
// 				url: '/v1/integrations/companies',
// 				method: 'POST',
// 				data: data,
// 			});

// 			dispatch({
// 				type: CREATE_CODAT_COMPANY,
// 				payload: request?.data,
// 			});

// 			return request?.data;
// 		} catch (e) {
// 			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
// 		}
// 	};
// };


// export const getCodatCompany = (companyId) => {
// 	return async (dispatch) => {
// 		try {
// 			const request = await secure_instance.request({
// 				url: `/v1/integrations/companies/${companyId}`,
// 				method: 'GET',
// 			});

// 			dispatch({
// 				type: GET_CODAT_COMPANY,
// 				payload: request?.data,
// 			});

// 			return request?.data;
// 		} catch (e) {
// 			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
// 		}
// 	};
// };

// export const syncCodat = (companyId, data) => {
// 	return async (dispatch) => {
// 		try {
// 			const request = await secure_instance.request({
// 				url: `v1/integrations/companies/${companyId}/data/queue/customers`,
// 				method: 'POST',
// 				data: data,
// 			});

// 			dispatch({
// 				type: SYNC_CODAT,
// 				payload: request?.data,
// 			});

// 			return request?.data;
// 		} catch (e) {
// 			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
// 		}
// 	};
// };
