import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as authActions from '../redux/actions/auth-actions';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { convertUnixDate } from '../utilities/utilities';
import * as companyActions from '../redux/actions/company-profile';

const ProtectedRoute = ({ comp: Component, ...rest }) => {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.authReducer.user);
	const isTenantSubscribed = useSelector((state) => state.authReducer.isTenantSubscribed);
	const subscriptions = useSelector((state) => state.authReducer.subscriptions);

	const [subscriptionFetched, setSubscriptionFetched] = useState(false);

	useEffect(() => {
		const checkSubscription = async () => {
			if (subscriptions) {
				const expirationDate = convertUnixDate(subscriptions?.expires_at);
				const subscriptionEndDate = moment(expirationDate)
					.add(14, 'days')
					.format('MMMM D, YYYY');
				const currentDate = moment().format('MMMM D, YYYY');

				if (moment(currentDate).isAfter(subscriptionEndDate)) {
					dispatch(authActions.handleSubscription(false));
				}
			}
			setSubscriptionFetched(true);
		};

		!user?.accessToken && dispatch(authActions.refreshToken());
		checkSubscription();
	}, [dispatch, subscriptions, user?.accessToken]);

	useEffect(() => {
		dispatch(authActions.setActionCenterModal(true));

		return () => {
			dispatch(authActions.setActionCenterModal(false));
		};
	}, []);

	useEffect(() => {
		let interval = undefined;
		if (user?.accessToken) {
			dispatch(companyActions.getTenantProfile(user?.tenantId));
			dispatch(authActions.setUserActivity('online', moment()));
			dispatch(authActions.getUserActivity());
			dispatch(authActions.getSubscription());
			dispatch(authActions.getStripePricings());
			interval = setInterval(() => {
				dispatch(authActions.setUserActivity('online', moment()));
			}, 80000);
		}

		return () => {
			clearInterval(interval);
		};
	}, [dispatch, user?.accessToken]);

	if (!subscriptionFetched || !user?.accessToken) {
		return (
			<div
				style={{
					height: '100vh',
					width: '100vw',
				}}
				className=' d-flex justify-content-center align-items-center'
			>
				<CircularProgress
					style={{
						color: '#fa7b03',
						height: '50px',
						width: '50px',
					}}
				/>
			</div>
		);
	}

	return (
		<>
			<Route
				{...rest}
				render={(props) => {
					if (
						(isTenantSubscribed != null && !isTenantSubscribed) ||
						subscriptions?.status == 'canceled' ||
						subscriptions?.status == 'unpaid'
					) {
						if (user?.userRole?.id == 16) {
							return <Redirect to='/company-profile' />;
						} else {
							return <Redirect to='/restricted' />;
						}
					} else {
						return <Component {...props} />;
					}
				}}
			/>
		</>
	);
};

export default ProtectedRoute;
