import { CircularProgress } from '@mui/material';

function FormButtonOne({ loading, children, className = 'primary-btn', ...props }) {
	return (
		<button className={className} disabled={loading || props.disabled} {...props}>
			{loading ? (
				<CircularProgress size='1.5em' sx={{ color: 'white' }} disableShrink />
			) : (
				children
			)}
		</button>
	);
}

export default FormButtonOne;
