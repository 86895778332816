import EditableText from '../components/Inputs/EditableText';
import EditableTextArea from '../components/Inputs/EditableTextArea';
import { EditableDropdown } from '../components/Inputs/EditableDropDown';
import EditableRadioButton from '../components/Inputs/EditableRadioButton';
import EditablePickList from '../components/Inputs/EditablePickList';
import EditableSelectedGroup from '../components/Inputs/EditableSelectGroup';
import EnchorText from '../components/Inputs/EnchorText';
import RichTextEditor from '../components/Inputs/RichTextEditor';
import WorkflowAccountsPickList from '../components/Inputs/WorkflowAccountsPickList';

export const getValidComponent = (type) => {
	switch (type) {
		case 'input':
			return EditableText;
		case 'textarea':
			return RichTextEditor;
		case 'dropdown':
			return EditableDropdown;
		case 'radio':
			return EditableRadioButton;
		case 'select-group':
			return EditableSelectedGroup;
		case 'datepicker':
			return EditableText;
		case 'pick-list':
			return EditablePickList;
		case 'links-pick-list':
			return EditablePickList;
		case 'contact-pick-list':
			return EditablePickList;
		case 'state-pick-list':
			return EditablePickList;
		case 'software-pick-list':
			return EditablePickList;
		case 'merchant-pick-list':
			return EditablePickList;
		case 'portal-pick-list':
			return EditablePickList;
		case 'card-pick-list':
			return EditablePickList;
		case 'account-pick-list':
			return EditablePickList;
		case 'location-pick-list':
			return EditablePickList;
		case 'anchor-text':
			return EnchorText;
		default:
			return;
	}
};

export const getNewValidComponent = (type) => {
	switch (type) {
		case 'pick-list':
			return WorkflowAccountsPickList;
		case 'software-pick-list':
			return WorkflowAccountsPickList;
		case 'merchant-pick-list':
			return WorkflowAccountsPickList;
		case 'portal-pick-list':
			return WorkflowAccountsPickList;
		case 'card-pick-list':
			return WorkflowAccountsPickList;
		case 'account-pick-list':
			return WorkflowAccountsPickList;
		case 'contact-pick-list':
			return WorkflowAccountsPickList;
		case 'links-pick-list':
			return WorkflowAccountsPickList;
		default:
			return null;
	}
};

export function push(arr, val, index) {
	return index >= arr.length
		? arr.concat(val)
		: arr.reduce((prev, x, i) => prev.concat(i === index ? [val, x] : x), []);
}

export function updateWithinColumnIndex(array) {
	return array?.map((item, i) => {
		return {
			...item,
			propsTypes: {
				...item.propsTypes,
				withinColumnIndex: i,
			},
		};
	});
}

export const datePickerJson = {
	componentType: 'datepicker',
	label: 'Date',
	name: '',
	value: '16-02-2022',
	type: 'date',
	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'input',
				name: 'label',
				value: '',
				label: 'Label',
				placeholder: 'Label',
				type: 'text',
			},
		],
	},
};

export const inputJson = {
	componentType: 'input',
	name: '',
	label: 'First Name',
	placeholder: 'Enter First Name',
	type: 'text',
	value: '',

	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'input',
				name: 'label',
				value: '',
				label: '',
				placeholder: 'Label',
				type: 'text',
			},
			{
				componentType: 'input',
				name: 'placeholder',
				value: '',
				label: '',
				placeholder: 'Placeholder',
				type: 'text',
			},
		],
	},
};

export const textareaJson = {
	componentType: 'textarea',
	name: '',
	label: 'Description',
	placeholder: 'Enter First Name',
	type: 'text',
	value: '',
	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'input',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: '',
			},
			{
				componentType: 'input',
				name: 'placeholder',
				label: '',
				placeholder: 'Placeholder',
				type: 'text',
				value: '',
			},
		],
	},
};

export const dropdownJson = {
	componentType: 'dropdown',
	label: 'Label',
	name: '',
	value: { value: 'Three' },
	options: [
		{ value: 'One', label: 'One' },
		{ value: 'Two', label: 'Two' },
		{ value: 'Three', label: 'Three' },
	],

	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'dropdown',
				name: 'label',
				label: 'Label',
				placeholder: 'Label',
				type: 'text',
				value: '',
			},
			{
				componentType: 'dropdown',
				name: 'options',
				label: '',
				placeholder: 'options',
				type: 'text',
				value: [
					{ value: 'One', label: 'One' },
					{ value: 'Two', label: 'Two' },
					{ value: 'Three', label: 'Three' },
				],
			},
		],
	},
};

export const radioJson = {
	componentType: 'radio',
	label: 'Label',
	name: '',
	value: '',
	options: [
		{
			id: 'Yes',
			value: 'Yes',
		},
		{
			id: 'No',
			value: 'No',
		},
	],

	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'radio',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: '',
			},
		],
	},
};

export const selectGroupJson = {
	componentType: 'select-group',
	label: 'Label',
	name: '',
	value: ['One', 'Two'],
	options: [
		{ value: 'One', label: 'One' },
		{ value: 'Two', label: 'Two' },
		{ value: 'Three', label: 'Three' },
	],

	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'select-group',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: '',
			},
			{
				componentType: 'select-group',
				name: 'options',
				label: '',
				placeholder: 'options',
				type: 'text',
				value: [
					{ value: 'One', label: 'One' },
					{ value: 'Two', label: 'Two' },
					{ value: 'Three', label: 'Three' },
				],
			},
		],
	},
};

export const pickList = {
	componentType: 'pick-list',
	label: 'Label',
	name: '',
	value: ['One'],
	options: [
		{ value: 'One', label: 'One' },
		{ value: 'Two', label: 'Two' },
		{ value: 'Three', label: 'Three' },
	],

	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'pick-list',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: '',
			},
			{
				componentType: 'pick-list',
				name: 'options',
				label: '',
				placeholder: 'options',
				type: 'text',
				value: [
					{ value: 'One', label: 'One' },
					{ value: 'Two', label: 'Two' },
					{ value: 'Three', label: 'Three' },
				],
			},
		],
	},
};

export const statePickList = {
	componentType: 'state-pick-list',
	label: 'states',
	name: '',
	value: [],
	options: [],
	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'select-group',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: 'states',
			},
		],
	},
};

export const contactPickList = {
	componentType: 'contact-pick-list',
	label: 'contacts',
	name: '',
	value: [],
	options: [],
	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'select-group',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: 'contacts',
			},
		],
	},
};

export const accountPickList = {
	componentType: 'account-pick-list',
	label: 'accounts',
	name: '',
	value: [],
	options: [],
	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'select-group',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: 'accounts',
			},
		],
	},
};

export const softwarePickList = {
	componentType: 'software-pick-list',
	label: 'softwares',
	name: '',
	value: [],
	options: [],
	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'select-group',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: 'softwares',
			},
		],
	},
};

export const creditCardPickList = {
	componentType: 'card-pick-list',
	label: 'credit cards',
	name: '',
	value: [],
	options: [],
	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'select-group',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: 'credit cards',
			},
		],
	},
};

export const merchantPickList = {
	componentType: 'merchant-pick-list',
	label: 'merchants',
	name: '',
	value: [],
	options: [],
	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'select-group',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: 'merchants',
			},
		],
	},
};

export const portalPickList = {
	componentType: 'portal-pick-list',
	label: 'portals',
	name: '',
	value: [],
	options: [],
	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'select-group',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: 'portals',
			},
		],
	},
};

export const locationPickList = {
	componentType: 'location-pick-list',
	label: 'locations',
	name: '',
	value: [],
	options: [],
	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'select-group',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: 'locations',
			},
		],
	},
};

export const linksPickList = {
	componentType: 'links-pick-list',
	label: 'links',
	name: '',
	value: [],
	options: [],
	propsTypes: {
		withinColumnIndex: 0,
		propInputTypes: [
			{
				componentType: 'select-group',
				name: 'label',
				label: '',
				placeholder: 'Label',
				type: 'text',
				value: 'links',
			},
		],
	},
};

// Default Workflow Template
export const defaultTemplate = {
	componentName: 'WORKFLOW_NAME',
	displayName: 'Workflow Name',
	props: {
		sections: [
			{
				sectionName: 'WORKFLOW_INFORMATION',
				componentName: 'Workflow Information',
				componentType: 'COLUMN',
				columns: [
					{
						columnName: 'Software',
						columnComponents: [
							{
								componentType: 'software-pick-list',
								label: 'Software',
								name: '',
								value: [],
								options: [],
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'pick-list',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'Software',
										},
										{
											componentType: 'pick-list',
											name: 'options',
											label: '',
											placeholder: 'options',
											type: 'text',
											value: [],
										},
									],
								},
								id: 0.87683645567,
								sectionIndex: 0,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Accounts',
						columnComponents: [
							{
								componentType: 'account-pick-list',
								label: 'Accounts',
								name: '',
								value: [],
								options: [],
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'pick-list',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'Accounts',
										},
										{
											componentType: 'pick-list',
											name: 'options',
											label: '',
											placeholder: 'options',
											type: 'text',
											value: [],
										},
									],
								},
								id: 0.876836,
								sectionIndex: 0,
								columnIndex: 1,
							},
						],
					},
					{
						columnName: 'Contacts',
						columnComponents: [
							{
								componentType: 'contact-pick-list',
								label: 'client contacts',
								name: '',
								value: [],
								options: [],
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'pick-list',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'client contacts',
										},
										{
											componentType: 'pick-list',
											name: 'options',
											label: '',
											placeholder: 'options',
											type: 'text',
											value: [],
										},
									],
								},
								id: 0.1456,
								sectionIndex: 0,
								columnIndex: 2,
							},
						],
					},
					{
						columnName: 'Links',
						columnComponents: [
							{
								componentType: 'links-pick-list',
								label: 'Links',
								name: '',
								value: [],
								options: [],
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'pick-list',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'Links',
										},
										{
											componentType: 'pick-list',
											name: 'options',
											label: '',
											placeholder: 'options',
											type: 'text',
											value: [],
										},
									],
								},
								id: 12.456,
								sectionIndex: 0,
								columnIndex: 3,
							},
						],
					},
					{
						columnName: 'locations',
						columnComponents: [
							{
								componentType: 'location-pick-list',
								label: 'locations',
								name: '',
								value: [],
								options: [],
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'pick-list',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'locations',
										},
									],
								},
								id: 454.67,
								sectionIndex: 0,
								columnIndex: 4,
							},
						],
					},
					{
						columnName: 'Credit Card Account',
						columnComponents: [
							{
								componentType: 'card-pick-list',
								label: 'Credit Card Account',
								name: '',
								value: [],
								options: [],
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'pick-list',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'Credit Card Account',
										},
										{
											componentType: 'pick-list',
											name: 'options',
											label: '',
											placeholder: 'options',
											type: 'text',
											value: [],
										},
									],
								},
								id: Math.random(),
								sectionIndex: 0,
								columnIndex: 5,
							},
						],
					},
					{
						columnName: 'Other Portals',
						columnComponents: [
							{
								componentType: 'portal-pick-list',
								label: 'Other Portals',
								name: '',
								value: [],
								options: [],
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'pick-list',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'Other Portals',
										},
										{
											componentType: 'pick-list',
											name: 'options',
											label: '',
											placeholder: 'options',
											type: 'text',
											value: [],
										},
									],
								},
								id: Math.random(),
								sectionIndex: 0,
								columnIndex: 6,
							},
						],
					},
					{
						columnName: 'Merchant Account',
						columnComponents: [
							{
								componentType: 'merchant-pick-list',
								label: 'Merchant Account',
								name: '',
								value: [],
								options: [],
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'pick-list',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'Merchant Account',
										},
										{
											componentType: 'pick-list',
											name: 'options',
											label: '',
											placeholder: 'options',
											type: 'text',
											value: [],
										},
									],
								},
								id: Math.random(),
								sectionIndex: 0,
								columnIndex: 7,
							},
						],
					},
				],
			},
			{
				sectionName: 'LINKSSECTION',
				componentName: 'Links',
				componentType: 'TABLE',
				tableHeaders: [
					{
						name: 'Name',
					},
					{
						name: 'Account Document Storage',
					},
					{
						name: 'login',
					},
					{
						name: 'Notes',
					},
				],
				tableRows: [],
			},
			{
				sectionName: 'NOTES',
				componentName: 'Notes',
				componentType: 'COLUMN',
				columns: [
					{
						columnName: '',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: '',
								placeholder: 'Enter',
								type: 'text',
								value: '',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: '',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 54225.74,
								sectionIndex: 2,
								columnIndex: 0,
							},
						],
					},
				],
			},
			{
				sectionName: 'WORKFLOW_NAME',
				componentName: 'Workflow: Name',
				componentType: 'FILLED_TABLE',
				tableHeaders: [
					{
						name: 'Task',
					},
					{
						name: 'Description',
					},
					{
						name: 'Budget',
					},
					{
						name: 'Assignee',
					},
					{
						name: 'Dependency',
					},
					{
						name: 'Days to Complete',
					},
				],
			},
		],
	},
	propsTypes: [],
};

// Workflows

export const PAY_ROLL = {
	componentName: 'PAY_ROLL',
	displayName: 'Pay Roll',
	props: {
		sections: [
			{
				sectionName: 'PAYROLL_INFORMATION',
				componentName: 'Payroll Information',
				componentType: 'COLUMN',
				columns: [
					{
						columnName: 'Details',
						columnComponents: [
							{
								componentType: 'radio',
								label: 'PAYROLL ACCOUNT',
								name: '',
								value: 'No',
								options: [
									{
										id: 'Yes',
										value: 'Yes',
									},
									{
										id: 'No',
										value: 'No',
									},
								],
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'radio',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'PAYROLL ACCOUNT',
										},
									],
								},
								id: 1.76458893,
								sectionIndex: 0,
								columnIndex: 0,
								workflowName: 'PAY_ROLL',
							},
							{
								componentType: 'pick-list',
								label: 'Software',
								name: '',
								value: [1, 2],
								options: [
									{
										value: 1,
										label: 'Quick books',
									},
									{
										value: 2,
										label: 'Fresh books',
									},
									{
										value: 3,
										label: 'Waves',
									},
								],
								propsTypes: {
									withinColumnIndex: 1,
									propInputTypes: [
										{
											componentType: 'pick-list',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'Software',
										},
										{
											componentType: 'pick-list',
											name: 'options',
											label: '',
											placeholder: 'options',
											type: 'text',
											value: [
												{
													value: 1,
													label: 'Quick books',
												},
												{
													value: 2,
													label: 'Fresh books',
												},
												{
													value: 3,
													label: 'Waves',
												},
											],
										},
									],
								},
								id: 0.876836477195567,
								sectionIndex: 0,
								columnIndex: 0,
							},
							{
								componentType: 'dropdown',
								label: 'pay frequency',
								name: '',
								value: {
									value: 'Three',
									label: 'Three',
								},
								options: [
									{
										value: 'One',
										label: 'One',
									},
									{
										value: 'Two',
										label: 'Two',
									},
									{
										value: 'Three',
										label: 'Three',
									},
								],
								propsTypes: {
									withinColumnIndex: 2,
									propInputTypes: [
										{
											componentType: 'dropdown',
											name: 'label',
											label: 'Label',
											placeholder: 'Label',
											type: 'text',
											value: 'pay frequency',
										},
										{
											componentType: 'dropdown',
											name: 'options',
											label: '',
											placeholder: 'options',
											type: 'text',
											value: [
												{
													value: 'One',
													label: 'One',
												},
												{
													value: 'Two',
													label: 'Two',
												},
												{
													value: 'Three',
													label: 'Three',
												},
											],
										},
									],
								},
								id: 0.6564340918201819,
								sectionIndex: 0,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Contacts',
						columnComponents: [
							{
								componentType: 'pick-list',
								label: 'client payroll Contact',
								name: '',
								value: [1, 2],
								options: [],
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'pick-list',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'client payroll Contact',
										},
										{
											componentType: 'pick-list',
											name: 'options',
											label: '',
											placeholder: 'options',
											type: 'text',
											value: [],
										},
									],
								},
								id: 0.8768367,
								sectionIndex: 0,
								columnIndex: 1,
							},
						],
					},
					{
						columnName: 'Employees',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'salaried employees',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'salaried employees',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 0.19618091137949145,
								sectionIndex: 0,
								columnIndex: 2,
							},
							{
								componentType: 'select-group',
								label: 'states employees work in',
								name: '',
								value: ['AL', 'NY'],
								options: [
									{
										value: 'AL',
										label: 'AL',
									},
									{
										value: 'AK',
										label: 'AK',
									},
									{
										value: 'AZ',
										label: 'AZ',
									},
									{
										value: 'NY',
										label: 'NY',
									},
								],
								propsTypes: {
									withinColumnIndex: 1,
									propInputTypes: [
										{
											componentType: 'select-group',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'states employees work in',
										},
										{
											componentType: 'select-group',
											name: 'options',
											label: '',
											placeholder: 'options',
											type: 'text',
											value: [
												{
													value: 'AL',
													label: 'AL',
												},
												{
													value: 'AK',
													label: 'AK',
												},
												{
													value: 'AZ',
													label: 'AZ',
												},
												{
													value: 'NY',
													label: 'NY',
												},
											],
										},
									],
								},
								id: 0.6937506827123561,
								sectionIndex: 0,
								columnIndex: 2,
							},
						],
					},
					{
						columnName: 'PTO',
						columnComponents: [
							{
								componentType: 'radio',
								label: 'pto policy',
								name: '',
								value: 'No',
								options: [
									{
										id: 'Yes',
										value: 'Yes',
									},
									{
										id: 'No',
										value: 'No',
									},
								],
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'radio',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'pto policy',
										},
									],
								},
								id: 0.76,
								sectionIndex: 0,
								columnIndex: 3,
								workflowName: 'PAY_ROLL',
							},
							{
								componentType: 'input',
								name: '',
								label: 'time tracked by',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 1,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'time tracked by',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 0.1145,
								sectionIndex: 0,
								columnIndex: 3,
							},
							{
								componentType: 'input',
								name: '',
								label: 'policy details',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 2,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'policy details',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 0.19145,
								sectionIndex: 0,
								columnIndex: 3,
							},
						],
					},
				],
			},
			{
				sectionName: 'PAYROLL_COMPANY_CONTACTS',
				componentName: 'Payroll Company Contacts',
				componentType: 'TABLE',
				tableHeaders: [
					{
						name: 'First Name',
					},
					{
						name: 'Last Name',
					},
					{
						name: 'Title',
					},
					{
						name: 'Email',
					},
					{
						name: 'Phone',
					},
				],
				tableRows: [],
			},
			{
				sectionName: 'WORKFLOW_PAYROLL',
				componentName: 'Workflow Payroll',
				componentType: 'FILLED_TABLE',
				tableHeaders: [
					{
						name: 'Task',
					},
					{
						name: 'Description',
					},
					{
						name: 'Budget',
					},
					{
						name: 'Assignee',
					},
					{
						name: 'Dependency',
					},
				],
			},
		],
	},
	propsTypes: [],
};
export const ACCOUNT_PAYABLE = {
	componentName: 'AP',
	displayName: 'Account Payable',
	props: {
		sections: [
			{
				sectionName: 'AP_INFORMATION',
				componentName: 'AP Information',
				componentType: 'COLUMN',
				columns: [
					{
						columnName: 'Contacts',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'ap contract(s)',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'ap contract(s)',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 0.145,
								sectionIndex: 0,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Banking',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'bank account',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'bank account',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 0.1961809119145,
								sectionIndex: 0,
								columnIndex: 1,
							},
						],
					},
				],
			},
			{
				sectionName: '',
				componentName: '',
				componentType: 'COLUMN_TWO',
				columns: [
					{
						columnName: 'Notes',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: '',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: '',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 0.354,
								sectionIndex: 1,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Links',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: 'AP DOCUMENT STORAGE',
								placeholder: 'Enter ',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'AP DOCUMENT STORAGE',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 895.316,
								sectionIndex: 1,
								columnIndex: 1,
							},
						],
					},
				],
			},
			{
				sectionName: 'WORKFLOW_ACCOUNTS_PAYABLE',
				componentName: 'Workflow: Accounts Payable',
				componentType: 'FILLED_TABLE',
				tableHeaders: [
					{
						name: 'Task',
					},
					{
						name: 'Description',
					},
					{
						name: 'Budget',
					},
					{
						name: 'Assignee',
					},
					{
						name: 'Dependency',
					},
				],
			},
		],
	},
	propsTypes: [],
};
export const ACCOUNT_RECEIVABLE = {
	componentName: 'AR',
	displayName: 'Account Receivable',
	props: {
		sections: [
			{
				sectionName: 'AR_INFORMATION',
				componentName: 'AR Information',
				componentType: 'COLUMN',
				columns: [
					{
						columnName: 'Contacts',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'ar contract(s)',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'ar contract(s)',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 0.19618,
								sectionIndex: 0,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Invoicing',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'accounting system',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'accounting system',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 0.1180911,
								sectionIndex: 0,
								columnIndex: 1,
							},
						],
					},
				],
			},
			{
				sectionName: '',
				componentName: '',
				componentType: 'COLUMN_TWO',
				columns: [
					{
						columnName: 'Notes',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: '',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: '',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 0.398954,
								sectionIndex: 1,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Links',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: 'AR DOCUMENT STORAGE',
								placeholder: 'Enter ',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'AR DOCUMENT STORAGE',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 895.31664,
								sectionIndex: 1,
								columnIndex: 1,
							},
						],
					},
				],
			},
			{
				sectionName: 'WORKFLOW_ACCOUNTS_RECEIVABLE',
				componentName: 'Workflow: Accounts Receivable',
				componentType: 'FILLED_TABLE',
				tableHeaders: [
					{
						name: 'Task',
					},
					{
						name: 'Description',
					},
					{
						name: 'Budget',
					},
					{
						name: 'Assignee',
					},
					{
						name: 'Dependency',
					},
				],
			},
		],
	},
	propsTypes: [],
};
export const SALES_INVENTORY = {
	componentName: 'SALES_INVENTORY',
	displayName: 'Sales & Inventory',
	props: {
		sections: [
			{
				sectionName: 'SALES_INVENTORY_INFORMATION',
				componentName: 'Sales & Inventory Information',
				componentType: 'COLUMN',
				columns: [
					{
						columnName: 'Contacts',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'inventory manager(s)',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'inventory manager(s)',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 7.196180911,
								sectionIndex: 0,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Accounts',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'ecommerce',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'ecommerce',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 77.196180911,
								sectionIndex: 0,
								columnIndex: 1,
							},
							{
								componentType: 'input',
								name: '',
								label: 'merchant',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 1,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'merchant',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 9.191,
								sectionIndex: 0,
								columnIndex: 1,
							},
						],
					},
				],
			},
			{
				sectionName: '',
				componentName: '',
				componentType: 'COLUMN_TWO',
				columns: [
					{
						columnName: 'Notes',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: '',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: '',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 785.3985254,
								sectionIndex: 1,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Links',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: 'S&I DOCUMENT STORAGE',
								placeholder: 'Enter ',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'S&I DOCUMENT STORAGE',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 836.3686864,
								sectionIndex: 1,
								columnIndex: 1,
							},
						],
					},
				],
			},
			{
				sectionName: 'WORKFLOW_SALES_&_INVENTORY',
				componentName: 'Workflow: Sales & Inventory',
				componentType: 'FILLED_TABLE',
				tableHeaders: [
					{
						name: 'Task',
					},
					{
						name: 'Description',
					},
					{
						name: 'Budget',
					},
					{
						name: 'Assignee',
					},
					{
						name: 'Dependency',
					},
				],
			},
		],
	},
	propsTypes: [],
};
export const SALES_TAX = {
	componentName: 'SALES_TAX',
	displayName: 'Sales Tax',
	props: {
		sections: [
			{
				sectionName: 'SALES_TAX_INFORMATION',
				componentName: 'Sales Tax Information',
				componentType: 'COLUMN',
				columns: [
					{
						columnName: 'Filing',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'Sales tax filer',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'Sales tax filer',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 91.191,
								sectionIndex: 0,
								columnIndex: 0,
							},
						],
					},
				],
			},
			{
				sectionName: '',
				componentName: '',
				componentType: 'COLUMN_TWO',
				columns: [
					{
						columnName: 'Notes',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: '',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: '',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 5.3957714,
								sectionIndex: 2,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Links',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: 'SALES TAX DOCUMENT STORAGE',
								placeholder: 'Enter ',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'SALES TAX DOCUMENT STORAGE',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 86.5834,
								sectionIndex: 2,
								columnIndex: 1,
							},
						],
					},
				],
			},
			{
				sectionName: 'WORKFLOW_SALES_TAX',
				componentName: 'Workflow: Sales Tax',
				componentType: 'FILLED_TABLE',
				tableHeaders: [
					{
						name: 'Task',
					},
					{
						name: 'Description',
					},
					{
						name: 'Budget',
					},
					{
						name: 'Assignee',
					},
					{
						name: 'Dependency',
					},
				],
				tableRows: [],
			},
		],
	},
	propsTypes: [],
};
export const TAX = {
	componentName: 'TAX',
	displayName: 'Tax',
	props: {
		sections: [
			{
				sectionName: 'TAX_INFORMATION',
				componentName: 'Tax Information',
				componentType: 'COLUMN',
				columns: [
					{
						columnName: 'Federal',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'last filed',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'last filed',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 9.100091,
								sectionIndex: 0,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'State',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'last file state/year',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'last file state    /year',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 9.191,
								sectionIndex: 0,
								columnIndex: 1,
							},
						],
					},
					{
						columnName: 'Local',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'last file locality/year',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'last file locality/year',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 900.191,
								sectionIndex: 0,
								columnIndex: 2,
							},
						],
					},
					{
						columnName: 'Filling',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: '1099 filer',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: '1099 filer',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 9.111191,
								sectionIndex: 0,
								columnIndex: 3,
							},
						],
					},
				],
			},
			{
				sectionName: 'TAX_ACCOUNTANTS',
				componentName: 'Tax Accountants',
				componentType: 'TABLE',
				tableHeaders: [
					{
						name: 'First Name',
					},
					{
						name: 'Last Name',
					},
					{
						name: 'Title',
					},
					{
						name: 'Email',
					},
					{
						name: 'Phone',
					},
				],
				tableRows: [],
			},
			{
				sectionName: '',
				componentName: '',
				componentType: 'COLUMN_TWO',
				columns: [
					{
						columnName: 'Notes',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: '',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: '',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 5.7,
								sectionIndex: 2,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Links',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: 'TAX DOCUMENT STORAGE',
								placeholder: 'Enter ',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'TAX DOCUMENT STORAGE',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 6.583,
								sectionIndex: 2,
								columnIndex: 1,
							},
						],
					},
				],
			},
			{
				sectionName: 'WORKFLOW_PROCESSING',
				componentName: 'Workflow: 1099-1096 Processing',
				componentType: 'FILLED_TABLE',
				tableHeaders: [
					{
						name: 'Task',
					},
					{
						name: 'Description',
					},
					{
						name: 'Budget',
					},
					{
						name: 'Assignee',
					},
					{
						name: 'Dependency',
					},
				],
				tableRows: [],
			},
		],
	},
	propsTypes: [],
};
export const INSURANCE = {
	componentName: 'INSURANCE',
	displayName: 'Insurance',
	props: {
		sections: [
			{
				sectionName: 'BUSINESS_ACCOUNTANTS',
				componentName: 'Business Accountants',
				componentType: 'TABLE',
				tableHeaders: [
					{
						name: 'Carrier',
					},
					{
						name: 'Type',
					},
					{
						name: 'Policy Number',
					},
					{
						name: 'Renewal Date',
					},
				],
				tableRows: [],
			},
			{
				sectionName: 'WORKERS_COMP_DISABILITY',
				componentName: 'Workers Comp & Disability',
				componentType: 'TABLE',
				tableHeaders: [
					{
						name: 'State',
					},
					{
						name: 'Carrier',
					},
					{
						name: 'No. of Employees',
					},
					{
						name: 'Add. Cov. Amount',
					},
				],
				tableRows: [],
			},
			{
				sectionName: 'INSURANCE_BROKERS',
				componentName: 'Insurance Brokers',
				componentType: 'TABLE',
				tableHeaders: [
					{
						name: 'First Name',
					},
					{
						name: 'Last Name',
					},
					{
						name: 'Title',
					},
					{
						name: 'Email',
					},
					{
						name: 'Phone',
					},
				],
				tableRows: [],
			},
			{
				sectionName: '',
				componentName: '',
				componentType: 'COLUMN_TWO',
				columns: [
					{
						columnName: 'Notes',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: '',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: '',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 475.587,
								sectionIndex: 3,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Links',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: 'INSURANCE DOCUMENT STORAGE',
								placeholder: 'Enter ',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'INSURANCE DOCUMENT STORAGE',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 647.54783,
								sectionIndex: 3,
								columnIndex: 1,
							},
						],
					},
					{
						sectionName: 'WORKFLOW_INSURANCE',
						componentName: 'Workflow: Insurance',
						componentType: 'FILLED_TABLE',
						tableHeaders: [
							{
								name: 'Task',
							},
							{
								name: 'Description',
							},
							{
								name: 'Budget',
							},
							{
								name: 'Assignee',
							},
							{
								name: 'Dependency',
							},
						],
						tableRows: [],
					},
				],
			},
		],
	},
	propsTypes: [],
};
export const HR = {
	componentName: 'HR',
	displayName: 'HR',
	props: {
		sections: [
			{
				sectionName: 'INSURANCE_INFORMATION',
				componentName: 'Insurance Information',
				componentType: 'COLUMN',
				columns: [
					{
						columnName: 'Contacts',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'PRIMARY HR CONTACT',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'PRIMARY HR CONTACT',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 9456.191,
								sectionIndex: 0,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: '401K',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'ELIGIBILITY DATE',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'ELIGIBILITY DATE',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 9.1900000981,
								sectionIndex: 0,
								columnIndex: 1,
							},
							{
								componentType: 'input',
								name: '',
								label: 'EMPLOYER MATCH',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 1,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'EMPLOYER MATCH',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 9.19188888,
								sectionIndex: 0,
								columnIndex: 1,
							},
						],
					},
					{
						columnName: 'Employee/Employer Cost Share',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'WAITING PERIOD',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'WAITING PERIOD',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 9.194321,
								sectionIndex: 0,
								columnIndex: 2,
							},
							{
								componentType: 'input',
								name: '',
								label: 'OPEN ENROLLMENT',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 1,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'OPEN ENROLLMENT',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 955.1954345341,
								sectionIndex: 0,
								columnIndex: 2,
							},
						],
					},
					{
						columnName: 'Additional Plans',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'CAFETERIA/POP PLAN',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'CAFETERIA/POP PLAN',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: 'Enter',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 9.1954331,
								sectionIndex: 0,
								columnIndex: 3,
							},
						],
					},
				],
			},
			{
				sectionName: 'HEALTH_INSURANCE',
				componentName: 'Health Insurance',
				componentType: 'TABLE',
				tableHeaders: [
					{
						name: 'Carrier',
					},
					{
						name: 'Type',
					},
					{
						name: 'EE Coverage',
					},
					{
						name: 'Dep. Coverage',
					},
					{
						name: 'Renewal Date',
					},
				],
				tableRows: [],
			},
			{
				sectionName: 'BENEFITS_BROKERS',
				componentName: 'Benefits Brokers',
				componentType: 'TABLE',
				tableHeaders: [
					{
						name: 'First Name',
					},
					{
						name: 'Last Name',
					},
					{
						name: 'Title',
					},
					{
						name: 'Email',
					},
					{
						name: 'Phone',
					},
				],
				tableRows: [],
			},
			{
				sectionName: '',
				componentName: '',
				componentType: 'COLUMN_TWO',
				columns: [
					{
						columnName: 'Notes',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: '',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: '',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 75.5285585887,
								sectionIndex: 3,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Links',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: 'HR DOCUMENT STORAGE',
								placeholder: 'Enter ',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'HR DOCUMENT STORAGE',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 647.75858783,
								sectionIndex: 3,
								columnIndex: 1,
							},
						],
					},
				],
			},
			{
				sectionName: 'WORKFLOW_HR',
				componentName: 'Workflow: HR',
				componentType: 'FILLED_TABLE',
				tableHeaders: [
					{
						name: 'Task',
					},
					{
						name: 'Description',
					},
					{
						name: 'Budget',
					},
					{
						name: 'Assignee',
					},
					{
						name: 'Dependency',
					},
				],
				tableRows: [],
			},
		],
	},
	propsTypes: [],
};

export const MONTHLY_CLOSE = {
	componentName: 'MONTHLY_CLOSE',
	displayName: 'Monthly Close',
	props: {
		sections: [
			{
				sectionName: 'MONTH_CLOSE_INFORMATION',
				componentName: 'Month Close Information',
				componentType: 'COLUMN',
				columns: [
					{
						columnName: 'Details',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'Software',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'Software',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: '',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 321.1145,
								sectionIndex: 0,
								columnIndex: 0,
							},
							{
								componentType: 'input',
								name: '',
								label: 'WEEK OF MONTH CLOSE',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 1,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'WEEK OF MONTH CLOSE',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: '',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 43332,
								sectionIndex: 0,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Banking',
						columnComponents: [
							{
								componentType: 'input',
								name: '',
								label: 'BANK ACCOUNT',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											value: 'BANK ACCOUNT',
											label: '',
											placeholder: 'Label',
											type: 'text',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											value: '',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
										},
									],
								},
								id: 43.332,
								sectionIndex: 0,
								columnIndex: 1,
							},
						],
					},
				],
			},
			{
				sectionName: 'MONTH_CLOSE_CONTACTS',
				componentName: 'Month Close Contacts',
				componentType: 'TABLE',
				tableHeaders: [
					{
						name: 'First Name',
					},
					{
						name: 'Last Name',
					},
					{
						name: 'Title',
					},
					{
						name: 'Email',
					},
					{
						name: 'Phone',
					},
				],
				tableRows: [],
			},
			{
				sectionName: '',
				componentName: '',
				componentType: 'COLUMN_TWO',
				columns: [
					{
						columnName: 'Notes',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: '',
								placeholder: 'Enter',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: '',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 543.74,
								sectionIndex: 2,
								columnIndex: 0,
							},
						],
					},
					{
						columnName: 'Links',
						columnComponents: [
							{
								componentType: 'textarea',
								name: '',
								label: 'TAX DOCUMENT STORAGE',
								placeholder: 'Enter ',
								type: 'text',
								value: '-',
								propsTypes: {
									withinColumnIndex: 0,
									propInputTypes: [
										{
											componentType: 'input',
											name: 'label',
											label: '',
											placeholder: 'Label',
											type: 'text',
											value: 'TAX DOCUMENT STORAGE',
										},
										{
											componentType: 'input',
											name: 'placeholder',
											label: '',
											placeholder: 'Placeholder',
											type: 'text',
											value: '',
										},
									],
								},
								id: 632.534383,
								sectionIndex: 2,
								columnIndex: 1,
							},
						],
					},
				],
			},
			{
				sectionName: 'WORKFLOW_MONTH_CLOSE',
				componentName: 'Workflow: Month Close',
				componentType: 'FILLED_TABLE',
				tableHeaders: [
					{
						name: 'Task',
					},
					{
						name: 'Description',
					},
					{
						name: 'Budget',
					},
					{
						name: 'Assignee',
					},
					{
						name: 'Dependency',
					},
				],
			},
		],
	},
	propsTypes: [],
};
