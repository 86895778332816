import FormButtonOne from '../Buttons/FormButtonOne';
import RichTextEditor from '../Inputs/RichTextEditor';

function ViewHtmlParserContent({ onCancel, edit, value, onChange, type, richTextStyle, title }) {
	return (
		<div className='ViewHtmlParserContent'>
			<div className='main'>
				<div className='w-100 d-flex justify-content-between'>
					<span className='HeadlineTwoBold dark-text'>{title}</span>
				</div>

				<div>
					<RichTextEditor
						type={type}
						Edit={edit}
						value={value}
						taskDescription
						onChange={onChange}
						style={{
							...richTextStyle,
							wordWrap: 'break-word',
							textAlign: 'left',
						}}
					/>
				</div>
			</div>

			<div className='ctas'>
				<FormButtonOne
					onClick={onCancel}
					type='button'
					className={'rounded-button-2 secondary'}
				>
					Close
				</FormButtonOne>
			</div>
		</div>
	);
}

export default ViewHtmlParserContent;
