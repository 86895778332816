import { memo } from 'react';
import { Route, Switch } from 'react-router';
import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';
import Restricted from '../components/Permissions/Restricted';
import { executivePermissions, Member, standardPermissions } from '../utilities/utilities';
import posthog from 'posthog-js';
import Login from './login/LoginFormComponent';
import Signup from './signup/SignupFormComponent';
import UploadDocument from './UploadDocument';
import ForgetPasswordComponent from './login/ForgetPasswordComponent';
import ResetLinkSentComponent from './login/ResetLinkSentComponent';
import ResetPasswordComponent from './login/ResetPasswordComponent';
import SlackIntegrationComponent from './SlackIntegration';
import AccountSettingsComponent from './AccountSettings';
import WorkflowBrowser from '../components/ClientManagement/WorkflowBrowser/WorkflowBrowser';
import UserManagement from './UserManagement';
import ArchivedUsers from './ArchivedUsers';
import UserProfile from '../components/UserManagement/UserProfile';
import TeamManagement from './TeamManagement';
import TeamProfile from '../components/TeamManagement/TeamProfile';
import ClientManagement from './ClientManagement';
import TimeReportsV2 from './TimeReportsV2';
import UsersPayrollExport from '../components/TimeReportsV2/UsersPayrollExport';
import TimeReportPreviewClient from '../components/TimeReportsV2/TimeReportPreviewClient';
import TimeReportsPreviewUser from '../components/TimeReportsV2/TimeReportsPreviewUser';
import ClientInvoice from '../components/ClientManagement/ClientInvoice';
import ClientProfile from '../components/ClientManagement/ClientProfile';
import ClientProfilePreview from './ClientProfilePreview';
import TimeReportsUserDetailsV2 from '../components/TimeReportsV2/TimeReportsUserDetailsV2';
import TimeReportsClientDetailsV2 from '../components/TimeReportsV2/TimeReportsClientDetailsV2';
import AppComponent from '../components/AppComponent';
import Public from './Public';
import PrivateRoute from '../authservice/Auth';
import WorkflowBuilderEditor from '../components/ClientManagement/Workflow-Builder/WorkflowBuilderEditor';
import WorkSpace from './WorkSpace';
import CustomizeInvoice from '../components/Invoices/CustomizeInvoice';
import InvoicePreview from '../components/Invoices/InvoicePreviewModal';
import { useSelector } from 'react-redux';
import Invoices from './Invoices';
import InvoiceDetails from '../components/Invoices/InvoiceDetails';
import RestrictedAccess from './RestrictedAccess';
import CompanyProfile from './CompanyProfile';
import WorkflowLibrary from './WorkflowLibrary';
import ZapierAccess from './ZapierAccess';
import ZapierIntegration from './ZapierIntegration';
import LeavesScreen from './LeavesScreen';
import ArchievedWorkflow from './ArchievedWorkflow';
import PdfPreview from './PdfPreview';

function AllRoutes() {
	const user = useSelector((state) => state.authReducer.user);

	return (
		<Switch>
			<PublicRoute exact path='/' component={AppComponent} />
			<PublicRoute path='/login' component={Login} />
			<PublicRoute exact path='/signup' component={Signup} />

			<Route path='/forgetpassword' component={ForgetPasswordComponent} />
			<Route path='/resetlinksent' component={ResetLinkSentComponent} />
			<Route exact path='/resetpassword' component={ResetPasswordComponent} />
			<Route path='/resetpassword/:resetId' component={ResetPasswordComponent} />

			<Route path='/upload-document/:id' component={UploadDocument} />
			<Route path='/invoice-preview/:id' component={PdfPreview} />
			{/* Workspace */}
			<ProtectedRoute path='/workspace' comp={WorkSpace} />

			{/* Client Management */}
			<ProtectedRoute path='/clients' comp={ClientManagement} />
			<ProtectedRoute path='/client-profile/:id' comp={ClientProfile} />
			{posthog?.isFeatureEnabled('llm_onboarding') && (
				<ProtectedRoute path='/client-profile-preview'>
					<Restricted
						AllowedUsers={standardPermissions}
						userTeamRole={user?.user_roles?.map((item) => item.role_id)}
					>
						<ClientProfilePreview />
					</Restricted>
				</ProtectedRoute>
			)}
			<ProtectedRoute path='/client-profile-workflow/:id' comp={WorkflowBrowser} />

			{/* Workflow Library */}
			<ProtectedRoute path='/Workflow-Builder'>
				<Restricted
					AllowedUsers={standardPermissions}
					userTeamRole={user?.user_roles?.map((item) => item.role_id)}
				>
					<WorkflowLibrary />
				</Restricted>
			</ProtectedRoute>
			<ProtectedRoute path='/workflow-editor/:id' comp={WorkflowBuilderEditor} />
			<ProtectedRoute path='/archived-workflow'>
				<Restricted AllowedUsers={executivePermissions}>
					<ArchievedWorkflow />
				</Restricted>
			</ProtectedRoute>

			{/* Time Reports */}
			<ProtectedRoute path='/time-reports' comp={TimeReportsV2} />
			<ProtectedRoute path='/time-reports-user/:id' comp={TimeReportsUserDetailsV2} />
			<ProtectedRoute path='/time-reports-client/:id' comp={TimeReportsClientDetailsV2} />
			<ProtectedRoute path='/client-time-reports-preview' comp={TimeReportPreviewClient} />
			<ProtectedRoute path='/user-time-reports-preview' comp={TimeReportsPreviewUser} />
			<ProtectedRoute path='/users-payroll-export' comp={UsersPayrollExport} />

			{/* Team Management */}
			<ProtectedRoute path='/teams'>
				<Restricted
					AllowedUsers={standardPermissions}
					userTeamRole={user?.user_roles?.map((item) => item.role_id)}
				>
					<TeamManagement />
				</Restricted>
			</ProtectedRoute>
			<ProtectedRoute path='/team-profile/:id'>
				<Restricted
					AllowedUsers={standardPermissions}
					userTeamRole={user?.user_roles?.map((item) => item.role_id)}
				>
					<TeamProfile />
				</Restricted>
			</ProtectedRoute>

			{/* User Management */}
			<ProtectedRoute path='/users'>
				<Restricted
					AllowedUsers={[...standardPermissions, Member.teamMemberId]}
					userTeamRole={user?.user_roles?.map((item) => item.role_id)}
				>
					<UserManagement />
				</Restricted>
			</ProtectedRoute>
			<ProtectedRoute path='/user-profile/:id' comp={UserProfile} />
			<ProtectedRoute path='/archived-users'>
				<Restricted AllowedUsers={[...executivePermissions]}>
					<ArchivedUsers />
				</Restricted>
			</ProtectedRoute>

			{/* Integrations */}
			<ProtectedRoute path='/integration'>
				<ZapierIntegration />
			</ProtectedRoute>
			<ProtectedRoute path='/oauth'>
				<ZapierAccess />
			</ProtectedRoute>
			<ProtectedRoute path='/slackintegration' comp={SlackIntegrationComponent} />
			<ProtectedRoute path='/account-settings' comp={AccountSettingsComponent} />

			{/* Invoices */}
			<ProtectedRoute exact path='/invoices'>
				<Restricted AllowedUsers={executivePermissions}>
					<Invoices />
				</Restricted>
			</ProtectedRoute>
			<ProtectedRoute path='/client-invoice'>
				<Restricted AllowedUsers={standardPermissions}>
					<ClientInvoice />
				</Restricted>
			</ProtectedRoute>
			<ProtectedRoute exact path='/invoices/:id'>
				<Restricted AllowedUsers={executivePermissions}>
					<InvoiceDetails />
				</Restricted>
			</ProtectedRoute>
			<ProtectedRoute path='/customize-invoice'>
				<Restricted AllowedUsers={executivePermissions}>
					<CustomizeInvoice />
				</Restricted>
			</ProtectedRoute>
			<ProtectedRoute path='/preview-invoice'>
				<Restricted AllowedUsers={executivePermissions}>
					<InvoicePreview />
				</Restricted>
			</ProtectedRoute>

			<ProtectedRoute path='/restricted'>
				<RestrictedAccess />
			</ProtectedRoute>
			<ProtectedRoute path='/company-profile'>
				<CompanyProfile />
			</ProtectedRoute>

			{posthog?.isFeatureEnabled('leave_requests') && (
				<ProtectedRoute path='/leaves'>
					<Restricted AllowedUsers={executivePermissions}>
						<LeavesScreen />
					</Restricted>
				</ProtectedRoute>
			)}

			<PrivateRoute path='/private' component={Public} />
		</Switch>
	);
}

export default memo(AllRoutes);
