import { useEffect, useMemo, useRef, useState } from 'react';
import AddTemplateTask from '../Modals/AddTemplateTask';
import { Input } from '../../Inputs/Input';
import DummyModal from '../../Modal/DummyModal';
import { useDispatch, useSelector } from 'react-redux';
import SelectList from '../../Inputs/SelectList';
import EditableTextArea from '../../Inputs/EditableTextArea';
import { useHistory, useLocation, useParams } from 'react-router';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import * as builderActions from '../../../redux/actions/workflow-builder-actions';
import { sortTemplateTasks } from '../../../utilities/utilities';
import TasksComponent from '../../sub_components/TasksComponent';
import useModal from '../../Modal/useModal';
import { CircularProgress, Modal } from '@mui/material';
import { SketchPicker } from 'react-color';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/Info.svg';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/CaretLeft.svg';
import useBreadCrumb from '../../../hooks/useBreadCrumb';
import { routes } from '../../../utilities/routes';
import AlertBar from '../../sub_components/AlertBar';
import { setSnackBarIsOpen } from '../../../redux/actions/dashboard/workspaces-actions';

const CustomTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#FB7A03',
		color: 'white',
		boxShadow: theme.shadows[1],
		width: 175,
		minHeight: 80,
		fontFamily: 'Centra No2',
		fontSize: 12,
		fontStyle: 'normal',
		fontWeight: 300,
	},
}));

function WorkflowBuilderEditor() {
	const taskTableHeaders = [
		'Task',
		'Instructions',
		'Budgeted Hours',
		'Dependency',
		'Action',
	];

	const colors = [
		'#B94D8F',
		'#117A3D',
		'#77C3DE',
		'#1656F7',
		'#63C66E',
		'#7995D2',
		'#F05544',
		'#FFCF06',
		'#4B0082',
		'#FF6F61',
		'#E6E6FA',
		'#98FF98',
		'#FF007F',
		'#008080',
		'#DA70D6',
		'#FFAA4D',
		'#0F52BA',
	];

	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const colorPickerRef = useRef();

	const { BreadCrumbs } = useBreadCrumb();
	const { id } = useParams();

	const [FormProp, setFormProp] = useState({});
	const [editTask, setEditTask] = useState(false);
	const [colorPicker, setColorPicker] = useState(false);

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const newWorkflowForm = useSelector(
		(state) => state.WorkflowBuilder.newWorkflowForm,
	);

	const workflowData = useSelector(
		(state) => state.WorkflowBuilder.workflowData,
	);

	const workflowCategories = useSelector(
		(state) => state.WorkflowBuilder.workflowCategories,
	);
	
	const builderLoading = useSelector(
		(state) => state.WorkflowBuilder.builderLoading,
	);
	const publishWorkflowBuilderLoading = useSelector(
		(state) => state.WorkflowBuilder.publishWorkflowBuilderLoading,
	);
	
	const loading = useSelector((state) => state.WorkflowBuilder.loading);

	const clientForBuilder = location.state;

	let task_templates = useSelector(
		(state) => state.WorkflowBuilder.task_templates,
	);
	task_templates = sortTemplateTasks(task_templates);

	const addNewTaskRow = () => {
		ModalType('TEMPLATE_TASK');
		dispatch(builderActions.clearWorkflowTaskValues());
		toggleModal();
		setFormProp({});
		setEditTask(false);
	};

	const handleEditTask = (task) => {
		ModalType('TEMPLATE_TASK');
		toggleModal();
		setFormProp(task);
		setEditTask(true);
	};

	const deleteTask = (task) => {
		dispatch(builderActions.removeTasksInTaskTemplates(task.id));
	};

	const formChangeHandler = (event) => {
		let { name, value } = event.target;

		if (name == 'category_id' && value === '') value = null;
		if (name == 'category_id' && value !== null) value = parseInt(value);

		dispatch(builderActions.workflowFormChangeHanlder(name, value));
	};

	const colorChangeHandler = (color) => {
		dispatch(builderActions.workflowFormChangeHanlder('color', color));
	};

    const handleAddColor = () => {
		setColorPicker(true);
	};

    const updateBuilder = (response) => {
        const tempWorkflow = workflowData?.data[0];
        tempWorkflow.componentName = newWorkflowForm?.name
            .split(' ')
            .join('_')
            .toUpperCase();
        tempWorkflow.displayName = newWorkflowForm?.name;
        dispatch(
            builderActions.updateWorkflowBuilderData(
                {
                    ...workflowData,
                    name: newWorkflowForm?.name.trim(),
                    data: tempWorkflow,
                },
            ),
        ).then(() => {
            dispatch(builderActions.publishBuilderDataLoading(false));
            dispatch(builderActions.workflowBuilderLoading(false));
            dispatch(builderActions.getCompanyWorkflows());
            history.push(`/Workflow-Builder`, clientForBuilder);
            dispatch(builderActions.setComponent({}));
            dispatch(builderActions.resetWorkflowForm());
            dispatch(setSnackBarIsOpen(true, true, 'Workflow updated successfully'));
        }).catch((error) => {
            dispatch(builderActions.publishBuilderDataLoading(false));
            dispatch(setSnackBarIsOpen(false, true, error.message));
            dispatch(builderActions.workflowBuilderLoading(false));
        }); 
    };

	const onUpdate = () => {
		dispatch(builderActions.workflowBuilderLoading(true));
		dispatch(
			builderActions.updateWorkflowfromBuilder(id, {...newWorkflowForm, name: newWorkflowForm?.name.trim()}),
		).then((response) => {
            updateBuilder(response);
        }).catch((error) => {
            dispatch(builderActions.workflowBuilderLoading(false));
            dispatch(setSnackBarIsOpen(false, true, error.message));
        });
	};

	const publishWorkflow = () => {
		dispatch(builderActions.publishBuilderDataLoading(true));
		dispatch(
			builderActions.updateWorkflowfromBuilder(
				id,
				{ ...newWorkflowForm, name: newWorkflowForm?.name.trim() ,status: 'PUBLISHED' },
			),
		).then((response) => {
            updateBuilder(response);
        }).catch((error) => {
            dispatch(builderActions.publishBuilderDataLoading(false));
            dispatch(setSnackBarIsOpen(false, true, error.message));
        });
	};

	useMemo(() => {
		dispatch(builderActions.getWorkflowCategories());
		dispatch(builderActions.getSpecificWorkflowById(id));
		dispatch(builderActions.getWorkflowBuilderData(id));
		dispatch(builderActions.getTaskTemplates(id));
		return () => {
			dispatch(builderActions.resetWorkflowForm());
		};
	}, [dispatch]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
				setColorPicker(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div>
			<div className='Workflow-Builder'>
				<div className='workflow-builder-sidebar d-flex flex-column'>
					<h2 className='HeadlineThreeBold text-white text-left'>
						Workflow Details
					</h2>
					<div className='d-flex flex-column'>
						<div className='d-flex flex-column' style={{ gap: '0.5em' }}>
							<>
								<div>
									<Input
										labelClass='text-white BodyTwoLight text-capitalize'
										style={{
											padding: '5px',
											backgroundColor: 'rgba(0,0,0,0.5)',
											borderRadius: '4px',
										}}
										Class='text-white'
										label={'Workflow Name'}
										type='text'
										name='name'
										placeholder='Type workflow name'
										onChange={(e) => {
											formChangeHandler(e);
										}}
										value={newWorkflowForm?.name}
									/>
								</div>
								<div className='pt-1'>
									<EditableTextArea
										labelClass='text-white text-capitalize BodyTwoLight'
										style={{
											padding: '5px',
											backgroundColor: 'rgba(0,0,0,0.5)',
											borderRadius: '4px',
											height: '80px',
											resize: 'none',
										}}
										Class='text-white'
										label={'Description'}
										type='text'
										name='description'
										placeholder='Workflow Description'
										onChange={formChangeHandler}
										Edit={true}
										value={newWorkflowForm?.description}
									/>
								</div>
								<div className='ProfilePage d-flex flex-column w-100'>
									<div className='w-100 d-flex align-items-center'>
										<div>
											<label className=' text-left text-white BodyTwoLight text-capitalize'>
												Category
											</label>
										</div>
										<div className='pl-1 pb-1'>
											<CustomTooltip
												placement='right-end'
												title={
													<div>
														<span className='BodyTwoLight text-left text-white'>
															The workflow will appear in the selected category
															within the library for easy access and
															organization.
														</span>
													</div>
												}
											>
												<InfoIcon width='20px' height='20px' />
											</CustomTooltip>
										</div>
									</div>
									<SelectList
										style={{
											padding: '5px',
											backgroundColor: 'rgba(0,0,0,0.5)',
											borderRadius: '4px',
										}}
										placeholder='Select Category'
										options={workflowCategories?.map((category) => {
											return {
												label: category.name,
												value: category.id,
											};
										})}
										name='category_id'
										value={{
											value: newWorkflowForm?.category_id,
										}}
										onChange={formChangeHandler}
										required
									/>
								</div>
								<div className='ProfilePage d-flex flex-column w-100'>
									<div className='w-100 d-flex align-items-center'>
										<div>
											<label className=' text-left text-white BodyTwoLight text-capitalize'>
												Choose Workflow Color
											</label>
										</div>
										<div className='pl-1 pb-1'>
											<CustomTooltip
												placement='bottom-start'
												title={
													<div>
														<span className='BodyTwoLight text-left text-white'>
															Choose a color to represent the workflow, and it
															will be applied to the strips of cards and the
															task modal, making it easier to identify and
															manage your workflow.
														</span>
													</div>
												}
											>
												<InfoIcon width='20px' height='20px' />
											</CustomTooltip>
										</div>
									</div>
									{colorPicker ? (
										<div ref={colorPickerRef}>
											<SketchPicker
												width='90%'
												onChangeComplete={(color) => colorChangeHandler(color.hex)}
												onChange={(color) => colorChangeHandler(color.hex)}
												disableAlpha
												color={newWorkflowForm?.color || '#fff'}
											/>
										</div>
									) : (
										<div
											style={{
												width: '100%',
												height: 'auto',
												backgroundColor: '#242424',
												borderRadius: '4px',
												padding: '8px',
											}}
											className='d-flex align-items-center justify-content-center container'
										>
											<div className='row mr-0'>
												<div className='col-2 pb-2'>
													<div
														className='pointer d-flex align-items-center justify-content-center'
														onClick={handleAddColor}
														name='color'
														style={{
															width: '18px',
															height: '18px',
															backgroundColor: '#FB7A03',
															borderRadius: '50%',
														}}
													>
														<AddIcon />
													</div>
												</div>
												{colors?.map((color) => {
													return (
														<div key={color} className='col-2 pb-2'>
															<div
																key={color}
																className='d-flex align-items-center justify-content-center'
																style={{
																	border:
																		newWorkflowForm &&
																		newWorkflowForm?.color == color
																			? `1px solid #FB7A03`
																			: '',
																	width: '22px',
																	height: '22px',
																	borderRadius: '50%',
																}}
															>
																<div
																	className='pointer'
																	onClick={() => colorChangeHandler(color)}
																	name='color'
																	key={color}
																	style={{
																		width: '18px',
																		height: '18px',
																		backgroundColor: color,
																		borderRadius: '50%',
																	}}
																></div>
															</div>
														</div>
													);
												})}
											</div>
										</div>
									)}
								</div>
							</>
						</div>
					</div>
				</div>
                <div
                    style={{
                        width: 'calc(100vw - 260px)',
                        height: '100vh',
                    }}
                >
                    <AlertBar/>
                    <div className='workflow-builder-container'>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                cursor: 'pointer',
                                color: '#FB7A03',
                                marginBottom: '8px',
                            }}
                        >
                            <ArrowLeftIcon style={{ marginRight: '4px' }} />
                            <BreadCrumbs>
                                <h2
                                    className='HeadlineThreeBook text-left'
                                    onClick={() => history.push(routes['Workflow-Builder'], clientForBuilder)}
                                >
                                    Workflow Library
                                </h2>
                            </BreadCrumbs>
                        </div>
                        <div
                            className='workflow-builder-bar d-flex flex-row justify-content-between align-items-center'
                            style={{ padding: '24px' }}
                        >
                            <h2 className='HeadlineThreeBold text-white'>Workflow Builder</h2>

                            <div className='d-flex' style={{ gap: '16px' }}>
                                <SubmitButtonsField child={true}>
                                    <button
                                        type='submit'
                                        className='d-flex align-items-center curved'
                                        id='Element'
                                        onClick={onUpdate}
                                        disabled={builderLoading}
                                        style={{width: newWorkflowForm?.status !== 'PUBLISHED' && '210px'}}
                                    >
                                        {builderLoading ? (
                                            <CircularProgress
                                                size={20}
                                                sx={{ color: 'white' }}
                                                disableShrink
                                            />
                                        ) : (
                                            <>
                                            {newWorkflowForm?.status === 'PUBLISHED' ?'Save' : 'Save Changes to Draft'}
                                            </>
                                        )}
                                    </button>
                                </SubmitButtonsField>
                                {
                                newWorkflowForm.status !== 'PUBLISHED' &&
                                    <SubmitButtonsField child={true}>
                                        <button
                                            type='submit'
                                            className='d-flex align-items-center curved'
                                            id='Element'
                                            onClick={publishWorkflow}
                                            style={{ width: '240px' }}
                                            disabled={publishWorkflowBuilderLoading}
                                        >
                                            {publishWorkflowBuilderLoading ? (
                                                <CircularProgress
                                                    size={20}
                                                    sx={{ color: 'white' }}
                                                    disableShrink
                                                />
                                            ) : (
                                                'Publish to Company Library'
                                            )}
                                        </button>
                                    </SubmitButtonsField>
                                }
                            </div>
                        </div>

                        {newWorkflowForm && (
                            <div
                                className='worklfow-editor-container'
                                style={{ marginTop: '12px' }}
                            >	
                                <div className='d-flex' style={{padding:'13px'}}>
                                    <span className='HeadlineOneBold'>{newWorkflowForm?.name}</span>
                                </div>
                                <div className='workflow-editor-body'>
                                    
                                    <div
                                        className='d-flex align-items-center '
                                        style={{
                                            height: '47px',
                                            width: '100%',
                                            backgroundColor: '#FCFDFD',
                                            borderRadius: '4px',
                                            padding: '12px 18px',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        <span className='HeadlineThreeBold'>
                                            Workflow Tasks
                                        </span>
                                    </div>
                                    <div className='w-100'>
                                        {loading ? (
                                            <CircularProgress
                                                size={40}
                                                sx={{ color: '#FB7A03' }}
                                                disableShrink
                                            />
                                        ) : (
                                            <TasksComponent
                                                tasks={task_templates}
                                                headers={taskTableHeaders}
                                                addNewTaskRow={addNewTaskRow}
                                                handleEditTask={handleEditTask}
                                                deleteTask={deleteTask}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
				{modalIsOpen && (
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<>
							{ModalName === 'TEMPLATE_TASK' && (
								<DummyModal
									title={'Add New Task'}
									onClose={toggleModal}
									style={{ width: '900px' }}
									hideCrossIcon
								>
									<AddTemplateTask
										onSubmit={() => {
											toggleModal();
											setFormProp({});
										}}
										onCancel={toggleModal}
										editor
										formValues={FormProp}
										editTask={editTask}
									/>
								</DummyModal>
							)}
						</>
					</Modal>
				)}
			</div>
		</div>
	);
}

export default WorkflowBuilderEditor;
