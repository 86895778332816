/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as Close } from '../assets/icons/Close.svg';
import { ReactComponent as Calendar } from '../assets/icons/Calendarcolor.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as workspaceActions from '../redux/actions/dashboard/workspaces-actions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as ActionCenterActions from '../redux/actions/action-center';
import moment from 'moment';
import {
	DATE_RANGE_FILTER,
	detailsModal,
	ERROR_CODE,
	TASK_STATUSES,
	taskDragAlertContent,
} from '../utilities/utilities';
import { CircularProgress } from '@mui/material';
import ExpandableClientForReassignments from '../components/WorkSpace/ExpandableClientForReassignments';
import DropDown from '../components/Inputs/DropDown';
import TaskAlert from '../components/Alerts/TaskAlert';

function ReshcedulingTasks() {
	const dispatch = useDispatch();
	const today = moment();

	const dateRangeOptions = [
		{ label: 'is on', value: DATE_RANGE_FILTER.IS_ON },
		{ label: 'is between', value: DATE_RANGE_FILTER.IS_BETWEEN },
	];
	const [dateRangeFilter, setDateRangeFilter] = useState(DATE_RANGE_FILTER.IS_ON);
	const [date, setDate] = useState({
		start_date: today,
		end_date: today,
	});
	const [loading, setLoading] = useState(false);
	const [failedTask, setFailedTask] = useState({
		error: null,
		details: null,
	});
     
	const { header, doneButtonText, cancelButtonText } = useMemo(() => {
		return taskDragAlertContent(failedTask?.error?.error_code);
	}, [failedTask?.error]);

	const [updateLoading, setUpdateLoading] = useState(false);

	const bulkClientTasks = useSelector((state) => state.workSpaceReducer.bulkClientTasks)
		?.filter((client) => client?.tasks?.length > 0)
		?.map((client) => {
			return {
				...client,
				displayName:
					client?.client_dba?.trim() == '' ? client?.client_name : client?.client_dba,
			};
		})
		?.sort((a, b) => {
			if (a.displayName && b.displayName) {
				return a.displayName.localeCompare(b.displayName);
			}
		});
	const dragOverDate = useSelector((state) => state.workSpaceReducer.dragOverDate);

	const handleDateOption = (event) => {
		setDateRangeFilter(event.target.value);
	};

	const handleDateChange = (name) => (value) => {
		setDate({
			...date,
			[name]: value,
		});
	};

	const handleClosePanle = () => {
		dispatch(ActionCenterActions.setActionSideBarComponent(null));

		setDateRangeFilter(DATE_RANGE_FILTER.IS_ON);
		setDate({
			start_date: today,
			end_date: today,
		});
	};

	const handleCloseAlert = () => {
		dispatch(workspaceActions.setDragOverDate(null));
		setUpdateLoading(false);
		setFailedTask({
			error: null,
			details: null,
		});
	};

	const handleDragEnd = useCallback(
		(task) => () => {
			const task_date = task.extended_date
				? moment(task?.extended_date)
				: moment(task?.start_date);

			if (moment(dragOverDate).isValid() && !moment(dragOverDate).isSame(task_date)) {
				dispatch(
					workspaceActions.rescheduleTasks(
						{
							task: { ...task, stop_stream_sse: true },
							dragOverContainer: moment(dragOverDate).format('YYYY-MM-DD'),
						},
						true,
					),
				)
					.then(() => {
						getClientTasks();
						dispatch(workspaceActions.triggerGetTasks());
						dispatch(workspaceActions.setDragOverDate(null));
					})
					.catch((error) => {
						setFailedTask({
							error: error,
							details: task,
						});
					});
			} else {
				dispatch(
					workspaceActions.setSnackBarIsOpen(
						false,
						true,
						!moment(dragOverDate).isValid()
							? 'Invalid date'
							: 'Task is already scheduled on this date',
					),
				);
				dispatch(workspaceActions.setDragOverDate(null));
			}
		},
		[dragOverDate],
	);

	const handleDoneTaskAlert = (error_code) => {
		setUpdateLoading(true);
		if (
			error_code == ERROR_CODE.START_DATE_BEFORE_PRECEEDING_TASK_SAME_ASSIGNEE ||
			error_code == ERROR_CODE.START_DATE_BEFORE_PRECEEDING_TASK_DIFFERENT_ASSIGNEE
		) {
			dispatch(
				workspaceActions.handleTaskClick({
					taskId: failedTask?.details?.dependent_task_id,
					modalType: detailsModal.CLIENT,
				}),
			);
			handleCloseAlert();
		}
		if (
			error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE ||
			error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE
		) {
			if (failedTask?.details?.my_task?.task_type == 'ADHOC') {
				updateAdhocTaskAlertOnAlert(error_code);
			} else {
				dispatch(
					workspaceActions.updateTaskDetails(failedTask?.details?.id, {
						start_date:
							error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE
								? moment(dragOverDate).format('YYYY-MM-DD')
								: failedTask?.details?.start_date,
						due_date:
							error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE
								? moment(dragOverDate).format('YYYY-MM-DD')
								: failedTask?.details?.due_date,
						extended_date: moment(dragOverDate).format('YYYY-MM-DD'),
						stop_stream_sse: true,
					}),
				)
					.then(() => {
						handleCloseAlert();
						getClientTasks();
						dispatch(workspaceActions.triggerGetTasks());
					})
					.catch((error) => {
						handleCloseAlert();
						dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
					});
			}
		}
	};

	const updateAdhocTaskAlertOnAlert = (error_code) => {
		const formDetails = {
			id: failedTask?.details?.id,
			type: failedTask?.details?.my_task?.type,
			title: failedTask?.details?.title,
			client_id:
				failedTask?.details?.client_id == null
					? null
					: Number(failedTask?.details?.client_id),
			budgeted_hours: Number(failedTask?.details?.budgeted_hours),
			assignee_list:
				failedTask?.details?.task_assignees?.length > 0
					? [failedTask?.details?.task_assignees[0]?.user_profile?.id]
					: [],
			assignee_user_id: Number(failedTask?.details?.task_assignees[0]?.user_profile?.id),
			approval_from: failedTask?.details?.approval_from
				? Number(failedTask?.details?.approval_from)
				: null,
			is_approval_required: failedTask?.details?.is_approval_required,
			start_date:
				error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE
					? moment(dragOverDate).format('YYYY-MM-DD')
					: failedTask?.details?.start_date,
			extended_date: moment(dragOverDate).format('YYYY-MM-DD'),
			is_billable: failedTask?.details?.my_task?.is_billable,
			task_type: failedTask?.details?.my_task?.task_type,
			end_on: failedTask?.details?.my_task?.end_on,
			required_days:
				error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE
					? moment(dragOverDate).diff(moment(failedTask?.details?.start_date), 'days') + 1
					: moment(failedTask?.details?.due_date).diff(moment(dragOverDate), 'days') + 1,
			repeat_type: failedTask?.details?.my_task?.repeat_type,
			repeat_interval:
				failedTask?.details?.my_task?.repeat_interval == null
					? null
					: Number(failedTask?.details?.my_task?.repeat_interval),
			recur_preference: failedTask?.details?.my_task?.recur_preference,
			is_monthly_on_day: failedTask?.details?.my_task?.is_monthly_on_day,
			stop_stream_sse: true,
		};
		dispatch(workspaceActions.updateMeetingTaskDetails(formDetails, false))
			.then(() => {
				handleCloseAlert();
				getClientTasks();
				dispatch(workspaceActions.triggerGetTasks());
			})
			.catch((error) => {
				handleCloseAlert();
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
			});
	};

	const getClientTasks = () => {
		setLoading(true);
		dispatch(
			workspaceActions.getClientTasksForBulkActions(
				{
					users: [],
					taskStatuses: [
						TASK_STATUSES.PAST_DUE,
						TASK_STATUSES.NEW,
						TASK_STATUSES.BLOCKED,
						TASK_STATUSES.APPROVAL_PENDING,
						TASK_STATUSES.IN_PROGRESS,
					],
				},
				moment(date.start_date).format('YYYY-MM-DD'),
				dateRangeFilter === DATE_RANGE_FILTER.IS_ON ||
					dateRangeFilter === DATE_RANGE_FILTER.IS_AFTER
					? moment(date.start_date).format('YYYY-MM-DD')
					: moment(date.end_date).format('YYYY-MM-DD'),
				dateRangeFilter,
			),
		)
			.then(() => setLoading(false))
			.catch((error) => {
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
				setLoading(false);
			});
	};

	const getDatesInRange = (start, end) => {
		const startDate = moment(start).startOf('day');
		const endDate = moment(end).startOf('day');
		const diffDays = endDate.diff(startDate, 'day');

		const dates = Array.from({ length: diffDays + 1 }, (_, index) =>
			startDate.clone().add(index, 'days').format('YYYY-MM-DD'),
		);

		return dates;
	};

	const dateRange = useMemo(() => {
		return dateRangeFilter == DATE_RANGE_FILTER.IS_BETWEEN
			? getDatesInRange(date.start_date, date.end_date)
			: [];
	}, [date.start_date, date.end_date, dateRangeFilter]);

	useEffect(() => {
		if (dateRangeFilter) {
			getClientTasks();
		}
	}, [dateRangeFilter, date.start_date, date.end_date]);

	return (
		<>
			<div className='reassign-tasks-container'>
				<div>
					<div className='d-flex justify-content-between align-items-center mb-3'>
						<h2 className='HeadlineTwoBold text-left'>Reschedule Tasks</h2>
						<span onClick={handleClosePanle} className='pointer'>
							<Close />
						</span>
					</div>
					<div className='improved-input-designs pb-3'>
						<label className='text-left'>{`What are the date(s) of the tasks you want to reassign?`}</label>
						<DropDown
							onChange={handleDateOption}
							normalDropDown
							style={{ height: '32px', textAlign: 'left' }}
							options={dateRangeOptions}
							value={dateRangeFilter}
							disableNull
						/>
					</div>
					{dateRangeFilter !== '' &&
						(dateRangeFilter == DATE_RANGE_FILTER.IS_BETWEEN ? (
							<div className='d-flex flex-column' style={{ gap: '16px' }}>
								<div
									className='d-flex justi-content-between align-items-center'
									style={{ gap: '16px' }}
								>
									<label className='BodyTwoLight light-text mb-0'>From</label>
									<KeyboardDatePicker
										autoOk
										disableToolbar
										name='start_date'
										value={date.start_date || today}
										onChange={handleDateChange('start_date')}
										keyboardIcon={<Calendar />}
										InputProps={{
											style: {
												fontSize: 14,
												height: 32,
												fontFamily: 'CentraLight',
												fontWeight: 'normal',
												background: 'white',
											},
										}}
										style={{ width: '100%', textAlign: 'right' }}
										format={'MM/DD/YYYY'}
										className='BodyTwoLight'
									/>
								</div>
								<div
									className='d-flex justi-content-between align-items-center'
									style={{ gap: '16px' }}
								>
									<label className='BodyTwoLight light-text mb-0'>To</label>
									<KeyboardDatePicker
										autoOk
										disableToolbar
										name='end_date'
										value={
											date.end_date || moment(date.start_date).add(1, 'days')
										}
										onChange={handleDateChange('end_date')}
										keyboardIcon={<Calendar />}
										InputProps={{
											style: {
												fontSize: 14,
												height: 32,
												fontFamily: 'CentraLight',
												fontWeight: 'normal',
												background: 'white',
											},
										}}
										style={{ width: '100%' }}
										format={'MM/DD/YYYY'}
										className='BodyTwoLight'
										minDate={date.start_date}
									/>
								</div>
							</div>
						) : (
							<KeyboardDatePicker
								autoOk
								disableToolbar
								name='start_date'
								value={date.start_date || today}
								onChange={handleDateChange('start_date')}
								keyboardIcon={<Calendar />}
								InputProps={{
									style: {
										fontSize: 14,
										height: 32,
										fontFamily: 'CentraLight',
										fontWeight: 'normal',
										background: 'white',
									},
								}}
								style={{ width: '100%' }}
								format={'MM/DD/YYYY'}
								className='BodyTwoLight'
							/>
						))}
				</div>
				<div
					style={{
						height: '100%',
						marginTop: '16px',
						maxHeight: '100%',
						overflowY: 'auto',
						overflowX: 'hidden',
					}}
				>
					{loading ? (
						<div
							style={{
								height: '100%',
								width: '100%',
							}}
							className='d-flex justify-content-center align-items-center'
						>
							<CircularProgress
								style={{
									color: '#fa7b03',
									height: '50px',
									width: '50px',
								}}
							/>
						</div>
					) : (
						<>
							{dateRangeFilter == DATE_RANGE_FILTER.IS_ON && (
								<div className='d-flex flex-column' style={{ gap: '12px' }}>
									<span className='BodyOneBold text-left'>
										{`${moment(date.start_date).format('dddd')} - ${moment(
											date.start_date,
										).format('MMM DD, yyyy')}`}
									</span>
									{bulkClientTasks?.length > 0 ? (
										bulkClientTasks?.map((client, i) => {
											const filteredTasks = client?.tasks?.filter((t) =>
												t.extended_date === null
													? moment(t.start_date).format('MM-DD-YYYY') ===
													  moment(date.start_date).format('MM-DD-YYYY')
													: moment(t.extended_date).format(
															'MM-DD-YYYY',
													  ) ===
													  moment(date.start_date).format('MM-DD-YYYY'),
											);
											return (
												<ExpandableClientForReassignments
													key={client?.client_id}
													client={client}
													i={i}
													tasks={filteredTasks}
													handleDragEnd={handleDragEnd}
												/>
											);
										})
									) : (
										<span className='BodyTwoLight text-left'>
											No tasks on this date
										</span>
									)}
								</div>
							)}
							{dateRangeFilter == DATE_RANGE_FILTER.IS_BETWEEN && (
								<div className='d-flex flex-column' style={{ gap: '12px' }}>
									{dateRange?.map((date) => {
										const clientTasks = bulkClientTasks?.length
											? bulkClientTasks
													?.map((client) => {
														if (
															client?.tasks?.filter((t) =>
																t.extended_date === null
																	? moment(t.start_date).format(
																			'MM-DD-YYYY',
																	  ) ===
																	  moment(date).format(
																			'MM-DD-YYYY',
																	  )
																	: moment(
																			t.extended_date,
																	  ).format('MM-DD-YYYY') ===
																	  moment(date).format(
																			'MM-DD-YYYY',
																	  ),
															)?.length
														)
															return client;
														else return null;
													})
													?.filter((client) => client !== null)
											: [];
										return (
											<div
												className='d-flex flex-column text-left'
												key={date}
												style={{ gap: '12px' }}
											>
												<span className='BodyOneBold text-left'>
													{`${moment(date).format('dddd')} - ${moment(
														date,
													).format('MMM DD, yyyy')}`}
												</span>

												{clientTasks?.length > 0 ? (
													clientTasks?.map((client, i) => {
														const filteredTasks = client?.tasks?.filter(
															(t) =>
																t.extended_date === null
																	? moment(t.start_date).format(
																			'MM-DD-YYYY',
																	  ) ===
																	  moment(date).format(
																			'MM-DD-YYYY',
																	  )
																	: moment(
																			t.extended_date,
																	  ).format('MM-DD-YYYY') ===
																	  moment(date).format(
																			'MM-DD-YYYY',
																	  ),
														);
														if (filteredTasks?.length) {
															return (
																<ExpandableClientForReassignments
																	key={i}
																	client={client}
																	i={i}
																	tasks={filteredTasks}
																	handleDragEnd={handleDragEnd}
																/>
															);
														}
													})
												) : (
													<span className='BodyThreeLight'>
														No tasks on this date
													</span>
												)}
											</div>
										);
									})}
								</div>
							)}
						</>
					)}
				</div>
			</div>

			<TaskAlert
				message={failedTask?.error?.message}
				header={header}
				open={failedTask?.error}
				onDone={() => handleDoneTaskAlert(failedTask?.error?.error_code)}
				doneButton={doneButtonText}
				closeButton={cancelButtonText}
				closeHandler={handleCloseAlert}
				loading={updateLoading}
			/>
		</>
	);
}

export default ReshcedulingTasks;
