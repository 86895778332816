import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as clientActions from '../../redux/actions/client-actions';
import { useParams } from 'react-router';
import { standardPermissions } from '../../utilities/utilities';
import { Modal } from '@mui/material';
import useModal from '../Modal/useModal';
import AddIcon from '@mui/icons-material/Add';
import AddMyTaskModal from '../WorkSpace/Task Modal/AddMyTaskModal';
import * as WorkSpaceActions from '../../redux/actions/dashboard/workspaces-actions';
import moment from 'moment';
import * as userActions from '../../redux/actions/user-management.actions';
import ClientMeetingTask from './ClientMeetingTask';
import Restricted from '../Permissions/Restricted';

const MeetingMyTasksTable = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const meetingMyTasks = useSelector((state) => state.clientReducer.meetingMyTasks);
	const [myTaskDetails, setMyTaskDetails] = useState(null);
	const [open, setOpen] = useState(false);
	let allTenantUsers = useSelector((state) => state.userReducer.allTenantUsers);
	const user = useSelector((state) => state.authReducer.user);
	const userTeamRole = useSelector((state) => state.clientReducer.userTeamRole);
	const triggerGetTasks = useSelector((state) => state.workSpaceReducer.triggerGetTasks);

	if (allTenantUsers?.length > 1) {
		allTenantUsers = allTenantUsers.map((u) => {
			return {
				id: u.id,
				full_name: u.full_name,
			};
		});
	}

	const toggleAlert = () => {
		setOpen(!open);
	};

	const deleteTask = (delete_future) => {
		toggleAlert();
		dispatch(
			WorkSpaceActions.deleteMeetingMyTask(
				{
					id: myTaskDetails?.id,
					delete_future,
					...myTaskDetails,
				},
				onDeleteSuccess,
				id,
			),
		);
	};

	const onDeleteSuccess = () => {
		dispatch(WorkSpaceActions.setSnackBarIsOpen(true, true, 'Meeting Deleted'));
		dispatch(clientActions.getMeetingMyTasks(id));
	};

	useEffect(() => {
		dispatch(clientActions.getMeetingMyTasks(id));
	}, [dispatch, triggerGetTasks]);

	useEffect(() => {
		dispatch(userActions.getAllTenantUsers());
	}, []);

	return (
		<>
			{meetingMyTasks?.length < 1 ? (
				<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
					<h4 className='BodyTwoLight m-0 placeholder-text'>
						The Client has no meetings
					</h4>
				</div>
			) : (
				meetingMyTasks?.map((meeting, i) => {
					const isCreator = meeting?.my_task?.creator_id == user?.userId;
					return (
						<ClientMeetingTask
							key={meeting.id}
							meeting_task={meeting}
							is_creator={isCreator}
							user_team_role={userTeamRole}
						/>
					);
				})
			)}

			<Restricted AllowedUsers={standardPermissions} userTeamRole={[userTeamRole]}>
				<AddNewTask clientId={id} />
			</Restricted>
		</>
	);
};

export default memo(MeetingMyTasksTable);

const AddNewTaskComponent = ({ clientId }) => {
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const form = useSelector((state) => state.workSpaceReducer.createTaskForm);
	const [actionLoading, setActionLoading] = useState(false);
	const dispatch = useDispatch();
	const submitForm = () => {
		setActionLoading(true);
		let formDetails = form;

		const date = moment(form?.due_date).format('YYYY-MM-DD');
		const startDateTime = moment(date + ' ' + form.start_time, 'YYYY-MM-DD HH:mm:ss a').utc();
		const endDateTime = moment(date + ' ' + form.end_time, 'YYYY-MM-DD HH:mm:ss a').utc();
		const task_date = moment(startDateTime).format('YYYY-MM-DD');

		formDetails = {
			...formDetails,
			type: form?.task_type_id == 1 ? 'ONE_TIME' : 'RECURRING',
			task_type: 'MEETING',
			assignee_list: form?.meeting_guests || [],
			start_date: task_date,
			is_monthly_on_day: form.is_monthly_on_day,
			repeat_interval: form?.repeat_interval ? Number(form?.repeat_interval) : null,
			required_days: 1,
			metadata: {
				meeting_platform: form?.meeting_platform || null,
				meeting_link: form?.meeting_link || null,
			},
			start_time: moment(startDateTime, 'hh:mm A').format('hh:mm:ss a') || null,
			end_time: moment(endDateTime, 'hh:mm A').format('hh:mm:ss a') || null,
			is_billable: formDetails?.is_billable == 1 ? true : false,
		};
		delete formDetails.meeting_platform;
		delete formDetails.meeting_link;
		delete formDetails.meeting_guests;
		delete formDetails.task_type_id;
		delete formDetails?.assignee_user_id;
		delete formDetails?.due_date;

		dispatch(WorkSpaceActions.createMeetingTask(formDetails))
			.then(() => {
				setActionLoading(false);
				toggleModal();
			})
			.catch(() => {
				setActionLoading(false);
			});
	};

	return (
		<div
			className='d-flex justify-content-center information-header d-flex align-items-center'
			style={{ width: '100%' }}
		>
			<div
				className='pointer d-flex flex-row justify-content-start'
				style={{
					height: '22px',
					width: '6px',
					borderRadius: '3px',
					marginRight: '1rem',
					padding: 0,
					position: 'relative',
				}}
				onClick={() => {
					ModalType('ADD_MY_TASK_MODAL');
					toggleModal();
				}}
			>
				<AddIcon
					fontSize='small'
					sx={{
						color: '#fb7a03',
						fontSize: 21,
						position: 'absolute',
						margin: 0,
						padding: 0,
						top: 0,
						left: 0,
					}}
				/>
			</div>
			<h3
				className='BodyTwoLight mt-2 dark-text'
				style={{ color: '#FB7A03', cursor: 'pointer' }}
				onClick={() => {
					ModalType('ADD_MY_TASK_MODAL');
					toggleModal();
				}}
			>
				Add New Meeting
			</h3>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'ADD_MY_TASK_MODAL' && (
							<AddMyTaskModal
								clientId={clientId}
								onCancel={toggleModal}
								taskType='OFFICE'
								onSubmit={submitForm}
								title={'Create Meeting'}
								actionLoading={actionLoading}
							/>
						)}
					</>
				</Modal>
			)}
		</div>
	);
};

const AddNewTask = memo(AddNewTaskComponent);
