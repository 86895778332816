// import { ReactComponent as SaveArrowDown } from '../../assets/icons/SaveArrowDown.svg';
import { Box, CircularProgress } from '@mui/material';
import useBreadCrumb from '../../hooks/useBreadCrumb';
import { useHistory } from 'react-router';
import { lightGreen } from '../Style';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { invoiceStatusConstants } from '../../utilities/utilities';

function InvocieDetailsTopBar({
	Title,
	btnText,
	btnText2,
	save,
	InvoiceNo,
	GenerateOnClick,
	textTransparent,
	onSave,
	isLoading,
	disabled,
	handleDeleteInvoice,
}) {
	const { BreadCrumbs } = useBreadCrumb();
	const history = useHistory();

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				// width: accountSettings && '100%',
			}}
		>
			{!textTransparent && (
				<Box
					className='py-2'
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						':hover': {
							cursor: 'pointer',
						},
						color: `${lightGreen}`,
					}}
				>
					<ArrowLeftIcon />
					<BreadCrumbs>
						<h2
							onClick={() => history.push('/invoices')}
							className='HeadlineThreeBook text-left'
						>
							Invoices
						</h2>
					</BreadCrumbs>
				</Box>
			)}
			<div className='Zapier-Integration-TopBar' style={{ width: '100%' }}>
				<div className='d-flex align-items-center'>
					<span className='HeadlineTwoBold'>{InvoiceNo || ''}</span>
					{Title && (
						<div
							className='ml-3'
							style={{
								backgroundColor:
									Title === invoiceStatusConstants.SENT
										? 'green'
										: Title === invoiceStatusConstants.DRAFT
										? '#fb7a03'
										: 'red',
								height: '32px',
								width: 'auto',
								gap: '24px',
								borderRadius: '20px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								padding: '0px 10px',
								minWidth: '80px',
							}}
						>
							<h6 className='HeadlineThreeBold' style={{ color: 'white' }}>
								{Title || ''}
							</h6>
						</div>
					)}
				</div>
				<div className='d-flex w-50 justify-content-end'>
					{save && Title !== 'Void' ? (
						<div
							className='d-flex align-items-center justify-content-end w-100'
							style={{
								gap: '24px',
							}}
						>
							{Title === 'Draft' && (
								<button
									className='rounded-button d-flex'
									onClick={handleDeleteInvoice}
									disabled={isLoading?.delete}
								>
									{isLoading?.delete ? (
										<CircularProgress
											size={22}
											sx={{ color: 'white' }}
											disableShrink
										/>
									) : (
										<>
											<p className='BodyTwoBold pt-1'>Delete</p>
										</>
									)}
								</button>
							)}
							{Title === 'Draft' && (
								<button
									className='d-flex justify-content-center'
									style={{
										background: textTransparent ? 'transparent' : '#FB7A03',
										color: textTransparent ? '#FB7A03' : 'white',
										justifyContent: 'space-between',
										alignItems: 'center',
										border: 'none',
										borderRadius: '40px',
										padding: '5px 20px',
										width: '88px',
										opacity: isLoading.update ? 0.5 : 1,
									}}
									data-cy='on-invoice-save'
									onClick={onSave}
									disabled={isLoading.update}
								>
									{isLoading?.update ? (
										<CircularProgress
											size={22}
											sx={{ color: 'white' }}
											disableShrink
										/>
									) : (
										<>
											<p className='BodyTwoBold pt-1'>{btnText}</p>
										</>
									)}
								</button>
							)}

							<button
								className='rounded-button d-flex'
								onClick={GenerateOnClick}
								disabled={disabled || isLoading?.update}
								style={{ opacity: disabled || isLoading?.update ? 0.5 : 1 }}
							>
								{isLoading?.update && Title !== 'Draft' ? (
									<CircularProgress
										size={22}
										sx={{ color: 'white' }}
										disableShrink
									/>
								) : (
									<>
										<p className='BodyTwoBold pt-1'>{btnText2}</p>
									</>
								)}
							</button>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</Box>
	);
}

export default InvocieDetailsTopBar;
