/* eslint-disable no-mixed-spaces-and-tabs */
import moment from 'moment';
import React, { useEffect, memo, useMemo, useCallback } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import { useHistory } from 'react-router-dom';
import ExpandableClientTasksView from './ExpandableClientTasksView';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import TaskAlert from '../Alerts/TaskAlert';
import { ERROR_CODE, detailsModal, taskDragAlertContent } from '../../utilities/utilities';
import WorkspaceDateNavigation from './WorkspaceDateNavigation';
import MeetingsAndMyTasksview from './MeetingsAndMyTasksview';
import SkeletonLoading from '../Others/SkeletonLoading';

const WeeklyCalendar = () => {
	const history = useHistory();

	const dispatch = useDispatch();
	const dragOverContainer = useSelector((state) => state.workSpaceReducer.dragOverDate);
	const active_task_id = useSessionStorage('active_task_id');
	const start_date = useSelector((state) => state.workSpaceReducer.start_date);
	const end_date = useSelector((state) => state.workSpaceReducer.end_date);
	const weekDaysOff = useSelector((state) => state.workSpaceReducer.weekDaysOff);
	const weekData = useSelector((state) => state.workSpaceReducer.weekDate);
	const days = weekData ? weekData?.daysOfTheWeek : [];
	const triggerGetTasks = useSelector((state) => state.workSpaceReducer.triggerGetTasks);
	const showTeamMembersMeetings = useSelector(
		(state) => state.workSpaceReducer.showTeamMembersMeetings,
	);
	const weeklyClientTasks = useSelector((state) => state.workSpaceReducer.weeklyClientTasks);
	const [taskDetailsForError, setTaskDetailsForError] = useState(null);
	const [dateToUpdate, setDateToUpdate] = useState(null);
	const [isTaskAlert, setIsTaskAlert] = useState(false);
	const taskDragError = useSelector((state) => state.workSpaceReducer.taskDragError);
	const { header, doneButtonText, cancelButtonText } =
		isTaskAlert && taskDragAlertContent(taskDragError?.error_code);
	const clientTaskLoading = useSelector((state) => state.workSpaceReducer.clientTaskLoading);

	const isTaskMinimized = useSelector((state) => state.workSpaceReducer.isTaskMinimized);

	const filters = useSelector((state) => state.workSpaceReducer.filters);

	const handleDragEnd = useCallback((task, clientId, date) => {
		const dragOverDate = moment(date).format('YYYY-MM-DD');
		setTaskDetailsForError(task);
		setDateToUpdate(dragOverDate);
		dispatch(Actions.setDragOverDate(null));
		const task_date = task.extended_date
			? moment(task?.extended_date)
			: moment(task?.start_date);
		const start_date = days?.length > 0 ? days[0].format('YYYY-MM-DD') : undefined;
		const end_date = days?.length > 0 ? days[days?.length - 1].format('YYYY-MM-DD') : undefined;

		if (moment(dragOverDate).isValid() && !moment(dragOverDate).isSame(task_date)) {
			dispatch(
				Actions.updateTaskCard(
					{
						task: { ...task, stop_stream_sse: false },
						clientId,
						dragOverContainer: dragOverDate,
						weeklyClientTasks,
					},
					true,
					filters,
					start_date,
					end_date,
				),
			);
		}
	}, []);

	const handleDragOver = useCallback((date) => {
		dispatch(Actions.setDragOverDate(date));
	}, []);

	const toggleTaskAlert = () => {
		setIsTaskAlert(!isTaskAlert);
	};

	const handleDoneTaskAlert = (error_code) => {
		toggleTaskAlert();
		dispatch(Actions.resetTaskError());

		if (
			error_code == ERROR_CODE.START_DATE_BEFORE_PRECEEDING_TASK_SAME_ASSIGNEE ||
			error_code == ERROR_CODE.START_DATE_BEFORE_PRECEEDING_TASK_DIFFERENT_ASSIGNEE
		) {
			dispatch(
				Actions.handleTaskClick({
					taskId: taskDetailsForError?.dependent_task_id,
					modalType: detailsModal.CLIENT,
				}),
			);
		}
		if (
			error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE ||
			error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE
		) {
			if (taskDetailsForError?.my_task?.task_type == 'ADHOC') {
				updateAdhocTaskAlertOnAlert(error_code);
			} else {
				dispatch(
					Actions.updateTaskDetails(taskDetailsForError?.id, {
						start_date:
							error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE
								? dateToUpdate
								: taskDetailsForError?.start_date,
						due_date:
							error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE
								? dateToUpdate
								: taskDetailsForError?.due_date,
						extended_date: dateToUpdate,
					}),
				)
					.then(() => {})
					.catch((error) => {
						dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
					});
			}
		}
	};

	const updateAdhocTaskAlertOnAlert = (error_code) => {
		const formDetails = {
			id: taskDetailsForError?.id,
			type: taskDetailsForError?.my_task?.type,
			title: taskDetailsForError?.title,
			client_id:
				taskDetailsForError?.client_id == null
					? null
					: Number(taskDetailsForError?.client_id),
			budgeted_hours: Number(taskDetailsForError?.budgeted_hours),
			assignee_list:
				taskDetailsForError?.task_assignees?.length > 0
					? [taskDetailsForError?.task_assignees[0]?.user_profile?.id]
					: [],
			assignee_user_id: Number(taskDetailsForError?.task_assignees[0]?.user_profile?.id),
			approval_from: taskDetailsForError?.approval_from
				? Number(taskDetailsForError?.approval_from)
				: null,
			is_approval_required: taskDetailsForError?.is_approval_required,
			start_date:
				error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE
					? dateToUpdate
					: taskDetailsForError?.start_date,
			extended_date: dateToUpdate,
			is_billable: taskDetailsForError?.my_task?.is_billable,
			task_type: taskDetailsForError?.my_task?.task_type,
			end_on: taskDetailsForError?.my_task?.end_on,
			required_days:
				error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE
					? moment(dateToUpdate).diff(moment(taskDetailsForError?.start_date), 'days') + 1
					: moment(taskDetailsForError?.due_date).diff(moment(dateToUpdate), 'days') + 1,
			repeat_type: taskDetailsForError?.my_task?.repeat_type,
			repeat_interval:
				taskDetailsForError?.my_task?.repeat_interval == null
					? null
					: Number(taskDetailsForError?.my_task?.repeat_interval),
			recur_preference: taskDetailsForError?.my_task?.recur_preference,
			is_monthly_on_day: taskDetailsForError?.my_task?.is_monthly_on_day,
		};
		dispatch(Actions.updateMeetingTaskDetails(formDetails, false))
			.then(() => {})
			.catch((error) => {
				dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
			});
	};

	useMemo(() => {
		if (taskDragError) {
			toggleTaskAlert();
		}
	}, [taskDragError]);

	useEffect(() => {
		history.push(
			`/workspace/${start_date}/${
				active_task_id.getSessionStorage() && !isTaskMinimized
					? `?task_id=${active_task_id.getSessionStorage()}`
					: ''
			}`,
		);
		if (start_date && end_date && !showTeamMembersMeetings) {
			dispatch(Actions.getWeeklyClientTasks(filters, start_date, end_date));
		}
	}, [dispatch, filters, triggerGetTasks, start_date, end_date, showTeamMembersMeetings]);

	useEffect(() => {
		return () => {
			dispatch(Actions.resetWorkspaceTasks());
		};
	}, []);

	const clients = useMemo(() => {
		return weeklyClientTasks
			?.map((item) => {
				const displayName =
					item?.client_dba?.trim() === '' ? item?.client_name : item?.client_dba;
				return {
					...item,
					displayName: displayName || '', // Ensure displayName is never undefined
				};
			})
			.sort((a, b) => {
				// Compare displayNames safely
				return a.displayName?.localeCompare(b.displayName || '') || 0;
			});
	}, [weeklyClientTasks]);

	return (
		<React.Fragment>
			<div
				className='d-flex flex-column'
				style={{
					minWidth: weekDaysOff ? '1400px' : '1000px',
					padding: '0px 12px 0px 12px',
					boxSizing: 'border-box',
				}}
			>
				<WorkspaceDateNavigation dragOverContainer={dragOverContainer} />
				<div className='d-flex flex-column'>
					<MeetingsAndMyTasksview weekDaysOff={weekDaysOff} days={days} />
					{clientTaskLoading ? (
						<ExpandableClientTasksViewLoading days={days} />
					) : (
						!showTeamMembersMeetings &&
						clients?.map((client, i) => {
							return (
								<div
									key={client?.client_id}
									className='d-flex'
									style={{
										height: 'auto',
										width: '100%',
										borderRadius: '4px',
										borderTopRightRadius: i === 0 ? '0px' : '4px',
										borderTopLeftRadius: i === 0 ? '0px' : '4px',
										background: i % 2 === 0 ? '#FCFDFD' : '#F7F9FA',
										marginBottom: '24px',
										paddingBottom: '12px',
									}}
								>
									<ExpandableClientTasksView
										clientName={
											client?.displayName ? client?.displayName : client?.name
										}
										clientId={client?.client_id}
										clientTeamId={client?.team_id}
										clientTasks={client?.tasks}
										i={i}
										days={days}
										handleDragEnd={handleDragEnd}
										handleDragOver={handleDragOver}
									/>
								</div>
							);
						})
					)}
				</div>
			</div>
			<TaskAlert
				message={taskDragError?.message}
				header={header}
				open={isTaskAlert}
				onDone={() => handleDoneTaskAlert(taskDragError?.error_code)}
				doneButton={doneButtonText}
				closeButton={cancelButtonText}
				closeHandler={() => {
					toggleTaskAlert();
					dispatch(Actions.resetTaskError());
				}}
			/>
		</React.Fragment>
	);
};

export default memo(WeeklyCalendar);

const ExpandableClientTasksViewLoading = ({ days }) => {
	return Array.from({ length: 3 }).map((_, index) => {
		return (
			<div
				key={index}
				className='d-flex'
				style={{
					height: 'auto',
					width: '100%',
					borderRadius: '4px',
					borderTopRightRadius: '0px',
					borderTopLeftRadius: '0px',
					background: '#FCFDFD',
					marginBottom: '24px',
					paddingBottom: '12px',
				}}
			>
				<div
					style={{
						cursor: 'pointer',
						gap: '10px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'left',
						alignItems: 'start',
					}}
				>
					<SkeletonLoading
						loading={true}
						variant='text'
						width={150}
						height={45}
					></SkeletonLoading>
				</div>
				{days?.map((day, index) => {
					return (
						<div
							key={index}
							id={day.format()}
							className='d-flex flex-column justify-content-start align-items-start container'
							style={{
								width: `${100 / days.length}%`,
								height: 'auto',
								padding: '12px 12px 0px 12px',
								borderRadius: '8px',
							}}
						>
							<div className='w-100 d-flex justify-content-center'>
								<SkeletonLoading
									loading={true}
									variant='rounded'
									width={175}
									height={100}
								></SkeletonLoading>
							</div>
						</div>
					);
				})}
			</div>
		);
	});
};
