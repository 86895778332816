import { useSelector } from 'react-redux';
import useSideBarComponent from '../hooks/useSideBarComponent';

function ActionSideBar () {
	const actionSideBarComponent = useSelector(
		(state) => state.actionCenterReducer.actionSideBarComponent,
	);
	const component = useSideBarComponent(actionSideBarComponent);
	return (
		<div className={`action-side-bar-view ${actionSideBarComponent ? 'show' : ''}`}>
			{component}
		</div>
	);
}

export default ActionSideBar;
