import { Avatar, ClickAwayListener, Popper } from '@mui/material';
import React from 'react';
import { getAvatarLabel } from '../../utilities/utilities';

function GuestListPopper({ handleClosePopper, anchorRef, guests, open }) {
    return (
        <ClickAwayListener onClickAway={handleClosePopper}>
            <Popper
                open={open}
                onClose={handleClosePopper}
                anchorEl={anchorRef && anchorRef.current}
                placement='bottom-start'
                onClick={() => { }}
            >
                <div
                    className='d-flex flex-column scroll user-popper'
                >
                    {guests?.map((guest) => {
                        return (
                            <div key={guest?.user_id}>
                                <div className='d-flex'>
                                    <Avatar
                                        sx={{
                                            bgcolor: `#FEF2E7`,
                                            color: `#333`,
                                            marginRight: '10px',
                                            height: '23px',
                                            width: '23px',
                                            fontSize: '0.8rem',
                                        }}
                                        alt={guest?.user_profile?.full_name}
                                        src={guest?.user_profile?.image_url}
                                    >
                                        {getAvatarLabel(guest?.user_profile?.full_name)}
                                    </Avatar>
                                    <span className='BodyTwoLight pt-1 dark-text text-left'>
                                        {guest?.user_profile?.full_name}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Popper>
        </ClickAwayListener>
    );
}

export default GuestListPopper;
