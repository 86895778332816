import { useDispatch, useSelector } from 'react-redux';
import * as AccountingActions from '../../redux/actions/integrations/accounting-actions';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

export default function AccountingComponent() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState({});
	const [disconnectLoading, setDisconnectLoading] = useState(false);

	const redirectURL = window.location.href;
	const integration = useSelector((state) => state.AccountingReducer.integration);
	const isAnyAppConnected = integration?.data?.some(
		(app) => app?.connections && app?.connections.length > 0,
	);

	// dispatch(
	// 	AccountingActions.connectQBO(
	// 		integration != null ? integration?.company_id : companyId,
	// 		{
	// 			platformKey: PLATFORM_KEYS.QBO,
	// 		},
	// 	),
	// )
	// 	.then((res) => {
	// 		const linkUrl = res.linkUrl;
	// 		if (linkUrl) {
	// 			setRedirectUrl(linkUrl);
	// 			window.open(linkUrl, '_blank');
	// 		}
	// 	})
	// 	.finally(() => {
	// 		setLoading(false);
	// 	});

	// const onConnect = () => {
	// 	setLoading(true);
	// 	// if (redirectUrl) {
	// 	// 	window.open(redirectUrl, '_blank');
	// 	// 	setLoading(false);
	// 	// } else {
	// 		if (integration != null) {
	// 			connectQBO();
	// 		} else {
	// 			dispatch(AccountingActions.createCodatCompany({ name: companyProfileData?.name }))
	// 				.then((res) => {
	// 					connectQBO(res?.company_id);
	// 				})
	// 				.catch((e) => {
	// 					dispatch(setSnackBarIsOpen(false, true, e.message));
	// 				})
	// 				.finally(() => {
	// 					setLoading(false);
	// 				});
	// 		}
	// 	// }
	// };

	// const onSync = () => {
	// 	setLoading(true);
	// 	dispatch(AccountingActions.syncCodat(integration?.company_id, {}))
	// 		.then((res) => {
	// 			dispatch(
	// 				setSnackBarIsOpen(true, true, 'Customer list sync process has been started'),
	// 			);
	// 		})
	// 		.finally(() => {
	// 			setLoading(false);
	// 		});
	// };

	const onConnect = (id) => {
		setLoading((prevState) => ({ ...prevState, [id]: true }));
		dispatch(AccountingActions.connectIntegration(id, redirectURL))
			.then((res) => {
				console.log(res, 'response123');
				window.location.replace(res?.redirect);
			})
			.catch((err) => {
				console.log(err, 'error123');
			}).finally(() => {
                setLoading((prevState) => ({ ...prevState, [id]: false }));
            });
			
	};

	const onDisconnect = (id) => {
		setDisconnectLoading(true);
		dispatch(AccountingActions.disconnectIntegration(id))
			.then(() => dispatch(AccountingActions.updateIntegration(id)))
			.catch(() => {
				setSnackBarIsOpen(false, true, 'Failed to disconnect.Please retry.');
			})
			.finally(() => {
				setDisconnectLoading(false);
			});
	};

	useEffect(() => {
		dispatch(AccountingActions.getIntegration());
	}, [dispatch]);

	return (
		<div className='d-flex map-div' style={{ flexDirection: 'column', gap: '18px' }}>
			{integration?.data?.map((app) => {
				const isConnected = app?.connections && app?.connections.length > 0;
				return (
					<div
						key={app?.id}
						className='d-flex align-items-center justify-content-between'
						style={{
							width: '100%',
							height: 'auto',
							padding: '16px',
							border: '1px solid #EAEAEA',
							backgroundColor: 'white',
						}}
					>
						<div className='d-flex align-items-center'>
							<img
								src={app?.logo}
								alt={`${app?.name} logo`}
								style={{ width: '32px', height: '32px' }}
							/>

							<div style={{ paddingLeft: '16px' }}>
								<div className='d-flex'>
									<span className='BodyOneBold'>{app?.name}</span>
								</div>
								<div className='d-flex text-justify'>
									<span className='BodyTwoLight'>{app?.description}</span>
								</div>
							</div>
						</div>
						<div className='d-flex' style={{ marginLeft: '80px', cursor: 'pointer' }}>
							{isConnected ? (
								disconnectLoading ? (
									<CircularProgress
										size={22}
										sx={{ color: '#FB7A03', marginRight: '25px' }}
										disableShrink
									/>
								) : (
									<div className='d-flex flex-column align-items-center'>
										{/* <button
											className='rounded-button d-flex BodyOneBold text-center'
											style={{
												height: '32px',
												width: '125px',
												justifyContent: 'center',
												alignItems: 'center',
												opacity: loading ? 0.5 : 1,
											}}
											onClick={onSync}
											disabled={loading}
										>
											{loading ? (
												<CircularProgress
													size={22}
													sx={{ color: 'white' }}
													disableShrink
												/>
											) : (
												<p className='BodyOneBold mb-1 text-white'>Sync</p>
											)}
										</button> */}
										<div className='d-flex flex-row align-items-center pointer mt-2'>
											<button
												onClick={() => {
													onDisconnect(app?.connections[0]?.id);
												}}
												className='BodyTwoLight mb-0 primary-text ml-1'
												style={{
													outline: 'none',
													border: 'none',
													backgroundColor: 'transparent',
												}}
												disabled={disconnectLoading}
											>
												Disconnect
											</button>
										</div>
									</div>
								)
							) : (
								<button
									className='rounded-button d-flex BodyOneBold text-center'
									style={{
										height: '32px',
										width: '125px',
										justifyContent: 'center',
										alignItems: 'center',
										opacity:
											loading[app?.id] || (isAnyAppConnected && !isConnected)
												? 0.5
												: 1,
									}}
									onClick={() => {
										onConnect(app?.id);
									}}
									disabled={
										loading[app?.id] || (isAnyAppConnected && !isConnected)
									}
								>
									{loading[app?.id] ? (
										<CircularProgress
											size={22}
											sx={{ color: 'white' }}
											disableShrink
										/>
									) : (
										<div>
											<span className='BodyOneBold text-white'>Connect</span>
										</div>
									)}
								</button>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
}
